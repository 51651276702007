import { useContext, useEffect, useState } from "react";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { StatusAlert } from "../../../components/status_alert";
import { ReportConfig, ReportConfigId } from "vigil-datamodel/src/bean_report";
import { InputSelect, OptionSelect } from "../../../components/input_select";
import { fromDurationHours } from "./helper_report";
import { CheckboxItems } from "../../../components/checkbox_group";
import { InputCheckboxGroup } from "../../../components/input_checkbox_group";

interface ReportPatrolProps {
  config?: ReportConfig;
  setConfig: (config: ReportConfig) => void;
  setValidateForm: (validateForm: string[]) => void;
}

export const ReportPatrol: React.FC<ReportPatrolProps> = (props) => {
  const contextOrganization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');

  const [stateSelectedDuration, setSelectedDuration] = useState<OptionSelect>(
    props.config ?
      fromDurationHours.filter((duration) => duration.value == props.config?.fromDurationHours)[0] :
      fromDurationHours[0]
  );

  const [eventsLoading, setEventsLoading] = useState<boolean>(false);
  const [eventsError, setEventsError] = useState<string>('');
  const [stateEventCheckboxGroup, setEventCheckboxGroup] = useState<CheckboxItems>({});

  const [devicesLoading, setDevicesLoading] = useState<boolean>(false);
  const [devicesError, setDevicesError] = useState<string>('');
  const [stateDeviceCheckboxGroup, setDeviceCheckboxGroup] = useState<CheckboxItems>({});

  const [sitesLoading, setSitesLoading] = useState<boolean>(false);
  const [sitesError, setSitesError] = useState<string>('');
  const [stateSiteCheckboxGroup, setSiteCheckboxGroup] = useState<CheckboxItems>({});

  useEffect(() => {
    props.setConfig({
      type: ReportConfigId.Patrol,
      fromDurationHours: Number(stateSelectedDuration.value),
      eventUuids: Object.entries(stateEventCheckboxGroup).filter(([uuid, event]) => event.checked).map(([uuid, event]) => uuid),
      deviceUuids: Object.entries(stateDeviceCheckboxGroup).filter(([uuid, device]) => device.checked).map(([uuid, device]) => uuid),
      siteUuids: Object.entries(stateSiteCheckboxGroup).filter(([uuid, site]) => site.checked).map(([uuid, site]) => uuid),
    });
    props.setValidateForm([]);
  }, [stateSelectedDuration, stateEventCheckboxGroup, stateDeviceCheckboxGroup, stateSiteCheckboxGroup]);

  useEffect(() => { fetchEvents(); fetchDevices(); fetchSites(); }, [contextOrganization]);

  async function fetchEvents() {
    try {
      setEventsLoading(true);
      if (!contextOrganization) return;
      const events = await vigil.functions.eventLinkedTo({ type: 'organization', uuid: contextOrganization.uuid });
      const eventUuids = props.config?.eventUuids || [];
      setEventCheckboxGroup((prevState: CheckboxItems) => {
        const updatedItems: CheckboxItems = {} as CheckboxItems;
        for (const event of events) {
          updatedItems[event.uuid] = { label: event.name, checked: eventUuids.includes(event.uuid) }
        }
        return { ...prevState, ...updatedItems };
      });
    } catch (error: any) {
      setEventsError(error.message);
    } finally {
      setEventsLoading(false);
    }
  }

  async function fetchDevices() {
    try {
      setDevicesLoading(true);
      if (!contextOrganization) return;
      const devices = await vigil.functions.deviceLinkedTo({ type: 'organization', uuid: contextOrganization.uuid });
      const deviceUuids = props.config?.deviceUuids || [];
      setDeviceCheckboxGroup((prevState: CheckboxItems) => {
        const updatedItems: CheckboxItems = {} as CheckboxItems;
        for (const device of devices) {
          updatedItems[device.uuid] = { label: device.uuid, checked: deviceUuids.includes(device.uuid) }
        }
        return { ...prevState, ...updatedItems };
      });
    } catch (error: any) {
      setDevicesError(error.message);
    } finally {
      setDevicesLoading(false);
    }
  }

  async function fetchSites() {
    try {
      setSitesLoading(true);
      if (!contextOrganization) return;
      const sites = await vigil.functions.siteLinkedTo({ type: 'organization', uuid: contextOrganization.uuid });
      const siteUuids = props.config?.siteUuids || [];
      setSiteCheckboxGroup((prevState: CheckboxItems) => {
        const updatedItems: CheckboxItems = {} as CheckboxItems;
        for (const site of sites) {
          updatedItems[site.uuid] = { label: site.name, checked: siteUuids.includes(site.uuid) }
        }
        return { ...prevState, ...updatedItems };
      });
    } catch (error: any) {
      setSitesError(error.message);
    } finally {
      setSitesLoading(false);
    }
  }

  /* Functions */
  function onChangeSelectedDuration(event: React.ChangeEvent<HTMLSelectElement>) {
    const duration = fromDurationHours.find((duration) => duration.value == parseInt(event.target.value));
    if (duration) {
      setSelectedDuration(duration);
    }
  }

  if (stateError) return <StatusAlert message={stateError} type={"alert-error"} />

  return (
    <div className="flex flex-wrap overflow-y-auto">
      <InputSelect className="w-48 mb-2 mr-4" errors={[]} labelText="Report Duration" value={stateSelectedDuration.value} options={fromDurationHours} onChange={onChangeSelectedDuration}> </InputSelect>
      <InputCheckboxGroup
        className="w-full"
        labelText="Events to be added to the email"
        errors={eventsError ? [eventsError] : []}
        loading={eventsLoading}
        checkboxGroup={{
          label: 'Select All Events',
          items: stateEventCheckboxGroup,
          onChange: (updatedItems) => setEventCheckboxGroup(updatedItems)
        }}
      />
      <InputCheckboxGroup
        className="w-full"
        labelText="Devices to be added to the email"
        errors={devicesError ? [devicesError] : []}
        loading={devicesLoading}
        checkboxGroup={{
          label: 'Select All Devices',
          items: stateDeviceCheckboxGroup,
          onChange: (updatedItems) => setDeviceCheckboxGroup(updatedItems)
        }}
      />
      <InputCheckboxGroup
        className="w-full"
        labelText="Sites to be added to the email"
        errors={sitesError ? [sitesError] : []}
        loading={sitesLoading}
        checkboxGroup={{
          label: 'Select All Sites',
          items: stateSiteCheckboxGroup,
          onChange: (updatedItems) => setSiteCheckboxGroup(updatedItems)
        }}
      />
    </div>
  )
}