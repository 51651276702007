class Base64Helper {
  // This key is used to encode a very large number into base62
  static key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  static fromNumber(number: number): string {
    if (number < 0) {
      throw new Error("Can't represent negative numbers");
    }

    let rixit: number;
    let residual = Math.floor(number);
    let result = '';
    while (true) {
      rixit = residual % Base64Helper.key.length;
      result = this.key[rixit] + result;
      residual = Math.floor(residual / Base64Helper.key.length);

      if (residual == 0) break;
    }
    return result;
  }

  static toNumber(input: string): number {
    let result = 0;
    for (let i = 0; i < input.length; i++) {
      result = (result * Base64Helper.key.length) + this.key.indexOf(input[i]);
    }
    return result;
  }
}

export class TTuuidDecoded {
  private _time: Date;
  get time(): Date { return this._time; }

  private _randomNumber: number;
  get randomNumber(): number { return this._randomNumber; }

  constructor(cuuid: string) {
    // Check valid cuuid
    if (!cuuid) {
      this._randomNumber = 0;
      this._time = new Date();
      return;
    }

    // Split cuuid into parts
    const parts = cuuid.split(TTuuid.seperator)

    // Decode time
    const time = parts[0];
    this._time = new Date(Number.parseInt(time))

    // Decode random number
    const rand = parts[1];
    this._randomNumber = Base64Helper.toNumber(rand);
  }
}

export class TTuuid {
  static seperator = '_';

  static isValidCuuid(cuuid: string) {
    if (!cuuid) return false;
    const parts = cuuid.split(TTuuid.seperator)

    // OLD UUID
    if (parts.length == 3) {
      if (parts[0].length != 13) return false;
      if (parts[1].length != 7) return false;
      if (parts[2].length != 2) return false;
      return true;
    }

    // New UUID
    if (parts.length != 2) return false;
    if (parts[0].length != 14) return false;
    if (parts[1].length != 7) return false;
    return true;
  }

  static getCuuid() {
    const timestamp = (new Date).valueOf().toString();
    const rand64 = Base64Helper.fromNumber(Math.random() * 1000000000000);
    const uuid = timestamp.padStart(14, '0') + this.seperator + rand64.padStart(7, '0')
    return uuid;
  }

  static decodeCuuid(cuuid: string) {
    return new TTuuidDecoded(cuuid);
  }

  static getCuuidFromValues(date: Date, number: number) {
    return date.valueOf().toString().padStart(14, '0') + TTuuid.seperator + Base64Helper.fromNumber(number).padStart(7, '0');
  }
}
