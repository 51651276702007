export enum TTBeanTarget {
  Client,
  Server,
}

type TPrimative = String | Boolean | Number | Date
export interface TTField {
  type: Object | Array<TPrimative> | TPrimative,
  primary?: boolean,
  indexed?: boolean,
}

export interface TTFieldNamed extends TTField {
  name: string;
}

export type TTSchema<T> = {
  [K in keyof T]-?: TTField;
};

export class TTSchemaInstance<T> {
  public readonly name: string;
  public readonly schema: TTSchema<T>;
  public readonly version: number;
  public readonly targets: Array<TTBeanTarget>;

  constructor(name: string, schema: TTSchema<T>, version: number, targets: Array<TTBeanTarget> = [TTBeanTarget.Client, TTBeanTarget.Server]) {
    this.name = name;
    this.schema = schema;
    this.version = version;
    this.targets = targets;
  }

  public getFields() : TTFieldNamed[]{
    const schemaAny = this.schema as any;
    const result: Array<TTFieldNamed> = [];

    for (const name of Object.keys(schemaAny)) {
      result.push({
        name: name,
        ...schemaAny[name]
      })
    }

    return result;
  }
}

export class TTSchemaSyncableInstance<T> extends TTSchemaInstance<T> {
  constructor(name: string, schema: TTSchema<T>, version: number) {
    super(name, schema, version);
  }
}
