import React, { useContext, useEffect, useRef, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IRoleDirectory, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/hook_modal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { ModalRoleDelete } from './func_role/modal_role_delete';
import { ModalRoleCreate } from './func_role/modal_role_create';
import { convertQueryFilterToMongoFilter } from './helper_home';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { useInterval } from '../../hooks/hook_interval';


interface ScreenHomeRolesProps { }

export const ScreenHomeRoles: React.FC<ScreenHomeRolesProps> = (props) => {
  const navigate = useNavigate()

  const contextOrganization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);

  const [stateError, setError] = useState("");
  const [stateLoading, setLoading] = useState(false);

  const [stateRoles, setRoles] = useState([] as IRoleDirectory[]);

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState([] as QueryFilter[]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState({ id: 'uuid', direction: 'descending' } as Sorting);
  const [stateTablePagingSelected, setTablePagingSelected] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 } as Paging);

  const [ticker, setTicker] = useState(0);

  const { isOpen: isOpenModalCreateRole, toggle: toggleModalCreateRole } = useModal();
  const { isOpen: isOpenModalDeleteRole, toggle: toggleModalDeleteRole } = useModal();

  const [selectedRolesState, setSelectedRoles] = useState<IRoleDirectory[]>([]);

  /* Getters */
  async function fetchRolesBackground() {
    if (!contextOrganization) { return }

    const response = await vigil.functions.roleDirectory({
      uuidOrganization: contextOrganization.uuid,
      filter: convertQueryFilterToMongoFilter(stateTableFiltersSelected),
      ordering: { field: stateTableSortingSelected.id, direction: stateTableSortingSelected.direction },
      pagination: { page: stateTablePagingSelected.currentPage, count: stateTablePagingSelected.itemsPerPage }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateRoles)) {
      setRoles(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }
  async function fetchRoles() {
    try {
      setLoading(true);
      await fetchRolesBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update immediately
  useEffect(() => {
    fetchRoles();
  }, [contextOrganization, ticker])

  // Update every 5 seconds
  useInterval(async () => {
    await fetchRolesBackground();
  }, 5000, false);

  function navigateRole(selectedRole: IRoleDirectory) {
    navigate(ROUTES.ROUTE_HOME_ROLE.replace(':uuid', selectedRole.uuid))
  }

  function deleteRoles(selectedRoles: IRoleDirectory[]) {
    setSelectedRoles(selectedRoles)
    toggleModalDeleteRole();
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalRoleCreate isOpen={isOpenModalCreateRole} toggle={toggleModalCreateRole} onSubmit={async () => await fetchRoles()} />
      <ModalRoleDelete isOpen={isOpenModalDeleteRole} toggle={toggleModalDeleteRole} onSubmit={async () => await fetchRoles()} roles={selectedRolesState} />

      <Table<IRoleDirectory>
        className='h-full flex flex-col drop-shadow'
        name='Roles'
        data={stateRoles}
        selectable={true}
        loading={stateLoading}
        error={stateError}
        columns={[
          {
            id: 'name',
            header: 'Name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateRole(item)}>
                  {item.name}
                </div>
              );
            },
          },
          {
            id: 'userCount',
            header: 'Users Linked',
            sortable: true,
            value(item) {
              return <div>{item.userCount}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'changeStamp',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('name', 'Name', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
          new DateFilter('changeStamp', 'Last Updated', 'between'),
        ]}
        filtersSelected={stateTableFiltersSelected}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1) }}

        sortingOptions={null}
        sortingSelected={stateTableSortingSelected}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1) }}

        pagingOptions={null}
        pagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1) }}

        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new role</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RRole),
            onClick: () => toggleModalCreateRole(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RRole),
            onClick: (selectedItems) => deleteRoles(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RRole),
            onClick: (selectedItems) => deleteRoles(selectedItems)
          }
        ]}
      />
    </div >
  );
};
