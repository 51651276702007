import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../routes';
import { IEvent } from 'vigil-datamodel';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { IconBars4, IconCalendar } from '../../components/icons';
import { useModal } from '../../hooks/hook_modal';
import { ModalEventDelete } from './func_event/modal_event_delete';
import { ModalEventUpdate } from './func_event/modal_event_update';
import { Tabs } from '../../components/tabs';

interface ScreenHomeEventProps { }

export const ScreenHomeEvent: React.FC<ScreenHomeEventProps> = (props) => {
  /* State */
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const vigil = useContext(ContextVigilClient);
  const uuidEvent = params['uuid'] || '';

  const [stateError, setError] = useState<string>('');
  const [stateEventLoading, setEventLoading] = useState(true);
  const [stateEvent, setEvent] = useState<IEvent>();

  const { isOpen: isOpenModalUpdatePatrol, toggle: toggleModalUpdatePatrol } = useModal();
  const { isOpen: isOpenModalDeletePatrol, toggle: toggleModalDeletePatrol } = useModal();

  function navigateTab(pageId: string) {
    navigate({
      pathname: ROUTES.ROUTE_HOME_EVENT.replace(':uuid', uuidEvent) + '/' + pageId,
    })
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  useEffect(() => { fetchEvent(); }, [uuidEvent]);

  async function fetchEvent() {
    try {
      setEventLoading(true);
      const event = await vigil.functions.eventFindOne({ filter: { uuid: uuidEvent } });
      setEvent(event);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setEventLoading(false);
    }
  }

  if (stateEventLoading || !stateEvent) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 flex w-full h-full flex-col'>
      <ModalEventUpdate isOpen={isOpenModalUpdatePatrol} toggle={toggleModalUpdatePatrol} event={stateEvent} onSubmit={async () => fetchEvent()} />
      <ModalEventDelete isOpen={isOpenModalDeletePatrol} toggle={toggleModalDeletePatrol} events={[stateEvent]} onSubmit={async () => navigate(ROUTES.ROUTE_HOME_EVENTS)} />

      <Breadcrumbs crumbs={[{ id: 'events', onClick: () => navigate(ROUTES.ROUTE_HOME_EVENTS), text: 'Events' }, { id: uuidEvent, onClick: () => { }, text: stateEvent.name }]} />

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <Tabs selectedId={getSelectedId()} onSelect={navigateTab} items={[
        {
          icon: IconCalendar,
          id: 'overview',
          text: 'Overview'
        },
        {
          icon: IconBars4,
          id: 'raw_logs',
          text: 'Raw Logs'
        },
      ]} />

      <div className='w-full flex-grow'>
        <Outlet />
      </div>
    </div>
  )
};
