import { useContext, useEffect, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ISite } from "vigil-datamodel";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { StatusLoading } from "../../../components/status_loading";

interface ModalSiteUpdateProps extends ModalProps {
  uuidSite: string;
  onSubmit?: () => Promise<void>;
}

export const ModalSiteUpdate: React.FC<ModalSiteUpdateProps> = (props) => {
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateLoading, setLoading] = useState(false);

  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  const [stateSite, setSite] = useState<ISite>();
  const [stateNewSite, setNewSite] = useState<ISite>();

  /* Lifecycle */
  useEffect(() => { fetchSites() }, [props.uuidSite]);

  /* DB Functions */
  async function fetchSites() {
    try {
      setLoading(true);
      const site = await vigil.functions.siteFindOne({ filter: { uuid: props.uuidSite } });
      setSite(site);
      setNewSite(site);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function updateSite() {
    try {
      setError('');
      if (!stateNewSite) throw new Error('Site not found');
      setSubmitLoading(true);
      await vigil.functions.siteUpdate({uuid: props.uuidSite, siteInfo: stateNewSite});
      props.onSubmit && await props.onSubmit();
      props.toggle();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  /* Validation */
  function validateNewSiteName() {
    if (!stateNewSite || !stateNewSite.name) return [];
    return validate(stateNewSite.name, [VALIDATORS.length('Site name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateNewSite || !stateNewSite.name) { required.push('Site name is required') }
    return [
      ...required,
      ...validateNewSiteName(),
    ];
  }

  /* UI Updates */
  function onChangeSiteName(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setNewSite(prevState => ({ ...prevState!, name: event.target.value }));
  }

  function disableSubmitButton() {
    if (!stateSite || !stateNewSite) return true;
    if (validateForm().length > 0) return true;
    if (JSON.stringify(stateSite) == JSON.stringify(stateNewSite)) return true;
    return false;
  }

  if (!stateSite || stateLoading || !stateNewSite) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <StatusLoading />
      </Modal>
    )
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Update Site</h3>
      <InputText labelText='Site name' value={stateNewSite.name} onChange={onChangeSiteName} errors={validateNewSiteName()} > </InputText>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Update' loading={stateSubmitLoading} disabled={disableSubmitButton()} type='btn-primary' onClick={async () => updateSite()}></InputButton>
      </div>
    </Modal>
  )
}