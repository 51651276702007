import 'animate.css'
import './index.css'
import './charts'
import ReactDOM from 'react-dom/client'
import { RouterView } from './router'
import { ProviderVigilClient } from './providers/provider_vigil_client'
import { ProviderTheme } from './providers/provider_theme'
import { ProviderIdentity } from './providers/provider_identity'
import { ProviderUser } from './providers/provider_user'
import { ProviderOrganization } from './providers/provider_organization'
import { ProviderRoles } from './providers/provider_roles'
import { Toast } from './providers/provider_toast'
import { ProviderNotifications } from './providers/provider_notification'
import { ProviderSession } from './providers/provider_session'
import { Environment } from './environment'
import * as Sentry from "@sentry/react";

// TODO: Use the process.env if we are in dev mode.
Environment.init(window.env!);

Sentry.init({
  dsn: "https://04202742f6919c492e7af48f39c391c5@o1346134.ingest.us.sentry.io/4506942810292224",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ProviderVigilClient>
    <ProviderSession>
      <ProviderTheme>
        <Toast>
          <ProviderIdentity>
            <ProviderUser>
              <ProviderOrganization>
                <ProviderNotifications />
                <ProviderRoles>
                  <RouterView />
                </ProviderRoles>
              </ProviderOrganization>
            </ProviderUser>
          </ProviderIdentity>
        </Toast>
      </ProviderTheme>
    </ProviderSession>
  </ProviderVigilClient>
)
