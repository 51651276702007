import useBreakpoints from "../../hooks/hook_breakpoints";
import { IconArrowDownSolid, IconArrowUpSolid } from "../icons";
import { ColumnInfo, Sorting } from "./table_types";

interface TableHeaderProps<T> {
  sortingSelected: Sorting;
  onSortingChange: (sorting: Sorting) => void;

  columns: ColumnInfo<T>[];

  rowsSelectable: boolean;
  rowsSelected: boolean;
  onRowsSelectedChange: (value: boolean) => void;
}

export const TableHeader = <T,>(props: TableHeaderProps<T>) => {
  const breakpoints = useBreakpoints();

  // Sorted mechanism
  const toggleSort = (id: string) => {
    if (props.sortingSelected.id === id) {
      props.onSortingChange({ id, direction: props.sortingSelected.direction === 'ascending' ? 'descending' : 'ascending' });
    } else {
      props.onSortingChange({ id, direction: 'ascending' });
    }
  }

  // Render
  if (breakpoints['MD']) {
    return (
      <thead className='bg-base-200'>
        <tr>
          { props.rowsSelectable && (
            <th className='rounded-none'>
              <label>
                <input type="checkbox" className="checkbox" checked={props.rowsSelected} onChange={() => props.onRowsSelectedChange(!props.rowsSelected)} />
              </label>
            </th>
          )}
          { props.columns.map((item, index) => {
            return (
              <th key={index} className='rounded-none'>
                <div className={`select-none flex group ${item.sortable ? ' cursor-pointer' : ''}`} onClick={() => { if (item.sortable) toggleSort(item.id) }}>
                  <div className='text-sm/3 text-base-content'>{item.header}</div>
                  {
                    item.sortable && (
                      item.id === props.sortingSelected.id && props.sortingSelected.direction === 'descending' && (
                        <IconArrowDownSolid className={'h-4 ml-1'} />
                      ) ||
                      <IconArrowUpSolid className={(item.id === props.sortingSelected.id ? 'opacity-100 ' : 'opacity-0 group-hover:opacity-50 ') + 'h-4 ml-1'} />
                    )
                  }
                </div>
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }

  return <thead className="border-b border-base-300"></thead>
};