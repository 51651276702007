import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IBeaconSite extends IBaseSyncable {
  uuidBeacon: string;
  uuidSite: string;
}

export const schemaBeaconSite: TTSchema<IBeaconSite> = {
  ...schemaBaseSyncable,
  uuidBeacon: {
    type: String,
    indexed: true,
  },
  uuidSite: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceBeaconSite = new TTSchemaSyncableInstance<IBeaconSite>('beacon_sites', schemaBeaconSite, 0);
