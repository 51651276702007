import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface ISiteOrganization extends IBaseSyncable {
  uuidSite: string;
  uuidOrganization: string;
}

export const schemaSiteOrganization: TTSchema<ISiteOrganization> = {
  ...schemaBaseSyncable,
  uuidSite: {
    type: String,
    indexed: true,
  },
  uuidOrganization: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceSiteOrganization = new TTSchemaSyncableInstance<ISiteOrganization>('site_organizations', schemaSiteOrganization, 0);
