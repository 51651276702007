import useBreakpoints from '../../hooks/hook_breakpoints';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IBeacon, ISite, Permissions } from 'vigil-datamodel';
import { FullPageLoader } from '../../components/full_page_loader';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../routes';
import { InputButton } from '../../components/input_button';
import { IconBrokenLinkOutline, IconChevronRightSolid, IconLinkSolid, IconPencilSquareSolid, IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { TTuuid } from 'tt-uuid';
import { useModal } from '../../hooks/hook_modal';
import { StatusAlert } from '../../components/status_alert';
import { ModalBeaconDelete } from './func_beacon/modal_beacon_delete';
import { ModalBeaconUpdate } from './func_beacon/modal_beacon_update';
import { ModalSiteCreate } from './func_site/modal_site_create';
import { ModalBeaconSitesCreate } from './func_beacon_site/modal_beacon_sites_create';
import { ModalBeaconSitesDelete } from './func_beacon_site/modal_beacon_sites_delete';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { VigilMap, VigilMapController } from '../../components/vigil_map';
import { VigilMapBeacon, VigilMapMessageId, VigilMapV2Props, VigilMapV2ThemeId, getBoundingBox } from 'vigil-map';
import { ContextTheme } from '../../providers/provider_theme';

interface ScreenHomeBeaconProps { }

export const ScreenHomeBeacon: React.FC<ScreenHomeBeaconProps> = (props) => {
  /* State */
  const navigate = useNavigate()
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);
  const theme = useContext(ContextTheme);

  const breakpoints = useBreakpoints();

  const [stateError, setError] = useState<string>('');

  const [stateBeaconLoading, setBeaconLoading] = useState(true);
  const [stateBeacon, setBeacon] = useState<IBeacon>();

  const [stateSitesLoading, setSitesLoading] = useState(true);
  const [stateSites, setSites] = useState<ISite[]>();

  const [stateSelectedSite, setSelectedSite] = useState<ISite>();

  const [stateSnapped, setSnapped] = useState(false);
  const [stateMapController, setMapController] = useState(null as null | VigilMapController);
  const [stateMapProps, setMapProps] = useState({} as VigilMapV2Props);

  const [stateBeaconMarkers, setBeaconMarkers] = useState<VigilMapBeacon[]>([]);

  const { isOpen: isOpenModalUpdateBeacon, toggle: toggleModalUpdateBeacon } = useModal();
  const { isOpen: isOpenModalDeleteBeacon, toggle: toggleModalDeleteBeacon } = useModal();
  const { isOpen: isOpenModalCreateSite, toggle: toggleModalCreateSite } = useModal();
  const { isOpen: isOpenModalDeleteBeaconSites, toggle: toggleModalDeleteBeaconSites } = useModal();
  const { isOpen: isOpenModalCreateBeaconSites, toggle: toggleModalCreateBeaconSites } = useModal();

  const params = useParams();
  const uuidBeacon = params['uuid'] || '';

  useEffect(() => {
    setMapProps({
      beacons: stateBeaconMarkers,
      zoomControl: breakpoints['MD'] ? true : false,
      theme: theme === 'dark' ? VigilMapV2ThemeId.DARK_MATTER : VigilMapV2ThemeId.BASIC,
    })
    if (stateMapController && stateBeaconMarkers.length > 0 && !stateSnapped) {
      stateMapController?.sendVigilMessage({
        id: VigilMapMessageId.FIT_BOUNDS,
        bounds: getBoundingBox(stateBeaconMarkers.map((beacon) => ({ lat: beacon.lat, lng: beacon.lng }))),
        options: {
          maxZoom: 18,
        }
      });
      setSnapped(true);
    }
  }, [stateBeaconMarkers, stateMapController, breakpoints]);
  useEffect(() => { fetchBeacon(uuidBeacon); fetchSites(uuidBeacon); }, [uuidBeacon]);

  async function fetchBeacon(uuid: string) {
    try {
      setBeaconLoading(true);
      const beacon = await vigil.functions.beaconFindOne({ filter: { uuid } });
      setBeaconMarkers([{
        uuid: beacon.uuid,
        lat: beacon.latitude,
        lng: beacon.longitude,
        name: beacon.name,
        color: 'red'
      }]);
      setBeacon(beacon);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setBeaconLoading(false);
    }
  }

  async function fetchSites(uuidBeacon: string) {
    try {
      setSitesLoading(true);
      const sites = await vigil.functions.siteLinkedTo({ uuid: uuidBeacon, type: 'beacon' });
      setSites(sites);
      setSitesLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSitesLoading(false);
    }
  }

  function toggleSelectedSite(site: ISite) {
    if (stateSelectedSite == site) {
      setSelectedSite(undefined);
    } else {
      setSelectedSite(site);
    }
  }

  async function linkSiteToBeacon(site: ISite) {
    try {
      if (!uuidBeacon) return;
      await vigil.functions.beaconSitesLinkCreate({ uuidBeacon: uuidBeacon, uuidSites: [site.uuid] })
      await fetchSites(uuidBeacon);
    } catch (error: any) {
      setError(error.message);
    }
  }

  if (stateBeaconLoading || !stateBeacon) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full h-full flex flex-col'>
      <ModalBeaconUpdate isOpen={isOpenModalUpdateBeacon} toggle={toggleModalUpdateBeacon} onSubmit={async () => await fetchBeacon(uuidBeacon)} uuidBeacon={uuidBeacon} />
      <ModalBeaconDelete isOpen={isOpenModalDeleteBeacon} toggle={toggleModalDeleteBeacon} beacons={[stateBeacon]} onSubmit={async () => navigate(ROUTES.ROUTE_HOME_BEACONS)} />
      <ModalSiteCreate isOpen={isOpenModalCreateSite} toggle={toggleModalCreateSite} onSubmit={async (site) => await linkSiteToBeacon(site)} />
      <ModalBeaconSitesCreate isOpen={isOpenModalCreateBeaconSites} toggle={toggleModalCreateBeaconSites} uuidBeacon={uuidBeacon} onSubmit={async () => await fetchSites(uuidBeacon)} />
      <ModalBeaconSitesDelete isOpen={isOpenModalDeleteBeaconSites} toggle={toggleModalDeleteBeaconSites} uuidBeacon={uuidBeacon} uuidSites={stateSelectedSite ? [stateSelectedSite.uuid] : []} onSubmit={async () => await fetchSites(uuidBeacon)} />

      <Breadcrumbs crumbs={[{ id: 'beacons', onClick: () => navigate(ROUTES.ROUTE_HOME_BEACONS), text: 'Beacons' }, { id: uuidBeacon, onClick: () => { }, text: stateBeacon.name }]} />

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <div className='flex items-center'>
        <div className='flex flex-grow items-end'>
          {/* <div className='text-2xl mr-10'>{stateBeacon.name}</div> */}
          <div className="flex items-center mr-6">
            <span className='font-semibold'>Date Created:</span>
            <span className="text-sm ml-2">{TTuuid.decodeCuuid(stateBeacon.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateBeacon.uuid).time.toLocaleTimeString()}</span>
          </div>
          <div className="flex items-center mr-6">
            <span className='font-semibold'>Last Updated:</span>
            <span className="text-sm ml-2">{TTuuid.decodeCuuid(stateBeacon.changeStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateBeacon.changeStamp).time.toLocaleTimeString()}</span>
          </div>
        </div>

        <div className='mx-4'>
          <InputButton text='Edit Beacon' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.U, Permissions.RBeacon)} onClick={() => toggleModalUpdateBeacon()} />
        </div>
        <InputButton text='Delete Beacon' before={<IconTrashSolid className='h-5 mr-2' />} type='btn-error' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.RBeacon)} onClick={() => toggleModalDeleteBeacon()} />
      </div>
      <div className='flex items-end'>
        <div className="flex items-center mr-6">
          <span className='font-semibold'>Type:</span>
          <span className="text-sm ml-2">{stateBeacon.type}</span>
        </div>
        <div className="flex items-center mr-6">
          <span className='font-semibold'>Latitude:</span>
          <span className="text-sm ml-2">{stateBeacon.latitude}</span>
        </div>
        <div className="flex items-center mr-6">
          <span className='font-semibold'>Longitude:</span>
          <span className="text-sm ml-2">{stateBeacon.longitude}</span>
        </div>
        <div className="flex items-center mr-6">
          <span className='font-semibold'>Altitude:</span>
          <span className="text-sm ml-2">{stateBeacon.altitude}</span>
        </div>
        <div className="flex items-center mr-6">
          <span className='font-semibold'>Radius:</span>
          <span className="text-sm ml-2">{stateBeacon.radius}m</span>
        </div>
      </div>

      <div className='flex flex-grow py-2'>
        <div className="card w-80 mr-2 z-[1]">
          <div className='rounded-t-xl bg-base-300'>
            <div className='flex justify-center font-bold mt-2'>Linked Sites</div>
            <div className='p-2 flex flex-row'>
              <InputButton text='Create Site' before={<IconPlusSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RSite)} onClick={() => toggleModalCreateSite()} />
              <div className='mx-2'></div>
              <InputButton text='Link Site' before={<IconLinkSolid className='h-5 mr-2' />} type='btn-secondary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RBeaconSiteLink)} onClick={() => toggleModalCreateBeaconSites()} />
            </div>
          </div>

          <div className='rounded-b-xl bg-base-200 p-2 h-full'>
            {stateSites && stateSites.map((site) => {
              const decodedUuid = TTuuid.decodeCuuid(site.uuid);
              const dateCreated = decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString();
              const decodedChangeStamp = TTuuid.decodeCuuid(site.changeStamp);
              const dateUpdated = decodedChangeStamp.time.toLocaleDateString() + " " + decodedChangeStamp.time.toLocaleTimeString();
              return (
                <div key={site.uuid} onClick={() => toggleSelectedSite(site)} className={`card dropdown dropdown-right w-full hover:cursor-pointer hover:bg-base-200 flex flex-row items-center p-2 drop-shadow-xl mb-2 ${stateSelectedSite == site ? 'bg-base-200 dropdown-open' : 'bg-base-100 dropdown-close'}`}>
                  <div className='flex flex-col flex-grow text-left'>
                    <span className='text-md font-semibold'>{site.name}</span>
                  </div>
                  <IconChevronRightSolid className={`h-5 transform transition-transform duration-300 ${stateSelectedSite == site ? 'rotate-180' : ''}`} />

                  <div onClick={(event) => event.stopPropagation()} className={`cursor-auto ml-3 p-2 shadow dropdown-content bg-base-100 rounded-box w-64 outline outline-1 outline-neutral-content ${stateSelectedSite == site ? '' : 'hidden'}`}>
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1" onClick={() => toggleSelectedSite(site)}>✕</button>
                    <div className='text-lg font-semibold mb-2 text-center'>{site.name}</div>
                    <div className='text-left'>
                      <div className='text-xs'>UUID: {site.uuid}</div>
                      <div className='text-xs'>Date created: {dateCreated}</div>
                      <div className='text-xs mb-4'>Last updated: {dateUpdated}</div>
                      <InputButton text='Edit Site' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={false} onClick={() => navigate(ROUTES.ROUTE_HOME_SITE.replace(':uuid', site.uuid))} />
                      <div className='mt-2' />
                      <InputButton text='Unlink Site' before={<IconBrokenLinkOutline className='h-4 mr-2 fill-warning-content' />} type='btn-warning' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.RBeaconSiteLink)} onClick={() => toggleModalDeleteBeaconSites()} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className='flex-grow rounded-xl overflow-hidden'>
          <VigilMap setMapController={setMapController} state={stateMapProps} />
        </div>
      </div>
    </div>
  );
};
