
import { sleep, IBeanDiff, IKeyStore, schemaInstanceKeyStore, TTDefinition } from "tt-coms";

import { liveQuery } from "dexie";
import { Unsubscribable } from "@trpc/server/observable";

import { TTComsApi } from "./api.js";
import { TTComsClientDB } from "./db.js";
import { TTComsLogger, TTComsLoggerChannel } from "./ttcoms_logger.js";
import { TTSubscription } from "./ttcoms_subscription.js";

export type Preference = string | number | boolean | object | null;

export interface TTComsClientOptions {
  url: string,
  reconnectSeconds?: number,
  heartbeatSeconds?: number,
  maxSyncableSeconds?: number,
  batchSizeChangesPush?: number,
  batchSizeQueriesPush?: number,
  maxFunctionInvocationTime?: number,
  tokenValidator?: (token: string) => Promise<boolean>,
  debug?: {
    disablePushChanges: boolean,
    disablePushQueries: boolean
  }
}
export class TTComsClient<TDefinition extends TTDefinition> {
  public readonly db: TTComsClientDB;
  public readonly definition: TDefinition;

  private readonly api: TTComsApi;
  private lastHeartbeat: Date = new Date(0);
  private connected: boolean = false;
  private token: string | null = null;
  private subscriptionChanges: Unsubscribable | null = null;
  private subscriptionQueries: Unsubscribable | null = null;

  private stopMainLoop: boolean = false;

  private options: Required<TTComsClientOptions> = {
    url: '',
    heartbeatSeconds: 10,
    reconnectSeconds: 20,
    maxSyncableSeconds: 0.5,
    batchSizeChangesPush: 10,
    batchSizeQueriesPush: 10,
    maxFunctionInvocationTime: 10,
    tokenValidator: async (token: string) => { return true },
    debug: {
      disablePushChanges: false,
      disablePushQueries: false
    }
  }

  constructor(definition: TDefinition, options: TTComsClientOptions) {
    this.definition = definition;

    this.options = {
      ...this.options,
      ...options
    }

    this.api = new TTComsApi(this.options.url);
    this.db = new TTComsClientDB(this.definition, this.api);

  }

  private async connect() {
    // Clear subscriptions
    this.subscriptionChanges?.unsubscribe();
    this.subscriptionQueries?.unsubscribe();
    this.subscriptionChanges = null;
    this.subscriptionQueries = null;

    // Clear queries (we don't know if the server has the queries or not)
    this.db.getQueryManager().unconfirmed();

    // Connect
    const token = await this.getToken();
    await this.api.client.connect.query({ token: token || '' });

    // TODO: Check the min supported datamodel and make sure we confirm 
    this.lastHeartbeat = new Date();

    // Set up change listener for diffs
    this.subscriptionChanges = this.api.client.changes.subscribe(undefined, {
      onData: async (value: any) => {
        for (const change of value) {
          this.db.applyServerChange(change as IBeanDiff);
        }
      }
    });

    this.subscriptionQueries = this.api.client.queries.subscribe(undefined, {
      onData: async (value: any) => {
        console.log('SERVER SENT: QUERY UPDATE');
        console.log(value)
      }
    });

    this.connected = true;
  }

  private reconnect() {
    this.lastHeartbeat = new Date(0);
    this.connected = false;
  }

  private async heartbeat() {
    await this.api.client.ping.query();
    this.lastHeartbeat = new Date();
  }

  private async pushChanges() {
    if (this.options.debug.disablePushChanges) { return; }
    const changes = this.db.getDiffManager().collect(this.options.batchSizeChangesPush);
    if (changes.length > 0) {
      // Send changes
      await this.api.client.pushChanges.mutate(changes);

      // Confirm changes
      this.db.getDiffManager().confirm(changes);
    }
  }

  private async pushQueries() {
    if (this.options.debug.disablePushChanges) { return; }
    const queries = this.db.getQueryManager().getBatch(this.options.batchSizeQueriesPush);

    if (queries.length > 0) {
      // Send queries
      await this.api.client.pushQueries.mutate(queries);

      // Confirm queries
      for (const query of queries) {
        this.db.getQueryManager().confirm(query);
      }
    }
  }

  private async tick() {
    try {
      // If we're close to heartbeat timeout, attempt to get new heartbeat
      if (Date.now().valueOf() > this.lastHeartbeat.valueOf() + 1000 * this.options.heartbeatSeconds) {
        await this.heartbeat();
      }

      // Get token
      const token = await this.getToken();

      // If token is different from stashed token, reconnect
      if (this.token != token) {
        this.reconnect();
        this.token = token;
        return;
      }

      if (!this.token) {
        return;
      }

      const tokenValid = await this.options.tokenValidator(this.token);
      if (!tokenValid) {
        return;
      }

      // If we haven't connected or received a heartbeat, attempt to reconnect
      if (!this.connected || Date.now().valueOf() > this.lastHeartbeat.valueOf() + 1000 * this.options.reconnectSeconds) {
        await this.connect();
      }

      // Push changes
      await this.pushChanges();

      // Push queries
      await this.pushQueries();
    }
    catch (error: any) {
      // Force a reconnect immediately
      this.reconnect();
      TTComsLogger.log(TTComsLoggerChannel.OpsMessage, 'Error caught: ' + error.toString());
    }
  }

  private async mainLoop() {
    while (true) {
      if (this.stopMainLoop) break;
      await sleep(this.options.maxSyncableSeconds * 1000);
      await this.tick();
    }
  }

  public async forceTick() {
    if (this.stopMainLoop) {
      return;
    }
    await this.tick();
  }

  public async start() {
    // Start DB
    await this.db.start();

    // Start main loop
    this.stopMainLoop = false;
    this.mainLoop();
  }

  public async stop() {
    // Stop DB
    await this.db.stop();

    // Stop main loop
    this.stopMainLoop = true;
  }

  public async setToken(token: string | null) {
    // TODO: If a ttcoms user also sets a key 'token' we will have problems.
    // make this safe, could have private setPreference method which allows
    // key to be overriden, this method can then use that private method.
    await this.setPreference('token', token);
  }

  public async getToken() {
    return this.getPreference('token');
  }

  public async getTokenReactive(listener: (value: string | null) => void): Promise<TTSubscription> {
    return this.getPreferenceReactive('token', (value: Preference) => { listener(value as string | null) });
  }

  public async setPreference(key: string, value: Preference) {
    key = 'pref_' + key;
    const dbKeystore = this.db.getCollection<IKeyStore>(schemaInstanceKeyStore);

    if (value == null) {
      await dbKeystore.getTable().where('key').equals(key).delete();
    }
    else {
      await dbKeystore.getTable().put({ key: key, value: JSON.stringify(value) });
    }
  }

  public async getPreference(key: string) {
    key = 'pref_' + key;
    const dbKeystore = this.db.getCollection<IKeyStore>(schemaInstanceKeyStore);
    const result = await dbKeystore.getTable().get({ key: key });
    return result ? JSON.parse(result.value) : null;
  }

  public async getPreferenceReactive(key: string, listener: (value: Preference) => void): Promise<TTSubscription> {
    key = 'pref_' + key;
    return new Promise((resolve, reject) => {
      const observable = liveQuery<Preference>(async () => {
        const dbKeystore = this.db.getCollection<IKeyStore>(schemaInstanceKeyStore);
        const result = await dbKeystore.getTable().get({ key: key });
        const resultClean = result ? JSON.parse(result.value) : null;
        return resultClean;
      });

      const subscription = observable.subscribe({
        next: (items) => {
          listener(items);
          resolve(subscription);
        }
      });

      return subscription;
    });
  }
}

