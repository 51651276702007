export class Base64HelperV1 {
  static key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-~';

  static fromNumber(number: number): string {
    if (number < 0) {
      throw new Error("Can't represent negative numbers");
    }
    let rixit: number;
    let residual = Math.floor(number);
    let result = '';
    while (true) {
      rixit = residual % 64;
      result = Base64HelperV1.key[rixit] + result;
      residual = Math.floor(residual / 64);
      if (residual === 0) break;
    }
    return result;
  }

  static toNumber(input: string): number {
    let result = 0;
    for (let i = 0; i < input.length; i++) {
      result = result * 64 + Base64HelperV1.key.indexOf(input[i]);
    }
    return result;
  }
}

class CuuidDecodedV1 {
  constructor(public time: Date, public randomNumber: number) { }
}

export class CuuidV1 {
  static getCuuid(): string {
    const random = Math.random();
    const date64 = Base64HelperV1.fromNumber(Date.now()).padStart(8, '_');
    const rand64 = Base64HelperV1.fromNumber(Math.floor(random * 9007199254740991)).padStart(9, '_');
    return date64 + rand64;
  }

  static decodeCuuid(cuuid: string): CuuidDecodedV1 {
    let left = cuuid.substring(0, 8);
    left = left.replace(/_/g, '');
    const ms = Base64HelperV1.toNumber(left);
    let right = cuuid.substring(9, cuuid.length - 3);
    right = right.replace(/_/g, '');
    const rand = Base64HelperV1.toNumber(right);
    return new CuuidDecodedV1(
      new Date(ms),
      rand,
    );
  }
}