import useBreakpoints from "../../hooks/hook_breakpoints";
import { TableProps } from "./table";

interface TableActionBarProps<T> extends TableProps<T> {
  getSelectedItems(): T[];
  setRowsSelected: (value: boolean) => void;

  colorClassMap: {
    primary: string;
    secondary: string;
    warning: string;
    error: string;
  };
}

export const TableActionBar = <T,>(params: { props: TableActionBarProps<T> }) => {
  const breakpoints = useBreakpoints();
  const props = params.props;

  const selectedItems = props.getSelectedItems();

  const ActionButtons = () => {
    return (
      <>
        {/* TODO: Maybe use any other component in future, not just button (discusison) */}
        {selectedItems.length == 0 && (props.unSelectedActions?.map((action, index) => {
          const colorClass = props.colorClassMap[action.color ? action.color : 'primary'];
          return <button
            key={index}
            className={`btn btn-ghost btn-sm hover:bg-opacity-20 ${colorClass}`}
            onClick={action.onClick}
            disabled={action.disabled}
          >{action.buttonChildren}</button>
        }))}
        {/* Add single select actions here, it only activates when one item is selected */}
        {selectedItems.length == 1 && (props.singleSelectActions?.map((action, index) => {
          const colorClass = props.colorClassMap[action.color ? action.color : 'primary'];
          return <button
            key={index}
            className={`btn btn-ghost btn-sm hover:bg-opacity-20 ${colorClass}`}
            onClick={() => { action.onClick(props.getSelectedItems()) }}
            disabled={action.disabled}
          >{action.buttonChildren}</button>
        }))}
        {/* Add multi select actions here, it only activates when multiple items are selected */}
        {selectedItems.length > 1 && breakpoints['MD'] && (props.selectedActions?.map((action, index) => {
          const colorClass = props.colorClassMap[action.color ? action.color : 'primary'];
          return <button
            key={index}
            className={`btn btn-ghost btn-sm hover:bg-opacity-20 ${colorClass}`}
            onClick={() => { action.onClick(props.getSelectedItems()) }}
            disabled={action.disabled}
          >{action.buttonChildren}</button>
        }))}
        {/* Add select actions here, it only activates if it is on mobile */}
        {selectedItems.length > 0 && !breakpoints['MD'] && (props.selectedActions?.map((action, index) => {
          const colorClass = props.colorClassMap[action.color ? action.color : 'primary'];
          return <button
            key={index}
            className={`btn btn-ghost btn-sm hover:bg-opacity-20 ${colorClass}`}
            onClick={() => { action.onClick(props.getSelectedItems()) }}
            disabled={action.disabled}
          >{action.buttonChildren}</button>
        }))}
      </>
    )
  };

  if (breakpoints['MD']) {
    return (
      <div className={`rounded-t-xl border-b border-base-100 ${selectedItems.length > 0 ? 'bg-base-100' : 'bg-base-200'}`}>
        <div className={`rounded-t-xl flex p-3 bg-primary ${selectedItems.length > 0 ? 'bg-opacity-10' : 'bg-opacity-0'}`}>
          <div className='justify-center items-center flex ml-2 mr-10'>
            {selectedItems.length == 0 && (<div className='font-bold'>{props.name}</div>)}
            {props.selectable && selectedItems.length == 0 && (<div className='mx-1'>|</div>)}
            {props.selectable && selectedItems.length > 0 && (<div className="tooltip" data-tip="Reset Selection"><button className="btn btn-sm btn-circle btn-ghost" onClick={() => props.setRowsSelected(false)}>✕</button></div>)}
            {props.selectable && (selectedItems.length > 0 ? (selectedItems.length > 1 ? selectedItems.length + ` ${props.name.toLowerCase()} selected` : `1 ${props.name.toLowerCase().slice(0, -1)} selected`) : `Showing all ${props.name.toLowerCase()}`)}
          </div>
          <ActionButtons />
        </div>
      </div>
    )
  } else {
    return (
      <div className={`rounded-t-xl border-b border-base-100 ${selectedItems.length > 0 ? 'bg-base-100' : 'bg-base-200'}`}>
        <div className={`rounded-t-xl flex p-2 bg-primary ${selectedItems.length > 0 ? 'bg-opacity-10' : 'bg-opacity-0'}`}>
          <div className='justify-center items-center flex ml-2 mr-10'>
            {selectedItems.length == 0 && (<div className='font-bold'>{props.name}</div>)}
            {props.selectable && selectedItems.length == 0 && (<div className='mx-1'>|</div>)}
            {props.selectable && selectedItems.length > 0 && (<div className="tooltip" data-tip="Reset Selection"><button className="btn btn-sm btn-circle btn-ghost" onClick={() => props.setRowsSelected(false)}>✕</button></div>)}
            {props.selectable && (selectedItems.length > 0 ? (selectedItems.length > 1 ? selectedItems.length + ` ${props.name.toLowerCase()} selected` : `1 ${props.name.toLowerCase().slice(0, -1)} selected`) : `Showing all ${props.name.toLowerCase()}`)}
          </div>
        </div>
        <div className={`border-b border-base-300 px-1 pb-1 bg-primary ${selectedItems.length > 0 ? 'bg-opacity-10' : 'bg-opacity-0'}`}><ActionButtons /></div>
      </div>
    )
  }
};