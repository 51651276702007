import { TTSchema } from "./schema.js";
import { schemaBase, IBase } from "./bean_base.js";

export interface IBaseSyncable extends IBase {
  changeStamp: string;
  serverStamp: string;
  deleted: number;
}

export const schemaBaseSyncable: TTSchema<IBaseSyncable> = {
  ...schemaBase,
  changeStamp: {
    type: String,
    indexed: true,
  },
  serverStamp: {
    type: String,
    indexed: true,
  },
  deleted: {
    type: Number,
    indexed: true,
  },
}

// Helper type to strip uuid, changestamp, serverstamp & deleted
export type TTComsCleanCreate<T> = T extends { id: infer Id, data: infer Data }
  ? { id: Id, data: Data } & Omit<T, keyof IBaseSyncable | 'id' | 'data'>
  : Omit<T, keyof IBaseSyncable>;
export type TTComsCleanUpdate<T> = Partial<TTComsCleanCreate<T>>;
export type TTComsCleanDelete<T> = { uuid: string };

// We don't createa an instance, because we don't actually store this bean, it's a building block
