import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../routes';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { IReport } from 'vigil-datamodel';

interface ScreenHomeReportProps { }

export const ScreenHomeReport: React.FC<ScreenHomeReportProps> = (props) => {
  /* State */
  const navigate = useNavigate();
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidReport = params['uuid'] || '';

  const [stateError, setError] = useState<string>('');
  const [stateReportLoading, setReportLoading] = useState(true);
  const [stateReport, setReport] = useState<IReport>();

  useEffect(() => { fetchReport(); }, [uuidReport]);

  async function fetchReport() {
    try {
      setReportLoading(true);
      const report = await vigil.functions.reportFindOne({ filter: { uuid: uuidReport } });
      setReport(report);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setReportLoading(false);
    }
  }

  function navigateTab(pageId: string) {
    navigate({
      pathname: ROUTES.ROUTE_HOME_REPORT.replace(':uuid', uuidReport) + '/' + pageId,
    })
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  if (stateReportLoading || !stateReport) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full h-full'>
      <Breadcrumbs crumbs={[{ id: 'reports', onClick: () => navigate(ROUTES.ROUTE_HOME_REPORTS), text: 'Reports' }, { id: uuidReport, onClick: () => { }, text: stateReport.name }]} />

      {/* <Tabs selectedId={getSelectedId()} onSelect={navigateTab} items={[
        {
          icon: IconDocumentMagnifyingGlass,
          id: 'overview',
          text: 'Report Overview'
        },
      ]} /> */}

      <div className='w-full flex-grow'>
        <Outlet context={{ reportParent: stateReport, fetchReportParent: fetchReport }} />
      </div>
    </div>
  )
};
