import React from 'react';

interface ScreenHomeSettingsProps  {}

export const ScreenHomeSettings: React.FC<ScreenHomeSettingsProps> = (props) => {
  return (
    <div>
      settings: change some things
    </div>
  );
};
