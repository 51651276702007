import { TTComsCleanCreate, TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";
import { BeaconType, IDeviceStateLog } from "./main";

export enum ChartId {
  UserCount = 'userCount',
  SiteCount = 'siteCount',
  BeaconCount = 'beaconCount',
  DeviceCount = 'deviceCount',
  MapLive = 'mapLive',
}

export interface IChartUserCount {
  userCount: number
}
export interface IChartSiteCount {
  siteCount: number
}
export interface IChartBeaconCount {
  beaconCount: number
}
export interface IChartDeviceCount {
  deviceCount: number
}

interface IMapUser {
  uuid: string,
  firstName: string,
  lastName: string,
}

type IMapDevice = Omit<Required<IDeviceStateLog>, keyof IBaseSyncable> & { uuidLastSeen: string, user?: IMapUser }

interface IMapBeacon {
  uuid: string,
  name: string,
  type: BeaconType,
  lat: number,
  lng: number,
  alt: number,
}
interface IMapSite {

}
export interface IChartMapLive {
  devices: IMapDevice[],
  beacons: IMapBeacon[],
  sites: IMapSite[],
}

export type IChart = IBaseSyncable & { uuidOrganization: string } &
  (
    | { id: ChartId.UserCount, data: IChartUserCount }
    | { id: ChartId.SiteCount, data: IChartSiteCount }
    | { id: ChartId.BeaconCount, data: IChartBeaconCount }
    | { id: ChartId.DeviceCount, data: IChartDeviceCount }
    | { id: ChartId.MapLive, data: IChartMapLive }
  )

export const schemaChart: TTSchema<IChart> = {
  ...schemaBaseSyncable,
  uuidOrganization: {
    type: String,
    indexed: true,
  },
  id: {
    type: String,
    indexed: true,
  },
  data: {
    type: Object,
    indexed: false,
  },
}

export const schemaInstanceChart = new TTSchemaSyncableInstance<IChart>('chart', schemaChart, 0);
