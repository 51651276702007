export function TTFunction<TInput, TOutput>() {
  const internal = async () => {
    throw new Error('TTFunction: Polyfill failed.');
  }
  return internal as any as (input: TInput) => Promise<TOutput>;
}


export interface TTFunctionErrorJSON {
  message: string
}

export class TTFunctionError extends Error {
  public toJSON(): TTFunctionErrorJSON {
    return {
      message: this.message
    }
  }

  public static fromJSON(json: TTFunctionErrorJSON) {
    return new TTFunctionError(json.message);
  }
}

export type TTFunctionObject = {
  [key: string]: ReturnType<typeof TTFunction<any, any>>;
};
