import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IUserOrganization extends IBaseSyncable {
  uuidUser: string;
  uuidOrganization: string;
}

export const schemaUserOrganization: TTSchema<IUserOrganization> = {
  ...schemaBaseSyncable,
  uuidUser: {
    type: String,
    indexed: true,
  },
  uuidOrganization: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceUserOrganization = new TTSchemaSyncableInstance<IUserOrganization>('user_organizations', schemaUserOrganization, 0);
