import { FC, useRef, useEffect } from 'react';
import { VigilQRCode, VigilQRCodeScanner } from 'vigil-qr';

interface VigilQRScanProps {
  onScan: (data: VigilQRCode<any>) => void
}

export const VigilQRScan: FC<VigilQRScanProps> = (props) => {
  // Create refs
  const elementVideo = useRef(null as unknown as HTMLVideoElement);
  const elementCanvas = useRef(null as unknown as HTMLCanvasElement);

  // Start scanning
  useEffect(() => {
    const canvasContext = elementCanvas.current.getContext("2d", { willReadFrequently: true });
    const scanner = VigilQRCodeScanner;

    let videoStream: MediaStream | null = null;

    // Scan loop
    const timeout = setInterval(() => {
      if (!elementVideo.current) return;
      const canvasWidth = elementVideo.current.videoWidth;
      const canvasHeight = elementVideo.current.videoHeight;
      if (!canvasContext || !canvasWidth || !canvasHeight) {
        return;
      }

      if (elementCanvas.current.width != canvasWidth || elementCanvas.current.height != canvasHeight) {
        elementCanvas.current.width = canvasWidth;
        elementCanvas.current.height = canvasHeight;
      }

      canvasContext.drawImage(elementVideo.current, 0, 0, canvasWidth, canvasHeight);
      var imageData = canvasContext.getImageData(0, 0, canvasWidth, canvasHeight);
      var code = scanner.scan(imageData.data, imageData.width, imageData.height);
      code && props.onScan(code);
    }, 100);

    // Link video stream
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function (stream) {
      videoStream = stream;
      elementVideo.current.srcObject = stream;
      elementVideo.current.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
      elementVideo.current.play();
    });

    return () => {
      clearInterval(timeout);
      // Stop the video stream when the component is unmounted
      if (videoStream) {
        videoStream.getTracks().forEach(track => track.stop());
      }
    }
  }, []);

  return (
    <div>
      <canvas hidden={true} ref={elementCanvas}></canvas>
      <video className='rounded-xl' hidden={false} ref={elementVideo}></video>
    </div>
  )
}