import { ROUTES } from "./routes";
import { FullPageError } from "./components/full_page_error";
import { FullPageSuspend } from "./components/full_page_suspend";
import { RouteObject } from "react-router-dom";
import { ScreenProfile } from "./screens/profile/profile";
import { ScreenProfileBasicInfo } from "./screens/profile/profile_basic_info";
import { ScreenProfileOrganizations } from "./screens/profile/profile_organizations";
import { ScreenProfileAppearance } from "./screens/profile/profile_appearance";

export const RouterProfile: RouteObject[] = [{
  path: ROUTES.ROUTE_PROFILE,
  errorElement: <FullPageError />,
  element: <FullPageSuspend><ScreenProfile /></FullPageSuspend>,
  children: [
    {
      path: ROUTES.ROUTE_PROFILE_BASIC_INFO,
      element: <ScreenProfileBasicInfo />
    },
    {
      path: ROUTES.ROUTE_PROFILE_APPEARANCE,
      element: <ScreenProfileAppearance />
    },
    {
      path: ROUTES.ROUTE_PROFILE_ORGANIZATIONS,
      element: <ScreenProfileOrganizations />
    },
  ]
}]
