import { CuuidV1 } from "./cuuid_v1";

class Base64HelperV2 {
  static key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  static fromNumber(number: number): string {
    if (number < 0) {
      throw new Error("Can't represent negative numbers");
    }
    let rixit: number;
    let residual = Math.floor(number);
    let result = '';
    while (true) {
      rixit = residual % Base64HelperV2.key.length;
      result = Base64HelperV2.key[rixit] + result;
      residual = Math.floor(residual / Base64HelperV2.key.length);
      if (residual === 0) break;
    }
    return result;
  }

  static toNumber(input: string): number {
    let result = 0;
    for (let i = 0; i < input.length; i++) {
      result = result * Base64HelperV2.key.length + Base64HelperV2.key.indexOf(input[i]);
    }
    return result;
  }
}

class CuuidDecodedV2 {
  constructor(public time: Date, public randomNumber: number) { }
}

export class CuuidV2 {
  static getCuuid(options: { timestamp?: number; random?: number; hash?: string } = {}): string {
    const { timestamp, random, hash } = options;
    const randomGenerator = Math.random;
    const ts = (timestamp ?? Date.now()).toString();
    const rand = Base64HelperV2.fromNumber(random ?? Math.floor(randomGenerator() * 1000000000000));
    let hsh = hash == null ? '' : '_' + hash;
    hsh = hsh.replace(/:/g, '');
    return ts + '_' + rand + hsh;
  }

  static decodeCuuid(cuuid: string): CuuidDecodedV2 {
    const parts = cuuid.split('_');
    const timestamp = parseInt(parts[0], 10);
    const rand = Base64HelperV2.toNumber(parts[1]);
    return new CuuidDecodedV2(new Date(timestamp), rand);
  }

  static fixUuid(cuuid: string): string {
    if (!cuuid.startsWith('_')) {
      return cuuid;
    }
    const cuuidDecodedV2 = CuuidV1.decodeCuuid(cuuid);
    return CuuidV2.getCuuid({
      timestamp: cuuidDecodedV2.time.getTime(),
      random: cuuidDecodedV2.randomNumber % 1000000000000,
    });
  }
}