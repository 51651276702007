import React, { useContext, useEffect, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IBeaconDirectory, ISite, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { ModalBeaconCreate } from './func_beacon/modal_beacon_create';
import { ModalBeaconDelete } from './func_beacon/modal_beacon_delete';
import { useModal } from '../../hooks/hook_modal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { convertQueryFilterToMongoFilter } from './helper_home';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { useInterval } from '../../hooks/hook_interval';

interface ScreenHomeBeaconsProps { }

export const ScreenHomeBeacons: React.FC<ScreenHomeBeaconsProps> = (props) => {
  const navigate = useNavigate()
  const contextOrganization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);

  const [stateBeacons, setBeacons] = useState([] as IBeaconDirectory[]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState([] as QueryFilter[]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState({ id: 'uuid', direction: 'descending' } as Sorting);
  const [stateTablePagingSelected, setTablePagingSelected] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 } as Paging);

  const [ticker, setTicker] = useState(0);

  const { isOpen: isOpenModalCreateBeacon, toggle: toggleModalCreateBeacon } = useModal();
  const { isOpen: isOpenModalDeleteBeacon, toggle: toggleModalDeleteBeacon } = useModal();

  const [selectedBeaconsState, setSelectedBeacons] = useState<IBeaconDirectory[]>([]);

  /* Getters */
  async function fetchBeaconsBackground() {
    if (!contextOrganization) { return }

    const response = await vigil.functions.beaconDirectory({
      uuidOrganization: contextOrganization.uuid,
      filter: convertQueryFilterToMongoFilter(stateTableFiltersSelected),
      ordering: { field: stateTableSortingSelected.id, direction: stateTableSortingSelected.direction },
      pagination: { page: stateTablePagingSelected.currentPage, count: stateTablePagingSelected.itemsPerPage }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateBeacons)) {
      setBeacons(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }

  async function fetchBeacons() {
    try {
      setLoading(true);
      await fetchBeaconsBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update immediately
  useEffect(() => {
    fetchBeacons();
  }, [contextOrganization, ticker])

  // Update every 5 seconds
  useInterval(async () => {
    await fetchBeaconsBackground();
  }, 5000, false);

  function navigateBeacon(selectedBeacon: IBeaconDirectory) {
    navigate(ROUTES.ROUTE_HOME_BEACON.replace(':uuid', selectedBeacon.uuid))
  }

  function navigateSite(selectedSite: ISite) {
    navigate(ROUTES.ROUTE_HOME_SITE.replace(':uuid', selectedSite.uuid))
  }

  function deleteBeacons(selectedBeacons: IBeaconDirectory[]) {
    setSelectedBeacons(selectedBeacons)
    toggleModalDeleteBeacon();
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalBeaconCreate isOpen={isOpenModalCreateBeacon} toggle={toggleModalCreateBeacon} onSubmit={async () => await fetchBeacons()} />
      <ModalBeaconDelete isOpen={isOpenModalDeleteBeacon} toggle={toggleModalDeleteBeacon} onSubmit={async () => await fetchBeacons()} beacons={selectedBeaconsState} />

      <Table<IBeaconDirectory>
        className='h-full flex flex-col drop-shadow'
        name='Beacons'
        data={stateBeacons}
        selectable={true}
        loading={stateLoading}
        error={stateError}
        columns={[
          {
            id: 'name',
            header: 'Name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateBeacon(item)}>
                  {item.name}
                </div>
              );
            },
          },
          {
            id: 'siteCount',
            header: 'Sites Linked',
            sortable: true,
            value(item) {
              return <div className="dropdown dropdown-hover">
                <label tabIndex={0} className="m-1">{item.siteCount}</label>
                {item.siteCount > 0 &&
                  <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                    {item.sites.map((site) => {
                      return <li><a onClick={() => navigateSite(site)}>{site.name}</a></li>
                    })}
                  </ul>}
              </div>;
            },
          },
          {
            id: 'type',
            header: 'Type',
            sortable: true,
            value(item) {
              return <div>{item.type}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'changeStamp',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('name', 'Name', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
          new DateFilter('changeStamp', 'Last Updated', 'between'),
        ]}
        filtersSelected={stateTableFiltersSelected}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1) }}

        sortingOptions={null}
        sortingSelected={stateTableSortingSelected}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1) }}

        pagingOptions={null}
        pagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1) }}

        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new beacon</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RBeacon),
            onClick: () => toggleModalCreateBeacon(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RBeacon),
            onClick: (selectedItems) => deleteBeacons(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RBeacon),
            onClick: (selectedItems) => deleteBeacons(selectedItems)
          }
        ]}
      />
    </div >
  );
};
