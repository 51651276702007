import useBreakpoints from "../../hooks/hook_breakpoints";
import { useState } from "react";
import { ColumnInfo, SelectedAction } from "./table_types";

interface TableBodyProps<T> {
  setRowSelected: (index: number, value: boolean) => void;
  getRowSelected: (index: number) => boolean;
  data: T[],
  selectable: boolean,
  columns: ColumnInfo<T>[],
  hoverActions?: SelectedAction<T>[],

  colorClassMap: {
    primary: string;
    secondary: string;
    warning: string;
    error: string;
  };
  // rowCount: number;
}

export const TableBody = <T,>(props: TableBodyProps<T>) => {
  const breakpoints = useBreakpoints();

  const [hoverState, setHoverState] = useState<Record<number, boolean>>({});

  const onMouseEnter = (rowIndex: number) => {
    setHoverState(prevState => ({
      ...prevState,
      [rowIndex]: true,
    }));
  };

  const onMouseLeave = (rowIndex: number) => {
    setHoverState(prevState => ({
      ...prevState,
      [rowIndex]: false,
    }));
  };

  function getHighlightedItems(): T[] {
    return props.data.filter((_, index) => hoverState[index]);
  }

  return (
    <tbody className='bg-base-100'>
      {props.data.map((item, rowIndex) => {
        return (
          <tr
            key={rowIndex}
            onMouseEnter={() => onMouseEnter(rowIndex)}
            onMouseLeave={() => onMouseLeave(rowIndex)}
            className={`relative ${props.getRowSelected(rowIndex) ? 'bg-primary bg-opacity-10' : 'hover'}`}
          >
            {props.selectable && (
              <td key={'check'} className='w-1 rounded-none z-10'>
                <label>
                  <input type="checkbox" className="checkbox" checked={props.getRowSelected(rowIndex)} onChange={() => props.setRowSelected(rowIndex, !props.getRowSelected(rowIndex))} />
                </label>
              </td>
            )}

            {breakpoints['MD'] && props.columns.map((column, columnIndex) => {
              return (
                <td key={columnIndex} className='rounded-none'>
                  {column.value(item)}
                </td>
              )
            })}
            {!breakpoints['MD'] && props.columns.map((column, columnIndex) => {
              return (
                <td key={columnIndex} className='rounded-none block flex py-0.5'>
                  <div className="font-semibold mr-2">{column.header}:</div>{column.value(item)}
                </td>
              )
            })}
            {
              breakpoints['MD'] && hoverState[rowIndex] && (
                <td className="absolute h-full inset-0 flex items-center justify-end z-0 pointer-events-none">
                  <div className={`px-5 bg-base-200`}>
                    {props.hoverActions?.map((action, index) => {
                      const colorClass = props.colorClassMap[action.color ? action.color : 'primary'];
                      return <button
                        key={index}
                        className={`btn btn-ghost btn-sm hover:bg-opacity-20 pointer-events-auto ${colorClass}`}
                        onClick={() => { !props.getRowSelected(rowIndex) && props.setRowSelected(rowIndex, !props.getRowSelected(rowIndex)); action.onClick(getHighlightedItems()) }}
                        disabled={action.disabled}
                      >{action.buttonChildren}</button>
                    })}
                  </div>
                </td>
              )
            }
          </tr>
        )
      })}
    </tbody>
  )
};