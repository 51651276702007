

import React from 'react';
import { Line } from 'react-chartjs-2';
import { ChartBase, ChartBaseProps } from './chart_base';
import { StatusLoading } from '../status_loading';

export interface ChartLineDateTimeProps extends ChartBaseProps {
  loading?: boolean,
  values: {
    date: Date,
    value: number
  }[],
  minTime?: Date,
  maxTime?: Date
  minValue?: number,
  maxValue?: number,
}

export const ChartLineDateTime: React.FC<ChartLineDateTimeProps> = (props) => {
  const datas = props.values.map((value) => { return { x: value.date, y: value.value } })
  const labels = datas.map((v, i) => { return v.x.toString() })

  if (props.loading) {
    return <div className='w-100 h-100 flex justify-center'> <StatusLoading /> </div>
  }

  return (
    <ChartBase {...props}>
      <div className=''>
        <Line
          className=''
          data={{
            labels: labels,
            datasets: [{
              label: props.label,
              data: datas,
              borderColor: 'rgb(0, 192, 192)',
              tension: 0.2
            }]
          }}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                type: 'time',
                min: props.minTime?.toISOString() || undefined,
                max: props.maxTime?.toISOString() || undefined,
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 10
                }
              },
              y: {
                min: props.minValue || undefined,
                max: props.maxValue || undefined,
                suggestedMin: props.minValue,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || '';

                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += context.parsed.y;
                    }
                    return label;
                  }
                }
              }
            }
          }}
        />
      </div>
    </ChartBase>
  );
};
