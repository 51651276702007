import { useState } from "react";
import { InputButton } from "../../input_button";
import { BaseFilter, DateQueries, QueryFilter } from "./table_filter_types";

export class DateFilter implements BaseFilter {
  id: string;
  name: string;
  query: DateQueries;

  constructor(id: string, name: string, query: DateQueries) {
    this.id = id;
    this.name = name;
    this.query = query;
  }
}

export const TableDateFilterModal = (props: { dateFilter: DateFilter, onClose: () => void, onSubmit: (queryFilter: QueryFilter) => void }) => {
  const [value, setValue] = useState({
    startDate: null,
    endDate: null
  });

  const handleValueChange = (newValue: any) => {
    setValue(newValue);
  }

  return (
    <div className="card card-compact">
      <div className="card-body bg-base-100 flex rounded-xl">
        <span className="flex justify-between items-center bg-base-100">
          <span><div className='text-lg'>Filter '{props.dateFilter.name}'</div></span>
          <button className="btn btn-sm btn-circle btn-ghost" onClick={props.onClose}>✕</button>
        </span>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Start Date</span>
          </label>
          <input type="date" className="input input-bordered" onChange={(e) => handleValueChange({ ...value, startDate: e.target.value })} />
          <label className="label">
            <span className="label-text">End Date</span>
          </label>
          <input type="date" className="input input-bordered" onChange={(e) => handleValueChange({ ...value, endDate: e.target.value })} />
        </div>
        <span className="flex justify-end items-center">
          <InputButton text='Apply' loading={false} disabled={!value.startDate && !value.endDate} type='btn-primary' onClick={() => { props.onSubmit({ id: props.dateFilter.id, query: props.dateFilter.query, value: { startDate: value.startDate, endDate: value.endDate } }) }}></InputButton>
        </span>
      </div>
    </div>
  );
}