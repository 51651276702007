import { TTSchemaInstance } from './schema.js';
import { TTFunctionObject } from './function.js';

export class TTDefinition<TFunctions = TTFunctionObject> {
  public readonly name;
  public readonly schemas;

  constructor(name: string, schemas: TTSchemaInstance<any>[]) {
    this.name = name;
    this.schemas = schemas;
  }
}