import { useContext, useEffect, useState } from "react";
import { EventConfig, EventConfigId, ISite } from "vigil-datamodel";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { OptionSelect } from "../../../components/input_select";
import { InputSearchDropdown } from "../../../components/input_search_dropdown";
import { IconXCircleSolid } from "../../../components/icons";
import { StatusAlert } from "../../../components/status_alert";
import { StatusLoading } from "../../../components/status_loading";
import { DurationState, InputDuration } from "../../../components/input_duration";
import { InputText } from "../../../components/input_text";
import { VALIDATORS, validate } from "../../../validation";
import { getPatrolDuration, getPatrolDurationState } from "./helper_patrol";

interface EventPatrolUpdateProps {
  refetchData: boolean;

  patrolConfig: EventConfig & { type: EventConfigId.Patrol };
  onPatrolConfigChange?: (config: EventConfig & { type: EventConfigId.Patrol }) => void;

  patrolConfigValidation: string[];
  onPatrolConfigValidationChange?: (validation: string[]) => void;
}

export const EventPatrolUpdate: React.FC<EventPatrolUpdateProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const contextOrganization = useContext(ContextOrganization);

  const [stateError, setError] = useState<string>('');

  const [stateEnableBeaconCheckin, setEnableBeaconCheckin] = useState<boolean>(props.patrolConfig.beaconCheckin ? true : false);
  const [stateEnableGeofenceCheckin, setEnableGeofenceCheckin] = useState<boolean>(props.patrolConfig.geofenceCheckinRadius ? true : false);
  const [stateGeofenceRadius, setGeofenceRadius] = useState<number>(props.patrolConfig.geofenceCheckinRadius || 10);

  const [stateSitesLoading, setSitesLoading] = useState(false);
  const [stateSites, setSites] = useState<ISite[]>([]);
  const [stateNewSites, setNewSites] = useState<ISite[]>([]);
  const [stateOptionsSites, setOptionsSites] = useState<OptionSelect[]>([]);

  const [stateEnableDutyDuration, setEnableDutyDuration] = useState<boolean>(props.patrolConfig.duty ? true : false);
  const [stateStartRestOnCompletePatrol, setStartRestOnCompletePatrol] = useState<boolean>(props.patrolConfig.duty?.startRestOnCompletePatrol ? true : false);
  const [stateOnDutyDurationPicker, setOnDutyDurationPicker] = useState<DurationState>(getPatrolDurationState(props.patrolConfig.duty?.on || 60 * 60));
  const [stateOffDutyDurationPicker, setOffDutyDurationPicker] = useState<DurationState>(getPatrolDurationState(props.patrolConfig.duty?.off || 60 * 20));
  const [stateFailedPatrolPercentage, setFailedPatrolPercentage] = useState<number>(props.patrolConfig.duty?.failedPatrolPercentage || 100);

  const [stateEnableLateStartAlert, setEnableLateStartAlert] = useState<boolean>(props.patrolConfig.lateStartDuration ? true : false);
  const [stateLateStartDuration, setLateStartDuration] = useState<DurationState>(getPatrolDurationState(props.patrolConfig.duty?.off || 60 * 5));

  /* State */
  useEffect(() => { fetchSites(); }, [props.refetchData]);
  useEffect(() => {
    const sites = stateNewSites.map((site) => { return { uuid: site.uuid } });
    const duty = stateEnableDutyDuration ? { on: getPatrolDuration(stateOnDutyDurationPicker), off: getPatrolDuration(stateOffDutyDurationPicker), startRestOnCompletePatrol: stateStartRestOnCompletePatrol, failedPatrolPercentage: stateFailedPatrolPercentage } : undefined;
    const lateStartDuration = stateEnableLateStartAlert ? getPatrolDuration(stateLateStartDuration) : undefined;
    const beaconCheckin = stateEnableBeaconCheckin;
    const geofenceCheckinRadius = stateEnableGeofenceCheckin ? stateGeofenceRadius : undefined;
    if (props.onPatrolConfigChange) props.onPatrolConfigChange({ ...props.patrolConfig, sites, duty, lateStartDuration, beaconCheckin, geofenceCheckinRadius });
    if (props.onPatrolConfigValidationChange) props.onPatrolConfigValidationChange(validateForm());
  }, [stateNewSites, stateEnableBeaconCheckin, stateEnableGeofenceCheckin, stateGeofenceRadius, stateEnableDutyDuration, stateStartRestOnCompletePatrol, stateOnDutyDurationPicker, stateOffDutyDurationPicker, stateFailedPatrolPercentage, stateEnableLateStartAlert, stateLateStartDuration]);

  /* Fetch Data */
  async function fetchSites() {
    if (!contextOrganization) return;
    try {
      setError('');
      setSitesLoading(true);
      let sites = await vigil.functions.siteLinkedTo({ uuid: contextOrganization.uuid, type: 'organization' });
      setSites(sites);
      const uuidPatrolConfigSites = props.patrolConfig.sites.map((site) => { return site.uuid }) || [];
      setNewSites(sites.filter(site => uuidPatrolConfigSites.includes(site.uuid)));
      setOptionsSites(sites.filter(site => !uuidPatrolConfigSites.includes(site.uuid)).map((site) => { return { label: site.name, value: site.uuid } }));
      setSitesLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSitesLoading(false);
    }
  }

  /* UI Updates */
  function addNewSite(uuidSite: string) {
    setNewSites((prev) => { return [...prev, stateSites.filter((_site) => { return _site.uuid == uuidSite })[0]] });
    setOptionsSites(stateOptionsSites.filter((option) => { return option.value != uuidSite }));
  }

  function clearNewSite(site: ISite) {
    setNewSites((prev) => { return prev.filter((_site) => { return _site.uuid != site.uuid }) });
    setOptionsSites((prev) => {
      const _site = stateNewSites.filter((_site) => { return _site.uuid == site.uuid })[0];
      return [...prev, { label: _site.name, value: _site.uuid }];
    });
  }

  function toggleBeaconCheckin() {
    setEnableBeaconCheckin(!stateEnableBeaconCheckin);
  }

  function toggleGeofenceCheckin() {
    setEnableGeofenceCheckin(!stateEnableGeofenceCheckin);
  }

  function onChangeGeofenceRadius(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    if (!event.target.value) {
      setGeofenceRadius(0);
      return;
    }
    setGeofenceRadius(parseInt(event.target.value));
  }

  function toggleDutyDuration() {
    setEnableDutyDuration(!stateEnableDutyDuration);
  }

  function toggleStartRestOnCompletePatrol() {
    setStartRestOnCompletePatrol(!stateStartRestOnCompletePatrol);
  }

  function toggleLateStartAlert() {
    setEnableLateStartAlert(!stateEnableLateStartAlert);
  }

  function onChangeOnDutyDuration(newDuration: DurationState) {
    setError('');
    setOnDutyDurationPicker(newDuration);
  }

  function onChangeOffDutyDuration(newDuration: DurationState) {
    setError('');
    setOffDutyDurationPicker(newDuration);
  }

  function onChangeFailedPatrolPercentage(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setFailedPatrolPercentage(parseInt(event.target.value));
  }

  function onChangeLateStartDuration(newDuration: DurationState) {
    setError('');
    setLateStartDuration(newDuration);
  }

  /* Validation */
  function validateNewSites() {
    if (stateNewSites.length == 0) return ['Need to add atleast one site'];
    if (stateNewSites.length > 1) return ['Can only add 1 site at a time'];
    return [];
  }

  function validateGeoFenceRadius() {
    return validate(stateGeofenceRadius, [VALIDATORS.numberBetween('Geofence radius', 5, 100)]);
  }

  function validateFailedPatrolPercentage() {
    return validate(stateFailedPatrolPercentage, [VALIDATORS.numberBetween('Failed patrol percentage', 0, 100)]);
  }

  function validateForm() {
    return [
      ...validateNewSites(),
      ...validateGeoFenceRadius(),
      ...validateFailedPatrolPercentage()
    ];
  }

  if (stateSitesLoading) return <StatusLoading />
  if (stateError) return <StatusAlert message={stateError} type={"alert-error"} />

  return (
    <div className="bg-base-200 p-1 rounded-xl">
      <div className="mt-1 mr-1">
        <div className="text-sm italic">Site that should be part of the patrol</div>
        <div className="flex flex-wrap">
          {stateNewSites.map((site, index) => {
            return (
              <div key={index} className='flex items-center justify-center bg-secondary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
                {/* TODO: Make this better */}
                <div>{site.name}</div>
                <button onClick={() => { clearNewSite(site) }}>
                  <IconXCircleSolid className='ml-2' style={{ height: '18px' }} />
                </button>
              </div>
            )
          })}
        </div>
        <div className="flex">
          <InputSearchDropdown options={stateOptionsSites} labelText={"Link new Site"} errors={[]} onSelect={(val) => addNewSite(val.toString())} ></InputSearchDropdown>
        </div>
        {validateNewSites().length > 0 && <div className="text-error text-xs">* {validateNewSites().toString()}</div>}
      </div>
      <div className="mt-2 mr-1">
        <div className="text-sm italic">Enable this to let the user be able to be added to this patrol, whenever he/she log a beacon</div>
        <label className="p-0 label cursor-pointer max-w-fit">
          <input type="checkbox" checked={stateEnableBeaconCheckin} onChange={() => toggleBeaconCheckin()} className="checkbox checkbox-sm" />
          <div className="mx-0.5"></div>
          <span className="label-text font-semibold">Beacon Check In</span>
        </label>
      </div>
      <div className="mt-2 mr-1">
        <div className="text-sm italic">Enable this to show a popup and check in the device when it is in a certain radius (meter) from a beacon on the specific site</div>
        <label className="p-0 label cursor-pointer max-w-fit">
          <input type="checkbox" checked={stateEnableGeofenceCheckin} onChange={() => toggleGeofenceCheckin()} className="checkbox checkbox-sm" />
          <div className="mx-0.5"></div>
          <span className="label-text font-semibold">Geofence Check In</span>
        </label>
        <div className="flex flex-wrap">
          <InputText labelText='Geofence Radius ⓘ' labelTooltip="Meters" disabled={!stateEnableGeofenceCheckin} errors={validateGeoFenceRadius()} value={stateGeofenceRadius} onChange={onChangeGeofenceRadius} > </InputText>
        </div>
      </div>
      <div className="mt-2 mr-1">
        <div className="text-sm italic">Enable this to include a routine patrol. <span className="font-semibold">Failed Patrol: Set the scan rate for beacons; failing to meet it triggers an alert.</span></div>
        <label className="p-0 label cursor-pointer max-w-fit">
          <input type="checkbox" checked={stateEnableDutyDuration} onChange={() => toggleDutyDuration()} className="checkbox checkbox-sm" />
          <div className="mx-0.5"></div>
          <span className="label-text font-semibold">Duty Duration</span>
        </label>
        <label className="mt-2 p-0 label cursor-pointer max-w-fit">
          <input type="checkbox" disabled={!stateEnableDutyDuration} checked={stateStartRestOnCompletePatrol} onChange={() => toggleStartRestOnCompletePatrol()} className="checkbox checkbox-sm" />
          <div className="mx-0.5"></div>
          <span className="label-text font-semibold">Start off duty timer on completion of patrol</span>
        </label>
        <div className="flex flex-wrap">
          <InputDuration labelText='On Duty Duration ⓘ' labelTooltip="HOURS:MINUTES" disabled={!stateEnableDutyDuration} errors={[]} value={stateOnDutyDurationPicker} onChange={onChangeOnDutyDuration} > </InputDuration>
          <div className="mx-1"></div>
          <InputDuration labelText='Off Duty Duration ⓘ' labelTooltip="HOURS:MINUTES" disabled={!stateEnableDutyDuration} errors={[]} value={stateOffDutyDurationPicker} onChange={onChangeOffDutyDuration} > </InputDuration>
          <div className="mx-1"></div>
          <InputText className="w-28" labelText={"Failed Patrol"} disabled={!stateEnableDutyDuration} inputType="numberInt" errors={validateFailedPatrolPercentage()} value={stateFailedPatrolPercentage} onChange={onChangeFailedPatrolPercentage}></InputText>
          <div className="mx-1"></div>
        </div>
      </div>
      <div className="mt-1 mr-1">
        <div className="text-sm italic">Enable this to include a patrol late start alert</div>
        <label className="p-0 label cursor-pointer max-w-fit">
          <input type="checkbox" checked={stateEnableLateStartAlert} onChange={() => toggleLateStartAlert()} className="checkbox checkbox-sm" />
          <div className="mx-0.5"></div>
          <span className="label-text font-semibold">Late Start Alert</span>
        </label>
        <InputDuration labelText='Late Start Patrol Duration ⓘ' labelTooltip="HOURS:MINUTES" disabled={!stateEnableLateStartAlert} errors={[]} value={stateLateStartDuration} onChange={onChangeLateStartDuration} > </InputDuration>
      </div>
    </div>
  )
}