import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface ScreenHomeEventInstanceMapProps { }

export const ScreenHomeEventInstanceMap: React.FC<ScreenHomeEventInstanceMapProps> = (props) => {
  /* State */
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  return (
    <div className=' w-full '>
      map
    </div>
  );
};
