import React, { useContext, useEffect, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { EventInstanceLogId, IEventInstanceLog, IUser } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { useNavigate, useParams } from 'react-router-dom';
import { convertQueryFilterToMongoFilter } from './helper_home';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { useInterval } from '../../hooks/hook_interval';
import { ROUTES } from '../../routes';


interface ScreenHomeEventRawLogsProps { }

export const ScreenHomeEventRawLogs: React.FC<ScreenHomeEventRawLogsProps> = (props) => {
  const contextOrganization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidEvent = params['uuid'] || '';

  const navigate = useNavigate();

  const [ticker, setTicker] = useState(0);

  const [stateEventInstanceLogs, setEventInstanceLogs] = useState([] as IEventInstanceLog[]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState([] as QueryFilter[]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState({ id: 'uuid', direction: 'descending' } as Sorting);
  const [stateTablePagingSelected, setTablePagingSelected] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 } as Paging);

  const [stateUsers, setUsers] = useState<IUser[]>([]);

  /* Getters */
  async function fetchLogsBackground() {
    if (!contextOrganization) { return }
    const filter = convertQueryFilterToMongoFilter(stateTableFiltersSelected);
    filter.uuidEvent = uuidEvent;

    const response = await vigil.functions.eventInstanceLogs({
      filter,
      ordering: { field: stateTableSortingSelected.id, direction: stateTableSortingSelected.direction },
      pagination: { page: stateTablePagingSelected.currentPage, count: stateTablePagingSelected.itemsPerPage }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateEventInstanceLogs)) {
      setEventInstanceLogs(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }

  async function fetchUsers(uuids: string[]) {
    try {
      if (!contextOrganization) { return }
      const users = await vigil.functions.userFindMany({ filter: { uuid: { $in: uuids } } });
      setUsers(users);
    } catch (error: any) {
      setError(error.message);
    }
  }

  async function fetchLogs() {
    try {
      setLoading(true);
      await fetchLogsBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const uniqueUserUuids = Array.from(new Set(stateEventInstanceLogs.map(log => {
      if (log.id === EventInstanceLogId.Beacon) {
        return log.data.userUuid;
      }
      if (log.id === EventInstanceLogId.BeaconCheckIn) {
        return log.data.userUuid;
      }
      return '';
    })));
    fetchUsers(uniqueUserUuids);
  }, [stateEventInstanceLogs]);

  // Update immediately
  useEffect(() => {
    fetchLogs();
  }, [contextOrganization, ticker])

  // Update every 5 seconds
  useInterval(async () => {
    await fetchLogsBackground();
  }, 5000, false);

  function navigateDevice(uuid: string) {
    navigate(ROUTES.ROUTE_HOME_DEVICE.replace(':uuid', uuid))
  }

  function navigateSite(uuid: string) {
    navigate(ROUTES.ROUTE_HOME_SITE.replace(':uuid', uuid))
  }

  function navigateBeacon(uuid: string) {
    navigate(ROUTES.ROUTE_HOME_BEACON.replace(':uuid', uuid))
  }

  function navigateUser(uuid: string) {
    navigate(ROUTES.ROUTE_HOME_USER.replace(':uuid', uuid))
  }

  function navigateGoogleMaps(lat: number, lng: number) {
    window.open(`https://www.google.com/maps?q=${lat},${lng}`, '_blank');
  }

  function ClickableText(props: { text: string, onClick: () => void }) {
    return <div className='hover:underline cursor-pointer text-blue-500' onClick={props.onClick}>{props.text}</div>
  }

  return (
    <div className='py-2 h-full w-full'>
      <Table<IEventInstanceLog>
        className='h-full flex flex-col drop-shadow'
        name='Raw Logs'
        data={stateEventInstanceLogs}
        loading={stateLoading}
        error={stateError}
        columns={[
          {
            id: 'id',
            header: 'ID',
            sortable: true,
            value(item) {
              return <div>{item.id}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'creator',
            header: 'Creator',
            sortable: true,
            value(item) {
              if (item.creator.type === 'server') return <div>Server</div>;
              return (
                <ClickableText text={`Device: ${item.creator.uuidDevice}`} onClick={() => navigateDevice(item.uuid)} />
              )
            },
          },
          {
            id: 'data',
            header: 'Data',
            sortable: false,
            value(item) {
              if (item.id === EventInstanceLogId.BeaconCheckIn) {
                return (
                  <ClickableText text='User Location' onClick={() => navigateGoogleMaps(item.data.userLat, item.data.userLng)} />
                )
              }
              if (item.id === EventInstanceLogId.DutyOn || item.id === EventInstanceLogId.DutyOff) {
                return <div>Duration: {item.data.duration} seconds</div>;
              }
              if (item.id === EventInstanceLogId.Beacon) {
                const user = stateUsers.find(user => user.uuid === item.data.userUuid);
                return (
                  <div className='flex space-x-1'>
                    <ClickableText text={`Beacon: ${item.data.beaconName}`} onClick={() => navigateBeacon(item.data.beaconUuid)} />
                    <ClickableText text={`User: ${user?.firstName} ${user?.lastName}`} onClick={() => navigateUser(item.data.userUuid)} />
                  </div>
                )
              }
              if (item.id === EventInstanceLogId.NotificationPanic) {
                return (
                  <div className='flex space-x-1'>
                    <ClickableText text='User Location' onClick={() => navigateGoogleMaps(item.data.lat, item.data.lng)} />
                    <ClickableText text={`User: ${item.data.userFirstName} ${item.data.userLastName}`} onClick={() => navigateUser(item.data.userUuid)} />
                  </div>
                )
              }
              if (item.id === EventInstanceLogId.NotificationLateStart) {
                return (
                  <ClickableText text={`Site: ${item.data.siteName}`} onClick={() => navigateSite(item.data.siteUuid)} />
                )
              }
              if (item.id === EventInstanceLogId.NotificationUnsuccessfulPatrol) {
                return (
                  <div>Completed: {item.data.percentageCompleted}%; Required: {item.data.percentageRequired}%</div>
                )
              }
              return <div>{JSON.stringify(item.data)}</div>;
            },
          }
        ]}
        filtersOptions={[
          new TextFilter('id', 'ID', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
          new TextFilter('creator.uuidDevice', 'Creator Device UUID', 'contains'),
        ]}
        filtersSelected={stateTableFiltersSelected}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1); }}

        sortingOptions={null}
        sortingSelected={stateTableSortingSelected}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1); }}

        pagingOptions={null}
        pagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1); }}
      />
    </div >
  );
};
