import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export enum ReportConfigId {
  Patrol = 'patrol',
}

export type BaseReportConfig = {
  type: ReportConfigId;
}

export type ReportConfigPatrol = BaseReportConfig & {
  type: ReportConfigId.Patrol,
  fromDurationHours: number,
  eventUuids: string[],
  deviceUuids: string[],
  siteUuids: string[],
}

export type ReportConfig = ReportConfigPatrol;

export interface IReport extends IBaseSyncable {
  uuidOrganization: string;
  name: string;
  config: ReportConfig;
}

export type IReportURI = { uuidReport: string, issuedTimestamp?: number };

export const schemaReport: TTSchema<IReport> = {
  ...schemaBaseSyncable,
  uuidOrganization: {
    type: String,
    indexed: true,
  },
  name: {
    type: String,
    indexed: true,
  },
  config: {
    type: Object,
    indexed: true,
  },
}

export const schemaInstanceReport = new TTSchemaSyncableInstance<IReport>('reports', schemaReport, 0);
