import { ReactNode } from "react";

export interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
}

interface InputModalProps extends ModalProps {
  children?: ReactNode;
  className?: string;
  closeOnBackgroundClick?: boolean;
}

export function Modal(props: InputModalProps) {
  return (
    <>
      {props.isOpen && (
        <div
          className="fixed top-0 left-0 w-screen h-screen bg-neutral-focus bg-opacity-50 flex justify-center items-center z-50"
          onClick={props.closeOnBackgroundClick !== false ? props.toggle : undefined}>
          <div onClick={(e) => e.stopPropagation()} className={props.className ? 'card bg-base-100 flex p-4 outline outline-1 outline-neutral-content max-h-full' + ' ' + props.className : "card bg-base-100 flex w-96 p-4 outline outline-1 outline-neutral-content max-h-full"}>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
}