import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

// EventTrigger
export enum EventTriggerId {
  Scheduled = 'scheduled',
  Geofence = 'geofence',
}

export type BaseEventTrigger = {
  type: EventTriggerId;
  enabled: boolean;
}

export type EventTriggerSchedule = BaseEventTrigger & {
  type: EventTriggerId.Scheduled;
  creationCron: string;
  creationLifetimeMS: number;
  eventCron: string;
  eventLifetimeMS: number;
}

export type EventTriggerGeoFence = BaseEventTrigger & {
  type: EventTriggerId.Geofence;
  creationLattitude: string;
  creationLongitude: number;
  creationRadius: number;
  eventCron: string;
  eventLifetimeMS: number;
}

export type EventTrigger = EventTriggerSchedule | EventTriggerGeoFence;

// EventConfig
export enum EventConfigId {
  Patrol = 'patrol',
  Script = 'script',
}

export type BaseEventConfig = {
  type: EventConfigId;
  enabled: boolean;
}

export interface IPatrolDuty {
  on: number,
  off: number,
  failedPatrolPercentage?: number,
  startRestOnCompletePatrol?: boolean,
}

export interface IPatrolSite {
  uuid: string,
  // I can see how we might have some config options per site later
}

export interface IPatrolMember {
  uuid: string,
  type: 'device' | 'user' | 'role',
}

export type EventConfigPatrol = BaseEventConfig & {
  type: EventConfigId.Patrol;
  sites: IPatrolSite[],
  members: IPatrolMember[],
  beaconCheckin: boolean,
  geofenceCheckinRadius?: number,
  duty?: IPatrolDuty,
  lateStartDuration?: number,
}

export type EventConfigScript = BaseEventConfig & {
  type: EventConfigId.Script;
  script: string,
}

export type EventConfig = EventConfigPatrol | EventConfigScript;

export interface IEvent extends IBaseSyncable {
  name: string;
  triggers: EventTrigger[];
  configs: EventConfig[];
  enabled: boolean;
}

export const schemaEvent: TTSchema<IEvent> = {
  ...schemaBaseSyncable,
  name: {
    type: String,
    indexed: true,
  },
  triggers: {
    type: Array<EventTrigger>,
    indexed: true,
  },
  configs: {
    type: Array<EventConfig>,
    indexed: true,
  },
  enabled: {
    type: Boolean,
    indexed: true,
  }
}

export const schemaInstanceEvent = new TTSchemaSyncableInstance<IEvent>('events', schemaEvent, 0);
