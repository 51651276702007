
export enum TTComsLoggerChannel {
  OpsMessage = 'OpsMessage'
}

const activeLogs = {
  [TTComsLoggerChannel.OpsMessage]: true,
}

export class TTComsLogger {
  public static log(channel: TTComsLoggerChannel, message: string) {
    if (activeLogs[channel]) {
      console.log(message);
    }
  }
}