export function BuildTTComFunctions<TFunctions, TContextClient>(params: { functions: TFunctions, endpoint: string, contextFn: () => Promise<TContextClient> }): TFunctions {
  const functionsWrapped: any = {};
  for (const key of Object.keys(params.functions as any)) {
    functionsWrapped[key] = async (input: any) => {
      const context = await params.contextFn();
      const result = await fetch(params.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: key,
          input: input,
          contextClient: context
        })
      });

      // Check status code
      if (result.status !== 200) {
        throw new Error("Communication error.")
      }

      // Check format
      let resultParsed: any = null;
      try {
        resultParsed = await result.json();
      }
      catch (error) {
        throw new Error("Parsing error.")
      }
      if (!resultParsed) {
        throw new Error("Parsing error.")
      }

      // Check for errors
      if (resultParsed.error) {
        throw new Error(resultParsed.error.message);
      }

      // Return result
      return resultParsed.result;
    }
  }
  return functionsWrapped;
}
