import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IDashSession extends IBaseSyncable {
  uuidSession: string;
  browserType: string;
  operatingSystem: string;
}

export const schemaDashSession: TTSchema<IDashSession> = {
  ...schemaBaseSyncable,
  uuidSession: {
    type: String,
    indexed: true,
  },
  browserType: {
    type: String,
    indexed: true,
  },
  operatingSystem: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceDashSession = new TTSchemaSyncableInstance<IDashSession>('dash_sessions', schemaDashSession, 0);
