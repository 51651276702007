import { useContext, useEffect, useState } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { IBeacon, ISite } from "vigil-datamodel";
import { StatusAlert } from "../../../components/status_alert";
import { ContextOrganization } from "../../../providers/provider_organization";
import { IconXCircleSolid } from "../../../components/icons";
import { InputSearchDropdown, OptionSearchDropdown } from "../../../components/input_search_dropdown";
import { StatusLoading } from "../../../components/status_loading";

interface ModalSiteBeaconsCreateProps extends ModalProps {
  uuidSite: string;
  onSubmit?: () => Promise<void>;
}

export const ModalSiteBeaconsCreate: React.FC<ModalSiteBeaconsCreateProps> = (props) => {
  const contextOrganization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateSiteLoading, setSiteLoading] = useState(true);
  const [stateSite, setSite] = useState<ISite>();

  const [stateBeaconsLoading, setBeaconsLoading] = useState(true);
  const [stateLinkedBeacons, setLinkedBeacons] = useState<IBeacon[]>([]);
  const [stateUnlinkedBeacons, setUnlinkedBeacons] = useState<IBeacon[]>([]);
  const [stateNewBeacons, setNewBeacons] = useState<IBeacon[]>([]);
  const [stateBeaconOptions, setBeaconOptions] = useState<OptionSearchDropdown[]>([]);

  useEffect(() => { fetchSite(props.uuidSite); }, [props.uuidSite]);
  useEffect(() => { resetCalls() }, [props.isOpen]);

  /* Functions */
  function resetCalls() {
    setNewBeacons([]);
    fetchLinkedBeacons();
    fetchUnlinkedBeacons();
  }

  function clearNewBeacon(beacon: IBeacon) {
    const newBeacons = stateNewBeacons.filter((_beacon) => { return _beacon.uuid != beacon.uuid });
    setNewBeacons(newBeacons);

    const unlinkedBeacons = stateUnlinkedBeacons;
    unlinkedBeacons.push(beacon);
    setUnlinkedBeacons(unlinkedBeacons);

    const beaconOptions = stateBeaconOptions;
    beaconOptions.push({ label: beacon.name, value: beacon.uuid });
    setBeaconOptions(beaconOptions);
  }

  function addNewBeacon(uuidBeacon: string) {
    const beacon: IBeacon = stateUnlinkedBeacons.filter((_beacon) => { return _beacon.uuid == uuidBeacon })[0];

    const newBeacons = stateNewBeacons;
    newBeacons.push(beacon);
    setNewBeacons(newBeacons);

    const unlinkedBeacons = stateUnlinkedBeacons.filter((_beacon) => { return _beacon.uuid != uuidBeacon });
    setUnlinkedBeacons(unlinkedBeacons);

    const beaconOptions = stateBeaconOptions.filter((option) => { return option.value != uuidBeacon });
    setBeaconOptions(beaconOptions);
  }

  /* DB Functions */
  async function fetchSite(uuid: string) {
    try {
      setSiteLoading(true);
      const site = await vigil.functions.siteFindOne({ filter: { uuid } });
      setSite(site);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSiteLoading(false);
    }
  }

  async function fetchLinkedBeacons() {
    try {
      setBeaconsLoading(true);
      const beacons = await vigil.functions.beaconLinkedTo({ uuidSite: props.uuidSite });
      setLinkedBeacons(beacons);
      setBeaconsLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setBeaconsLoading(false);
    }
  }

  async function fetchUnlinkedBeacons() {
    if (!contextOrganization) return;
    try {
      setBeaconsLoading(true);
      const unlinkedBeacons = await vigil.functions.beaconUnlinkedTo({ uuidOrganization: contextOrganization.uuid, uuidSite: props.uuidSite });
      setUnlinkedBeacons(unlinkedBeacons);
      const beaconOptions: OptionSearchDropdown[] = unlinkedBeacons.map((beacon) => {
        return { label: beacon.name, value: beacon.uuid }
      });
      setBeaconOptions(beaconOptions);
      setBeaconsLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setBeaconsLoading(false);
    }
  }

  async function linkBeaconsToSite() {
    if (!stateSite) return;
    try {
      setBeaconsLoading(true);
      const uuidBeacons = stateNewBeacons.map((beacon) => { return beacon.uuid });
      await vigil.functions.siteBeaconsLinkCreate({uuidSite: stateSite.uuid, uuidBeacons});
      props.onSubmit && await props.onSubmit();
      props.toggle();
      resetCalls();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setBeaconsLoading(false);
    }
  }

  if (stateSiteLoading || stateBeaconsLoading || !stateSite) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <StatusLoading />
      </Modal>
    )
  }

  if (stateUnlinkedBeacons.length == 0 && stateNewBeacons.length == 0) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
        <h3 className="font-bold text-lg pb-2">Need to add beacons first</h3>
        <div>Please add beacons to link them to this site</div>
      </Modal>
    )
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-192">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Link beacons to {stateSite.name}</h3>
      <div className="flex flex-wrap">
        {stateLinkedBeacons.map((beacon, index) => {
          return (
            <div key={index} className='flex items-center justify-center bg-primary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              {beacon.name}
            </div>
          )
        })}
        {stateNewBeacons.map((beacon, index) => {
          return (
            <div key={index} className='flex items-center justify-center bg-secondary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              <div>{beacon.name}</div>
              <button onClick={() => { clearNewBeacon(beacon) }}>
                <IconXCircleSolid className='ml-2' style={{ height: '18px' }} />
              </button>
            </div>
          )
        })}
      </div>
      <div className="flex">
        <InputSearchDropdown options={stateBeaconOptions} labelText={"Link new Beacon"} errors={[]} onSelect={(val) => addNewBeacon(val.toString())} ></InputSearchDropdown>
      </div>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Link Beacons' loading={false} disabled={stateNewBeacons.length == 0} type='btn-primary' onClick={async () => {
          await linkBeaconsToSite();
        }}></InputButton>
      </div>
    </Modal>
  )
}