import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Table } from '../../components/table/table';
import { IEventInstanceLog } from 'vigil-datamodel';
import { TTuuid } from 'tt-uuid';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { useInterval } from '../../hooks/hook_interval';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { Paging, Sorting } from '../../components/table/table_types';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';

interface ScreenHomeEventInstanceLogsProps { }

export const ScreenHomeEventInstanceLogs: React.FC<ScreenHomeEventInstanceLogsProps> = (props) => {
  /* State */
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const eventInstanceId = params['uuid'] || '';

  const [stateEventInstanceLogs, setEventInstanceLogs] = useState([] as IEventInstanceLog[]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState([] as QueryFilter[]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState({ id: 'uuid', direction: 'descending' } as Sorting);
  const [stateTablePagingSelected, setTablePagingSelected] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 } as Paging);

  const [ticker, setTicker] = useState(0);

  async function fetchEventInstanceLogsBackground() {
    if (!organization) { return }
    const response = await vigil.functions.eventInstanceLogs({
      filter: {
        uuidEventInstance: eventInstanceId,
      },
      pagination: {
        page: stateTablePagingSelected.currentPage,
        count: stateTablePagingSelected.itemsPerPage,
      },
      ordering: {
        field: stateTableSortingSelected.id,
        direction: stateTableSortingSelected.direction
      }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateEventInstanceLogs)) {
      setEventInstanceLogs(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.pages });
    }
  }

  async function fetchEventInstances() {
    try {
      setLoading(true);
      await fetchEventInstanceLogsBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update immediately
  useEffect(() => {
    fetchEventInstances();
  }, [organization, ticker]);

  // Update every 5 seconds
  const interval = useInterval(async () => {
    await fetchEventInstanceLogsBackground();
  }, 5000, false);

  return (
    <div className='h-full w-full '>
      <Table<IEventInstanceLog>
        className='h-full flex flex-col drop-shadow'
        name='Logs'
        data={stateEventInstanceLogs}
        selectable={true}
        loading={stateLoading}
        error={stateError}
        columns={[
          {
            id: 'uuid',
            header: 'Unique ID',
            sortable: true,
            value(item) {
              return <div>{item.uuid}</div>;
            },
          },
          {
            id: 'date_created',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'last_updated',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'type_id',
            header: 'Type',
            sortable: true,
            value(item) {
              return <div>{item.id}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('uuid', 'Unique ID', 'contains'),
          new DateFilter('date_created', 'Date Created', 'between'),
          new DateFilter('last_updated', 'Last Updated', 'between'),
        ]}
        filtersSelected={stateTableFiltersSelected}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1) }}

        sortingOptions={null}
        sortingSelected={stateTableSortingSelected}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1) }}

        pagingOptions={null}
        pagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1) }}

        unSelectedActions={[
        ]}
        selectedActions={[
        ]}
        hoverActions={[
        ]}
      />
    </div>
  );
};
