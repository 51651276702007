import { TTSchema, TTSchemaInstance } from "./schema.js";

export interface IKeyStore {
  key: string;
  value: string;
}

export const schemaKeyStore: TTSchema<IKeyStore> = {
  key: {
    type: String,
    indexed: true,
  },
  value: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceKeyStore = new TTSchemaInstance<IKeyStore>('_keystore', schemaKeyStore, 0);
