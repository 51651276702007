import { useEffect, useRef, useState } from 'react';
import { SelectedAction, UnselectedAction, ColumnInfo, Sorting, Paging } from './table_types';
import { StatusAlert } from '../status_alert';
import { TableHeader } from './table_header';
import { TableBody } from './table_body';
import { TableFooter } from './table_footer';
import { BaseFilter, QueryFilter } from './table_filters/table_filter_types';
import { TableFilterBar } from './table_filter_bar';
import { TableActionBar } from './table_action_bar';

export interface TableProps<T> {
  className?: string;

  name: string;
  data: T[],
  columns: ColumnInfo<T>[]

  loading?: boolean;
  error?: string;

  filtersOptions: BaseFilter[];
  filtersSelected: QueryFilter[];
  onFiltersChange: (filters: QueryFilter[]) => void;

  sortingOptions: null; // TODO: Could add future configs here
  sortingSelected: Sorting;
  onSortingChange: (sorting: Sorting) => void;

  pagingOptions: null;
  pagingSelected: Paging;
  onPagingChange: (paging: Paging) => void;

  selectable?: boolean;
  singleSelectActions?: SelectedAction<T>[];
  selectedActions?: SelectedAction<T>[];
  unSelectedActions?: UnselectedAction[];
  hoverActions?: SelectedAction<T>[];
}

export const Table = <T,>(props: TableProps<T>) => {
  /* Select mechanism */
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});

  function getSelectedItems(): T[] {
    return props.data.filter((_, index) => getRowSelected(index));
  }

  const setRowsSelected = (value: boolean) => {
    props.data.forEach((item, index) => {
      setRowSelected(index, value);
    });
  }

  const getRowsSelected = () => {
    if (props.data.length == 0) {
      return false;
    }

    let result = true;
    props.data.forEach((item, index) => {
      if (!getRowSelected(index)) {
        result = false;
      }
    })

    return result;
  }

  const setRowSelected = (index: number, value: boolean) => {
    setSelectedRows(selectedRows => ({
      ...selectedRows,
      [index]: value
    }))
  };

  const getRowSelected = (index: number) => {
    return selectedRows[index] || false;
  };


  const colorClassMap = {
    primary: 'text-primary hover:bg-primary',
    secondary: 'text-secondary hover:bg-secondary',
    warning: 'text-warning hover:bg-warning',
    error: 'text-error hover:bg-error',
  };

  /* Render */
  return (
    <div className={props.className ?? 'h-full flex flex-col'}>
      {props.error && <StatusAlert message={props.error} type='alert-error' />}
      {!props.error &&
        <div className='border border-base-200 rounded-2xl h-full flex flex-col'>
          <TableActionBar props={{ ...props, setRowsSelected, getSelectedItems, colorClassMap }} />
          { props.filtersOptions.length > 0 &&
            <TableFilterBar 
              onFiltersChange={props.onFiltersChange}
              filtersSelected={props.filtersSelected}
              filtersOptions={props.filtersOptions} 
            />
          }

          {props.loading && <progress className="progress progress-primary w-full h-1"></progress>}

          <div className="relative w-full h-full">
            <div className='absolute w-full h-full overflow-y-auto'>
              {/* Display Table */}
              {
                <table className="table table-sm w-full">
                  <TableHeader 
                    columns={props.columns}
                    rowsSelectable={props.selectable || false}
                    sortingSelected={props.sortingSelected}
                    rowsSelected={getRowsSelected()}
                    onRowsSelectedChange={setRowsSelected}
                    onSortingChange={props.onSortingChange}
                  />
                  <TableBody
                    getRowSelected={getRowSelected}
                    setRowSelected={setRowSelected}
                    selectable={props.selectable || false}
                    columns={props.columns}
                    data={props.data}
                    hoverActions={props.hoverActions}
                    colorClassMap={colorClassMap}
                  />
                </table>
              }

              {/* Display Empty  */}
              {
                props.data.length == 0 && <div className='w-full h-56 flex flex-col justify-center items-center'>
                  {/* // TODO: Make this configurable */}
                  <div>
                    No items were found
                  </div>
                </div>
              }

            </div>
            {props.loading && <div className="absolute inset-0 bg-base-100 bg-opacity-80" />}
          </div>

          <div className='flex-grow' />

          <TableFooter
            pagingOptions={null}
            pagingSelected={props.pagingSelected}
            onPagingChange={props.onPagingChange}
          />
        </div>
      }
    </div>
  );
};
