import { TTComsQuery, TTPagination } from "tt-coms";

interface TTComsQueryContainer {
  query: TTComsQuery<any>,
  confirmed: boolean,
}

export class TTComsDBQueryManager {
  private queryMemoryMap: Map<string, TTComsQueryContainer> = new Map();
  constructor() {

  }

  public addQuery(queryInput: TTComsQuery<any>) {
    const queryId = this.getQueryId(queryInput);
    if (this.queryMemoryMap.get(queryId) == undefined) {
      console.log('NEW OUTBOUND QUERY:')
      console.log(queryInput);
      this.queryMemoryMap.set(queryId, {
        confirmed: false,
        query: queryInput,
      });
    }
  }

  public removeQuery(queryInput: TTComsQuery<any>) {
    // TODO: Remove queries
  }

  public unconfirmed() {
    for (const item of this.queryMemoryMap.values()) {
      item.confirmed = false;
    }
  }

  public getBatch(size: number): TTComsQuery<any>[] {
    const result = [];
    for (const item of this.queryMemoryMap.values()) {
      if (item.confirmed == false) {
        result.push(item.query);
      }
    }
    return result;
  }

  public confirm(query: TTComsQuery<any>) {
    const queryId = this.getQueryId(query);
    const container = this.queryMemoryMap.get(queryId);
    if (container) {
      container!.confirmed = true;
    }
  }

  public updateQuery(query: TTComsQuery<any>) {
    // TODO: Update query server stamps
  }

  private getQueryId(query: TTComsQuery<any>) {
    return query.collection + '|' + JSON.stringify(query.query);
  }
}
