import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface ISite extends IBaseSyncable {
  name: string;
}

export const schemaSite: TTSchema<ISite> = {
  ...schemaBaseSyncable,
  name: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceSite = new TTSchemaSyncableInstance<ISite>('sites', schemaSite, 0);
