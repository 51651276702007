import React from 'react';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../routes';
import { Tabs } from '../../components/tabs';
import { IconBars4, IconBolt, IconMap, IconPlusOutline } from '../../components/icons';


interface ScreenHomeEventInstanceProps { }

export const ScreenHomeEventInstance: React.FC<ScreenHomeEventInstanceProps> = (props) => {
  /* State */
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();
  const eventInstanceId = params['uuid'] || '';

  function navigateTab(id: string) {
    navigate({
      pathname: ROUTES.ROUTE_HOME_EVENT_INSTANCE.replace(':uuid', eventInstanceId) + '/' + id,
      search: URLSearchParams.toString()
    })
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  function getBreadcrumbsId() {
    if (!URLSearchParams.get('source_event')) {
      return 'home_overview_events';
    }
    return 'home_event_instances';
  }

  return (
    <div className='w-full h-full flex flex-col px-8'>
      <div>
        {
          getBreadcrumbsId() == 'home_overview_events' &&
          <Breadcrumbs crumbs={[{ id: 'overview_events', onClick: () => navigate(ROUTES.ROUTE_HOME_OVERVIEW_EVENTS), text: 'Active Events' }, { id: eventInstanceId, onClick: () => { }, text: eventInstanceId }]} />
        }
        {
          getBreadcrumbsId() == 'home_event_instances' &&
          <Breadcrumbs crumbs={[
            { id: 'events', onClick: () => navigate(ROUTES.ROUTE_HOME_EVENTS), text: 'Events' },
            { id: URLSearchParams.get('source_event') || '', onClick: (crumb) => { navigate(ROUTES.ROUTE_HOME_EVENT.replace(':uuid', crumb.id)) }, text: URLSearchParams.get('source_event') || 'Event' },
            { id: URLSearchParams.get('source_event') || '', onClick: (crumb) => { navigate(ROUTES.ROUTE_HOME_EVENT_INSTANCES.replace(':uuid', crumb.id)) }, text: 'Instances' },
            { id: 'event_instance', onClick: () => { }, text: eventInstanceId },
          ]} />
        }

      </div>
      <div className="border-b border-base-300"></div>

      <div className='p-2 w-full h-full flex flex-col'>
        <Tabs selectedId={getSelectedId()} onSelect={navigateTab} items={[
          {
            icon: IconBolt,
            id: 'overview',
            text: 'Overview'
          },
          {
            icon: IconBars4,
            id: 'logs',
            text: 'Logs'
          },
        ]} />
        <div className='mt-2 w-full h-full'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
