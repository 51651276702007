import { ROUTES } from "./routes";
import { ScreenHome } from "./screens/dashboard/home";
import { ScreenHomeOverview } from "./screens/dashboard/home_overview";
import { ScreenHomeAssistent } from "./screens/dashboard/home_assistent";
import { ScreenHomeSettings } from "./screens/dashboard/home_settings";
import { ScreenHomeBilling } from "./screens/dashboard/home_billing";
import { ScreenHomeInventory } from "./screens/dashboard/home_inventory";
import { FullPageError } from "./components/full_page_error";
import { FullPageSuspend } from "./components/full_page_suspend";
import { RouteObject } from "react-router-dom";
import { ScreenHomeSites } from "./screens/dashboard/home_sites";
import { ScreenHomeSite } from "./screens/dashboard/home_site";
import { ScreenHomeBeacons } from "./screens/dashboard/home_beacons";
import { ScreenHomeBeacon } from "./screens/dashboard/home_beacon";
import { ScreenHomeUsers } from "./screens/dashboard/home_users";
import { ScreenHomeUser } from "./screens/dashboard/home_user";
import { ScreenHomeDevices } from "./screens/dashboard/home_devices";
import { ScreenHomeDevice } from "./screens/dashboard/home_device";
import { ScreenHomeRoles } from "./screens/dashboard/home_roles";
import { ScreenHomeRole } from "./screens/dashboard/home_role";
import { ScreenHomeOverviewMap } from "./screens/dashboard/home_overview_map";
import { ScreenHomeOverviewEvents } from "./screens/dashboard/home_overview_events";
import { ScreenHomeNotifications } from "./screens/dashboard/home_notifications";
import { ScreenHomeNotification } from "./screens/dashboard/home_notification";
import { ScreenHomeEventInstance } from "./screens/dashboard/home_event_instance";
import { ScreenHomeEventInstanceOverview } from "./screens/dashboard/home_event_instance_overview";
import { ScreenHomeEventInstanceMap } from "./screens/dashboard/home_event_instance_map";
import { ScreenHomeEventInstanceLogs } from "./screens/dashboard/home_event_instance_logs";
import { ScreenHomeEvents } from "./screens/dashboard/home_events";
import { ScreenHomeEvent } from "./screens/dashboard/home_event";
import { ScreenHomeEventOverview } from "./screens/dashboard/home_event_overview";
import { ScreenHomeEventInstances } from "./screens/dashboard/home_event_instances";
import { ScreenHomeDeviceLogsActivity } from "./screens/dashboard/home_device_logs_activity";
import { ScreenHomeDeviceLogsState } from "./screens/dashboard/home_device_logs_state";
import { ScreenHomeDeviceHistoryStatistics } from "./screens/dashboard/home_device_history_statistics";
import { ScreenHomeDeviceHistoryLocation } from "./screens/dashboard/home_device_history_location";
import { ScreenHomeOnboarding } from "./screens/dashboard/home_onboarding";
import { ScreenHomeOnboardingNewOrganization } from "./screens/dashboard/home_onboarding_new_organization";
import { ScreenHomeReports } from "./screens/dashboard/home_reports";
import { ScreenHomeReport } from "./screens/dashboard/home_report";
import { ScreenHomeReportOVerview } from "./screens/dashboard/home_report_overview";
import { ScreenHomeActions } from "./screens/dashboard/home_actions";
import { ScreenHomeAction } from "./screens/dashboard/home_action";
import { ScreenHomeActionOVerview } from "./screens/dashboard/home_action_overview";
import { ScreenHomeEventRawLogs } from "./screens/dashboard/home_event_raw_logs";
import { ScreenHomeDeviceBehaviours } from "./screens/dashboard/home_device_behaviours";

export const RouterHome: RouteObject[] = [{
  path: ROUTES.ROUTE_HOME,
  errorElement: <FullPageError />,
  element: <FullPageSuspend><ScreenHome /></FullPageSuspend>,
  children: [
    {
      path: ROUTES.ROUTE_HOME_ONBOARDING,
      element: <ScreenHomeOnboarding />
    },
    {
      path: ROUTES.ROUTE_HOME_ONBOARDING_NEW_ORGANIZATION,
      element: <ScreenHomeOnboardingNewOrganization />
    },
    {
      path: ROUTES.ROUTE_HOME_OVERVIEW,
      element: <ScreenHomeOverview />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_OVERVIEW_MAP,
          element: <ScreenHomeOverviewMap />
        },
        {
          path: ROUTES.ROUTE_HOME_OVERVIEW_EVENTS,
          element: <ScreenHomeOverviewEvents />
        },
      ]
    },
    {
      path: ROUTES.ROUTE_HOME_ASSISTENT,
      element: <ScreenHomeAssistent />
    },
    {
      path: ROUTES.ROUTE_HOME_EVENTS,
      element: <ScreenHomeEvents />
    },
    {
      path: ROUTES.ROUTE_HOME_INVENTORY,
      element: <ScreenHomeInventory />
    },
    {
      path: ROUTES.ROUTE_HOME_BILLING,
      element: <ScreenHomeBilling />
    },
    {
      path: ROUTES.ROUTE_HOME_SETTINGS,
      element: <ScreenHomeSettings />
    },
    {
      path: ROUTES.ROUTE_HOME_ROLES,
      element: <ScreenHomeRoles />
    },
    {
      path: ROUTES.ROUTE_HOME_USERS,
      element: <ScreenHomeUsers />
    },
    {
      path: ROUTES.ROUTE_HOME_SITES,
      element: <ScreenHomeSites />
    },
    {
      path: ROUTES.ROUTE_HOME_BEACONS,
      element: <ScreenHomeBeacons />
    },
    {
      path: ROUTES.ROUTE_HOME_DEVICES,
      element: <ScreenHomeDevices />
    },
    {
      path: ROUTES.ROUTE_HOME_NOTIFICATIONS,
      element: <ScreenHomeNotifications />
    },
    {
      path: ROUTES.ROUTE_HOME_DEVICE,
      element: <ScreenHomeDevice />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_DEVICE_LOGS_ACTIVITY,
          element: <ScreenHomeDeviceLogsActivity />
        },
        {
          path: ROUTES.ROUTE_HOME_DEVICE_BEHAVIOURS,
          element: <ScreenHomeDeviceBehaviours />
        },
        {
          path: ROUTES.ROUTE_HOME_DEVICE_LOGS_STATE,
          element: <ScreenHomeDeviceLogsState />
        },
        {
          path: ROUTES.ROUTE_HOME_DEVICE_HISTORY_LOCATION,
          element: <ScreenHomeDeviceHistoryLocation />
        },
        {
          path: ROUTES.ROUTE_HOME_DEVICE_HISTORY_STATISTICS,
          element: <ScreenHomeDeviceHistoryStatistics />
        },
      ]
    },
    {
      path: ROUTES.ROUTE_HOME_SITE,
      element: <ScreenHomeSite />,
    },
    {
      path: ROUTES.ROUTE_HOME_EVENT,
      element: <ScreenHomeEvent />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_EVENT_OVERVIEW,
          element: <ScreenHomeEventOverview />
        },
        {
          path: ROUTES.ROUTE_HOME_EVENT_INSTANCES,
          element: <ScreenHomeEventInstances />
        },
        {
          path: ROUTES.ROUTE_HOME_EVENT_RAW_LOGS,
          element: <ScreenHomeEventRawLogs />
        }
      ]
    },
    {
      path: ROUTES.ROUTE_HOME_BEACON,
      element: <ScreenHomeBeacon />,
    },
    {
      path: ROUTES.ROUTE_HOME_ROLE,
      element: <ScreenHomeRole />,
    },
    {
      path: ROUTES.ROUTE_HOME_USER,
      element: <ScreenHomeUser />,
    },
    {
      path: ROUTES.ROUTE_HOME_NOTIFICATION,
      element: <ScreenHomeNotification />
    },
    {
      path: ROUTES.ROUTE_HOME_EVENT_INSTANCE,
      element: <ScreenHomeEventInstance />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_EVENT_INSTANCE_OVERVIEW,
          element: <ScreenHomeEventInstanceOverview />
        },
        {
          path: ROUTES.ROUTE_HOME_EVENT_INSTANCE_MAP,
          element: <ScreenHomeEventInstanceMap />
        },
        {
          path: ROUTES.ROUTE_HOME_EVENT_INSTANCE_LOGS,
          element: <ScreenHomeEventInstanceLogs />
        },
      ]
    },
    {
      path: ROUTES.ROUTE_HOME_ACTIONS,
      element: <ScreenHomeActions />
    },
    {
      path: ROUTES.ROUTE_HOME_ACTION,
      element: <ScreenHomeAction />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_ACTION_OVERVIEW,
          element: <ScreenHomeActionOVerview />
        },
      ]
    },
    {
      path: ROUTES.ROUTE_HOME_REPORTS,
      element: <ScreenHomeReports />
    },
    {
      path: ROUTES.ROUTE_HOME_REPORT,
      element: <ScreenHomeReport />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_REPORT_OVERVIEW,
          element: <ScreenHomeReportOVerview />
        },
      ]
    },
  ]
}]
