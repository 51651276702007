import React, { useState, useEffect } from 'react';

interface VigilQRDisplayProps { 
  qrcode: () => Promise<string>,
}

export const VigilQRDisplay: React.FC<VigilQRDisplayProps> = (props) => {

  const [stateQRInviteImage, setStateQRInviteImage] = useState("");

  useEffect(() => {
    async function makeQRImage() {
      const qrcodeString = await props.qrcode();
      setStateQRInviteImage(qrcodeString);
    }

    makeQRImage()
  }, [])

  return (
    <img src={stateQRInviteImage} />
  );
};
