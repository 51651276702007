import { FC, useContext, useEffect, useRef, useState } from 'react';
import { ChartLineDateTime } from '../../components/charts/chart_line_datetime';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { useParams } from 'react-router-dom';
import { TimeFrame } from 'vigil-datamodel';
import { InputSelect } from '../../components/input_select';
import { VigilMap, VigilMapController } from '../../components/vigil_map';
import { Coordinate, VigilMapMessageId, VigilMapV2Props, VigilMapV2ThemeId, getBoundingBox } from 'vigil-map';
import { useStateLive } from '../../hooks/hook_live_state';
import { ContextTheme } from '../../providers/provider_theme';

interface ScreenHomeDeviceBehavioursProps { }


export const ScreenHomeDeviceBehaviours: FC<ScreenHomeDeviceBehavioursProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidDevice = params['uuid'] || '';
  const theme = useContext(ContextTheme);

  return (
    <div className='h-full w-full flex flex-col'>
      <div className='relative  h-full'>
        <div className='absolute w-full h-full overflow-y-scroll'>
          // List goes here
        </div>
      </div>
    </div>
  );
};
