import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { IContext } from "vigil-client";
import { FullPageLoader } from "../components/full_page_loader";
import { ContextVigilClient } from "./provider_vigil_client";
import { FullPageError } from "../components/full_page_error";
import { TTSubscription } from "tt-coms-client";

export const ContextIdentity = createContext(null as IContext | null);

interface ProviderIdentityProps {
  children: ReactNode
}
export const ProviderIdentity: React.FC<ProviderIdentityProps> = (props: ProviderIdentityProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);

  /* State */
  const [stateIdentity, setIdentity] = useState(null as IContext | null);
  const [stateIdentityLoading, setIdentityLoading] = useState(true);
  const [stateIdentityError, setThemeError] = useState('');

  /* Reactivity */
  const subscriptionIdentity = useRef(null as TTSubscription | null);
  useEffect(() => {
    subscriptionIdentity.current?.unsubscribe();
    (async () => {
      setIdentityLoading(true);
      subscriptionIdentity.current = await vigil.getContextReactive(async (context) => {
        console.log('Setting identity : ' + JSON.stringify(context))
        setIdentity(context);
        setIdentityLoading(false);
      });
    })();

    return () => { subscriptionIdentity.current?.unsubscribe() }
  }, []);

  if (stateIdentityLoading) {
    return <FullPageLoader />
  }

  if (stateIdentityError) {
    return <FullPageError error={stateIdentityError} />
  }

  return (
    <ContextIdentity.Provider value={stateIdentity}>
      {props.children}
    </ContextIdentity.Provider>
  )
}
