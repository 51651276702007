import React, { useContext, useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { IEventInstance } from 'vigil-datamodel';
import { TTuuid } from 'tt-uuid';
import { ContextRoles } from '../../providers/provider_roles';
import { ContextOrganization } from '../../providers/provider_organization';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { useInterval } from '../../hooks/hook_interval';
import { Table } from '../../components/table/table';
import { ROUTES } from '../../routes';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';

interface ScreenHomeEventProps { }

export const ScreenHomeEventInstances: React.FC<ScreenHomeEventProps> = (props) => {
  /* State */
  const navigate = useNavigate()
  const location = useLocation();
  const params = useParams();
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);
  const organization = useContext(ContextOrganization);
  const uuidEvent = params['uuid'] || '';

  const [stateEventInstances, setEventInstances] = useState([] as IEventInstance[]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState([] as QueryFilter[]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState({ id: '', direction: 'ascending' } as Sorting);
  const [stateTablePagingSelected, setTablePagingSelected] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 } as Paging);

  const [ticker, setTicker] = useState(0);

  async function fetchEventInstanceLogsBackground() {
    if (!organization) { return }
    const response = await vigil.functions.eventInstances({
      filter: {
        uuidEvent: uuidEvent,
      },
      pagination: {
        page: stateTablePagingSelected.currentPage,
        count: stateTablePagingSelected.itemsPerPage,
      },
      ordering: {
        field: stateTableSortingSelected.id,
        direction: stateTableSortingSelected.direction
      }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateEventInstances)) {
      setEventInstances(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }

  async function fetchEventInstances() {
    try {
      setLoading(true);
      await fetchEventInstanceLogsBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update immediately
  useEffect(() => {
    fetchEventInstances();
  }, [organization, ticker]);

  // Update every 5 seconds
  const interval = useInterval(async () => {
    await fetchEventInstanceLogsBackground();
  }, 5000, false);

  function navigateEventInstance(selectedEventInstance: IEventInstance) {
    navigate(ROUTES.ROUTE_HOME_EVENT_INSTANCE.replace(':uuid', selectedEventInstance.uuid) + `?${createSearchParams({ source_event: uuidEvent })}`);
  }

  return (
    <div className='flex w-full h-full flex-col py-2'>
      <Table<IEventInstance>
        className='h-full flex flex-col drop-shadow'
        name='Event Instances'
        data={stateEventInstances}
        selectable={false}
        loading={stateLoading}
        error={stateError}
        columns={[
          {
            id: 'uuid',
            header: 'Unique ID',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateEventInstance(item)}>
                  {item.uuid}
                </div>
              );
            },
          },
          {
            id: 'event_name',
            header: 'Event Name',
            sortable: true,
            value(item) {
              return <div>{item.event.name}</div>;
            },
          },
          {
            id: 'date_created',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'last_updated',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}

        filtersOptions={[
          new TextFilter('uuid', 'Unique ID', 'contains'),
          new DateFilter('date_created', 'Date Created', 'between'),
          new DateFilter('last_updated', 'Last Updated', 'between'),
        ]}
        filtersSelected={stateTableFiltersSelected}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1) }}

        sortingOptions={null}
        sortingSelected={stateTableSortingSelected}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1) }}

        pagingOptions={null}
        pagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1) }}

        unSelectedActions={[
        ]}
        selectedActions={[
        ]}
        hoverActions={[
        ]}
      />
    </div>
  )
};

// <div className='flex items-center'>
// <div className='flex-grow'>
//   <div className='flex flex-grow items-end'>
//     <div className="flex items-center mr-6">
//       <span className='font-semibold'>Date Created:</span>
//       <span className="text-sm ml-2">{TTuuid.decodeCuuid(stateEvent.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateEvent.uuid).time.toLocaleTimeString()}</span>
//     </div>
//     <div className="flex items-center mr-6">
//       <span className='font-semibold'>Last Updated:</span>
//       <span className="text-sm ml-2">{TTuuid.decodeCuuid(stateEvent.changeStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateEvent.changeStamp).time.toLocaleTimeString()}</span>
//     </div>
//     <div className="flex items-center mr-6">
//       <span className='font-semibold'>Duration:</span>
//       <span className="text-sm ml-2">{getEventDurationInStr(stateEvent.triggers[0].eventLifetimeMS)}</span>
//     </div>
//   </div>
//   {stateEvent.triggers.map((trigger, index) => {
//     if (trigger.type === EventTriggerId.Scheduled) {
//       return <EventScheduleTrigger key={index} trigger={trigger} />
//     }
//   })}
// </div>

// <div className='mx-4'>
//   <InputButton text='Edit Patrol' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.U, Permissions.REvent)} onClick={() => toggleModalUpdatePatrol()} />
// </div>
// <InputButton text='Delete Patrol' before={<IconTrashSolid className='h-5 mr-2' />} type='btn-error' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.REvent)} onClick={() => toggleModalDeletePatrol()} />
// </div>

// {stateEvent.configs.map((config, index) => {
// if (config.type === EventConfigId.Patrol) {
//   return <HomeEventPatrol key={index} patrolConfig={config} />
// }
// })}
// </div>

