import { FC } from 'react';

interface ScreenHomeDeviceHistoryLocationProps { }

export const ScreenHomeDeviceHistoryLocation: FC<ScreenHomeDeviceHistoryLocationProps> = (props) => {
  return (
    <div className=''>
      <div> Device History Location </div>
    </div>
  );
};
