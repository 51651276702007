import { TTSchema, TTSchemaInstance } from "./schema.js";

export interface ICheckpoint {
  table: string;
  serverStampOldest: string;
  serverStampNewest: string;
}

export const schemaCheckpoint: TTSchema<ICheckpoint> = {
  table: {
    type: String,
    indexed: true,
  },
  serverStampOldest: {
    type: String,
    indexed: true,
  },
  serverStampNewest: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceCheckpoint = new TTSchemaInstance<ICheckpoint>('_checkpoints', schemaCheckpoint, 0);
