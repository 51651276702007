import { ReportConfigId } from "vigil-datamodel/src/bean_report";
import { OptionSelect } from "../../../components/input_select";

export const fromDurationHours: OptionSelect[] = [
  { value: 24, label: '24 hours' },
  { value: 48, label: '48 hours' },
  { value: 72, label: '72 hours' },
  { value: 168, label: '1 week' },
  { value: 336, label: '2 weeks' },
]

export const reportConfigOptions: OptionSelect[] = [
  { value: ReportConfigId.Patrol, label: 'Patrol' },
]