import { useContext, useState } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { IOrganizationRole } from "vigil-datamodel";

interface ModalRoleDeleteProps extends ModalProps {
  roles: IOrganizationRole[];
  onSubmit?: () => Promise<void>;
}

export const ModalRoleDelete: React.FC<ModalRoleDeleteProps> = (props) => {
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');

  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  /* DB Functions */
  async function deleteRoles() {
    try {
      setError('');
      setSubmitLoading(true);
      await vigil.functions.roleDelete({ uuids: props.roles.map(role => role.uuid) });
      props.onSubmit && await props.onSubmit();
      props.toggle();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-128">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">{props.roles.length > 1 ? 'Delete Roles' : 'Delete Role'}</h3>
      <div>Are you sure you want to delete the selected {props.roles.length > 1 ? 'roles' : 'role'}? This will:</div>
      <ul className="list-disc list-inside">
        <li className="font-semibold">Delete the following {props.roles.length > 1 ? 'roles' : 'role'}:</li>
        <ul className="list-decimal list-inside pl-6">
          {props.roles.map(role => <li key={role.uuid}>{role.name}</li>)}
        </ul>
        <li>Remove the role-user links, but not the users.</li>
        <li>Delete the {props.roles.length > 1 ? 'roles' : 'role'} <b>PERMANENTLY</b>, and cannot be undone.</li>
      </ul>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Delete' loading={stateSubmitLoading} disabled={false} type='btn-error' onClick={async () => { await deleteRoles() }}></InputButton>
      </div>
    </Modal>
  )
}