import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { FullPageLoader } from "../components/full_page_loader";
import { ContextVigilClient } from "./provider_vigil_client";
import { IOrganizationRole, Permissions } from "vigil-datamodel";
import { ContextUser } from "./provider_user";
import { ContextOrganization } from "./provider_organization";
import { TTActions, TTResource } from "tt-permissions";
import { StatusAlert } from "../components/status_alert";

class IRoleContext {
  organizationRoles: IOrganizationRole[] = [];
  userRoles: IOrganizationRole[] = [];

  constructor(organizationRoles: IOrganizationRole[], userRoles: IOrganizationRole[]) {
    this.organizationRoles = organizationRoles;
    this.userRoles = userRoles;
  }

  public hasUserPermission(actions: TTActions, resource: typeof TTResource) {
    return Permissions.hasUserPermission(this.userRoles, actions, resource);
  }
}

export const ContextRoles = createContext<IRoleContext>(new IRoleContext([], []));

interface ProviderRolesProps {
  children: ReactNode
}
export const ProviderRoles: React.FC<ProviderRolesProps> = (props: ProviderRolesProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);
  const user = useContext(ContextUser);
  const organization = useContext(ContextOrganization);

  /* State */
  const [stateOrganizationRoles, setOrganizationRoles] = useState<IOrganizationRole[]>([]);
  const [stateUserRoles, setUserRoles] = useState<IOrganizationRole[]>([]);
  const [stateRolesLoading, setRolesLoading] = useState(true);
  const [stateError, setError] = useState<string>('');

  /* Reactivity */
  // This is for when the organization changes
  useEffect(() => {
    (async () => {
      try {
        setRolesLoading(true);
        if (!organization) {
          setOrganizationRoles([]);
          return;
        }

        const orgRoles = await vigil.functions.roleFindManyPublic({ filter: { uuidOrganization: organization.uuid } });
        setOrganizationRoles(orgRoles);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setRolesLoading(false);
      }
    })();
  }, [organization]);

  // This is for when the user gets/loses a role
  // This is for when there is changes on the permissionset of the role itself
  useEffect(() => {
    (async () => {
      try {
        setRolesLoading(true);
        if (!organization || !user) {
          setUserRoles([]);
          return;
        }

        const userRoles = await vigil.functions.roleLinkedToPublic({ uuidOrganization: organization.uuid, uuidUser: user.uuid });
        setUserRoles(userRoles);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setRolesLoading(false);
      }
    })();
  }, [user, organization]);

  if (stateError) return <StatusAlert type="alert-error" message={stateError} />
  if (stateRolesLoading) return <FullPageLoader />
  return (
    <ContextRoles.Provider value={new IRoleContext(stateOrganizationRoles, stateUserRoles)}>
      {props.children}
    </ContextRoles.Provider>
  )
}
