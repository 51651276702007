import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IOrganization extends IBaseSyncable {
  name: string;
  payfastToken: string;
}

export const schemaOrganization: TTSchema<IOrganization> = {
  ...schemaBaseSyncable,
  name: {
    type: String,
    indexed: true,
  },
  payfastToken: {
    type: String,
    indexed: true,
  }
}

export const schemaInstanceOrganization = new TTSchemaSyncableInstance<IOrganization>('organizations', schemaOrganization, 0);
