import { lazy } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { ROUTES } from "./routes";
import { RouterHome } from "./router_home";
import { FullPageError } from "./components/full_page_error";
import { FullPageSuspend } from "./components/full_page_suspend";
import { Director } from "./director";
import { RouterProfile } from "./router_profile";
import { RouterAdmin } from "./router_admin";
import { ScreenHomeToolbox } from "./screens/dashboard/home_toolbox";

const ScreenSignInRequest = lazy(() => import('./screens/account/sign_in_request'));
const ScreenSignInConfirm = lazy(() => import('./screens/account/sign_in_confirm'));
const ScreenUserCreate = lazy(() => import('./screens/account/user_create'));
const ScreenUserCreateEmailRequest = lazy(() => import('./screens/account/user_create_email_request'));
const ScreenUserCreateEmailConfirm = lazy(() => import('./screens/account/user_create_email_confirm'));
const ScreenUserCreateMobileRequest = lazy(() => import('./screens/account/user_create_mobile_request'));
const ScreenUserCreateMobileConfirm = lazy(() => import('./screens/account/user_create_mobile_confirm'));

const ScreenPublicReport = lazy(() => import('./screens/public/public_report'));
const ScreenPublicReportDevices = lazy(() => import('./screens/public/public_report_devices'));
const ScreenPublicReportDevice = lazy(() => import('./screens/public/public_report_device'));
const ScreenPublicReportDeviceLogsActivity = lazy(() => import('./screens/public/public_report_device_logs_activity'));
const ScreenPublicReportDeviceLogsState = lazy(() => import('./screens/public/public_report_device_logs_state'));
const ScreenPublicReportEvents = lazy(() => import('./screens/public/public_report_events'));
const ScreenPublicReportEvent = lazy(() => import('./screens/public/public_report_event'));
const ScreenPublicReportOverview = lazy(() => import('./screens/public/public_report_overview'));
const ScreenPublicReportSites = lazy(() => import('./screens/public/public_report_sites'));
const ScreenPublicReportSite = lazy(() => import('./screens/public/public_report_site'));

const Bootflow: React.FC = () => {
  return (
    <Director>
      <Outlet />
    </Director>
  )
}

export const RouterBase: RouteObject[] = [
  {
    path: "/",
    element: <Bootflow />,
    errorElement: <FullPageError />,
    children: [
      ...RouterHome,
      ...RouterProfile,
      ...RouterAdmin,
      {
        path: ROUTES.ROUTE_SIGN_IN_REQUEST,
        element: <FullPageSuspend><ScreenSignInRequest /></FullPageSuspend>,
      },
      {
        path: ROUTES.ROUTE_SIGN_IN_CONFIRM,
        element: <FullPageSuspend><ScreenSignInConfirm /></FullPageSuspend>,
      },
      {
        path: ROUTES.ROUTE_USER_CREATE,
        element: <FullPageSuspend><ScreenUserCreate /></FullPageSuspend>,
      },
      {
        path: ROUTES.ROUTE_USER_CREATE_EMAIL_REQUEST,
        element: <FullPageSuspend><ScreenUserCreateEmailRequest /></FullPageSuspend>,
      },
      {
        path: ROUTES.ROUTE_USER_CREATE_EMAIL_CONFIRM,
        element: <FullPageSuspend><ScreenUserCreateEmailConfirm /></FullPageSuspend>,
      },
      {
        path: ROUTES.ROUTE_USER_CREATE_MOBILE_REQUEST,
        element: <FullPageSuspend><ScreenUserCreateMobileRequest /></FullPageSuspend>,
      },
      {
        path: ROUTES.ROUTE_USER_CREATE_MOBILE_CONFIRM,
        element: <FullPageSuspend><ScreenUserCreateMobileConfirm /></FullPageSuspend>,
      },
      {
        path: ROUTES.ROUTE_TOOLBOX,
        element: <FullPageSuspend><ScreenHomeToolbox /></FullPageSuspend>,
      },
      {
        path: ROUTES.ROUTE_PUBLIC_REPORT,
        element: <FullPageSuspend><ScreenPublicReport /></FullPageSuspend>,
        children: [
          {
            path: ROUTES.ROUTE_PUBLIC_REPORT_OVERVIEW,
            element: <ScreenPublicReportOverview />
          },
          {
            path: ROUTES.ROUTE_PUBLIC_REPORT_EVENTS,
            element: <ScreenPublicReportEvents />
          },
          {
            path: ROUTES.ROUTE_PUBLIC_REPORT_EVENT,
            element: <ScreenPublicReportEvent />
          },
          {
            path: ROUTES.ROUTE_PUBLIC_REPORT_SITES,
            element: <ScreenPublicReportSites />
          },
          {
            path: ROUTES.ROUTE_PUBLIC_REPORT_SITE,
            element: <ScreenPublicReportSite />
          },
          {
            path: ROUTES.ROUTE_PUBLIC_REPORT_DEVICES,
            element: <ScreenPublicReportDevices />
          },
          {
            path: ROUTES.ROUTE_PUBLIC_REPORT_DEVICE,
            element: <ScreenPublicReportDevice />,
            children: [
              {
                path: ROUTES.ROUTE_PUBLIC_REPORT_DEVICE_LOGS_ACTIVITY,
                element: <ScreenPublicReportDeviceLogsActivity />
              },
              {
                path: ROUTES.ROUTE_PUBLIC_REPORT_DEVICE_LOGS_STATE,
                element: <ScreenPublicReportDeviceLogsState />
              },
            ]
          },
        ]
      },
    ]
  },
]
