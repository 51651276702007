import React, { useContext } from 'react';
import { TextHeader } from '../../components/text_header';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextUser } from '../../providers/provider_user';
import { IOrganization, IProfileResult, IUserOrganizationInvite } from 'vigil-datamodel';
import { useStateLive } from '../../hooks/hook_live_state';
import { StatusLoading } from '../../components/status_loading';
import { StatusAlert } from '../../components/status_alert';
import { InputButtonAsync } from '../../components/input_button_async';
import { InputButton } from '../../components/input_button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { Preferences } from '../../preference_keys';

interface ScreenProfileOrganizationsProps { }

export const ScreenProfileOrganizations: React.FC<ScreenProfileOrganizationsProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const user = useContext(ContextUser);
  const navigate = useNavigate();

  const stateProfile = useStateLive<IProfileResult>(
    async () => {
      const token = await vigil.getToken();
      const result = await vigil.functions.profile({token: token});
      return result;
    },
    [user],
    5000
  );

  async function actionNewOrganization() {
    await vigil.setPreference(Preferences.SELECTED_ORGANIZATION, null);
    navigate(ROUTES.ROUTE_HOME_ONBOARDING_NEW_ORGANIZATION)
  }

  async function actionSelectOrganization(organization: IOrganization) {
    await vigil.setPreference(Preferences.SELECTED_ORGANIZATION, organization.uuid);
    navigate(ROUTES.ROUTE_HOME)
  }

  async function actionAcceptInvite(invite: IUserOrganizationInvite) {
    await vigil.functions.userOrganizationInviteAccept({ uuidUserOrganizationInvite: invite.uuid });
    stateProfile.refetch();
  }

  async function actionRejectInvite(invite: IUserOrganizationInvite) {
    await vigil.functions.userOrganizationInviteReject({ uuidUserOrganizationInvite: invite.uuid });
    stateProfile.refetch();
  }

  return (
    <div className='py-4'>
      <div className='rounded-t-xl bg-base-300 p-2'>
        <div className='flex items-center'>
          <div className='font-semibold flex flex-grow'>Organizations</div>
          <InputButton text='New Organization' type='btn-primary' size='btn-sm' onClick={() => { actionNewOrganization() }} />
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2'>
        {
          stateProfile.stateLoading &&
          <div className='flex justify-center my-4'>
            <StatusLoading />
          </div>
        }
        {
          !stateProfile.stateLoading && stateProfile.stateError &&
          <div>
            <StatusAlert message={stateProfile.stateError} type='alert-error' />
          </div>
        }
        {
          !stateProfile.stateLoading && !stateProfile.stateError &&
          <div className='m-2'>
            {
              !stateProfile.stateResult || stateProfile.stateResult.organizations.length == 0 &&
              <div>
                You have no linked organizations
                <br />
                You can either create a new organization, or asked to be invited to an existing organization
              </div>
            }
            {
              stateProfile.stateResult && stateProfile.stateResult.organizations.length > 0 &&
              stateProfile.stateResult.organizations.map((organization) => {
                return <div className='flex p-2 border-solid border-b border-base-300 link hover:bg-base-300 rounded' key={organization.uuid} onClick={() => actionSelectOrganization(organization)}>
                  <div className='mr-2'>
                    <img src={`https://ui-avatars.com/api/?name=${organization?.name[0]}&background=random`} className="w-8 h-8 rounded-full" alt="" />
                  </div>
                  <div className='flex-1'>
                    {organization.name}
                  </div>
                </div>
              })
            }
          </div>
        }
      </div>

      <div className='h-4' />

      <div className='rounded-t-xl bg-base-300 p-2'>
        <div className='flex items-center'>
          <div className='font-semibold'>Organization Invites</div>
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2'>
        {
          stateProfile.stateLoading &&
          <div className='flex justify-center my-4'>
            <StatusLoading />
          </div>
        }
        {
          !stateProfile.stateLoading && stateProfile.stateError &&
          <div>
            <StatusAlert message={stateProfile.stateError} type='alert-error' />
          </div>
        }
        {
          !stateProfile.stateLoading && !stateProfile.stateError &&
          <div className='m-2'>
            {
              !stateProfile.stateResult || stateProfile.stateResult.organizationInvites.length == 0 &&
              <div>
                You have no pending organization invites.
                <br /><br />
                If you wish to join an organization, ask the administrator to send you an invite.
              </div>
            }
            {
              stateProfile.stateResult && stateProfile.stateResult.organizationInvites.length > 0 &&
              stateProfile.stateResult.organizationInvites.map((item) => {
                return <div className='flex p-2 border-solid border-b border-base-300' key={item.organization.uuid}>
                  <div className='mr-2'>
                    <img src={`https://ui-avatars.com/api/?name=${item.organization?.name[0]}&background=random`} className="w-8 h-8 rounded-full" alt="" />
                  </div>
                  <div className='flex-1'>
                    {item.organization.name}
                  </div>
                  <div className='flex'>
                    <InputButtonAsync
                      text='Accept' type='btn-success' size='btn-sm'
                      asyncAction={async () => { await actionAcceptInvite(item.invite) }}
                    />
                    <div className='mr-1' />
                    <InputButtonAsync
                      text='Decline' type='btn-error' size='btn-sm'
                      asyncAction={async () => { await actionRejectInvite(item.invite) }}
                    />
                  </div>
                </div>
              })
            }
          </div>
        }
      </div>
    </div>
  );
};
