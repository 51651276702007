import { TTuuid } from "tt-uuid";
import { QueryFilter } from "../../components/table/table_filters/table_filter_types";

export function escapeRegExp(inputStr: string) {
  return inputStr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function convertQueryFilterToMongoFilter(filters: QueryFilter[]): any {
  let match: any = {};
  for (const filter of filters) {
    if (filter.query === 'contains') {
      match[filter.id] = { $regex: escapeRegExp(filter.value.toString()), $options: 'i' }
    }
    if (filter.query === 'between') {
      const startDate = new Date((filter.value as any).startDate)
      const endDate = new Date((filter.value as any).endDate)
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 59, 999)
      const startUuid = TTuuid.getCuuidFromValues(startDate, 0)
      const endUuid = TTuuid.getCuuidFromValues(endDate, 0)
      match[filter.id] = { $gte: startUuid, $lte: endUuid }
    }
  }
  return match;
}