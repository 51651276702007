import { useState, useEffect } from 'react';
import useWindowDimensions from './hook_window_size';

export type BreakPoint = 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | '2XL';

function getBreakpoints(windowDimensions: {width: number, height: number}) {
  return {
    '2XL': windowDimensions.width >= 1536,
    'XL': windowDimensions.width >= 1280,
    'LG': windowDimensions.width >= 1024,
    'MD': windowDimensions.width >= 768,
    'SM': windowDimensions.width >= 640,
  }
}

export default function useBreakpoints() {
  const windowDimensions = useWindowDimensions();
  const [breakpoints, setBreakpoints] = useState(getBreakpoints(windowDimensions));

  useEffect(() => {
    setBreakpoints(getBreakpoints(windowDimensions))
  }, [windowDimensions])

  return breakpoints;
}
