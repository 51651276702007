import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IScript extends IBaseSyncable {
  name: string;
  content: string;
}

export const schemaScript: TTSchema<IScript> = {
  ...schemaBaseSyncable,
  name: {
    type: String,
    indexed: true,
  },
  content: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceScript = new TTSchemaSyncableInstance<IScript>('scripts', schemaScript, 0);
