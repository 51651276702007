import { FC } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../routes';
import { Tabs } from '../../components/tabs';
import { IconBars4, IconCalendar, IconPresentationChart, IconMapPin, IconQueueList, IconClipboardDocumentCheckIcon } from '../../components/icons';

interface ScreenHomeDeviceProps { }

export const ScreenHomeDevice: FC<ScreenHomeDeviceProps> = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const uuidDevice = params['uuid'] || '';

  function navigateTab(pageId: string) {
    navigate({
      pathname: ROUTES.ROUTE_HOME_DEVICE.replace(':uuid', uuidDevice) + '/' + pageId,
    })
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  return (
    <div className='px-4 pb-2 w-full h-full flex flex-col'>
      <Breadcrumbs crumbs={[{ id: 'notifications', onClick: () => navigate(ROUTES.ROUTE_HOME_DEVICES), text: 'Devices' }, { id: uuidDevice, onClick: () => { }, text: uuidDevice }]} />

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <Tabs selectedId={getSelectedId()} onSelect={navigateTab} items={[
        {
          icon: IconPresentationChart,
          id: 'history_statistics',
          text: 'Statistics'
        },        {
          icon: IconQueueList,
          id: 'behaviours',
          text: 'Behaviours'
        },
        {
          icon: IconClipboardDocumentCheckIcon,
          id: 'logs_activity',
          text: 'Activity Logs'
        },
        {
          icon: IconQueueList,
          id: 'logs_state',
          text: 'State Logs'
        },
        // {
        //   icon: IconMapPin,
        //   id: 'history_location',
        //   text: 'Location'
        // },
      ]} />

      <div className='w-full flex-grow'>
        <Outlet />
      </div>
    </div>
  );
};
