import React from 'react';
import { StatusLoading } from './status_loading';
import { InputBase, InputBaseProps } from './input_base';
import { CheckboxGroup, CheckboxGroupProps } from './checkbox_group';

export type InputCheckboxGroupProps = InputBaseProps & {
  loading?: boolean;
  checkboxGroup: CheckboxGroupProps;
};

export const InputCheckboxGroup: React.FC<InputCheckboxGroupProps> = (props) => {
  return (
    <InputBase {...props}>
      {props.loading && <div className='flex justify-center'><StatusLoading /></div>}
      {!props.loading && <CheckboxGroup {...props.checkboxGroup} />}
    </InputBase>
  );
};
