import { FC, useContext, useState } from 'react';
import { InputButton } from "../../components/input_button";
import { InputForm } from "../../components/input_form";
import { Modal, ModalProps } from "../../components/modal";
import { TextHeader } from "../../components/text_header";
import { ContextUser } from '../../providers/provider_user';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { VALIDATORS, validate } from '../../validation';
import { StatusAlert } from '../../components/status_alert';
import { TextDescription } from '../../components/text_description';
import { InputText } from '../../components/input_text';
import { ContextToast } from '../../providers/provider_toast';
import { Preferences } from '../../preference_keys';

interface ModalUpdateMobileProps extends ModalProps {
  onSubmit?: () => Promise<void>;
}

export const ModalUpdateMobile: FC<ModalUpdateMobileProps> = (props) => {
  const user = useContext(ContextUser);
  const vigil = useContext(ContextVigilClient);
  const toast = useContext(ContextToast);

  const [stateStep, setStep] = useState('request');
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");
  const [stateMobile, setMobile] = useState(user?.mobile || '');
  const [stateOTP, setOTP] = useState('');

  const steps = {
    REQUEST: 'request',
    CONFIRM: 'confirm'
  }

  /* UI Updates */
  function onChangeMobile(event: React.ChangeEvent<HTMLInputElement>) {
    setMobile(event.target.value)
  }
  function onChangeOTP(event: React.ChangeEvent<HTMLInputElement>) {
    setOTP(event.target.value)
  }
  function navigateStep(step: string) {
    setOTP('');
    setError('');
    setStep(step);
  }

  /* Actions */
  async function request() {
    try {
      setLoading(true)
      setError("")
      await vigil.functions.userUpdateMobileRequest({ uuidUser: user!.uuid, mobile: stateMobile });
      navigateStep(steps.CONFIRM);
    }
    catch (error: any) {
      setError(error.message)
    }
    finally {
      setLoading(false)
    }
  }

  async function confirm() {
    try {
      setLoading(true)
      setError("")
      const userUpdate = await vigil.functions.userUpdateMobileConfirm({ uuidUser: user!.uuid, mobile: stateMobile, otp: stateOTP });
      await vigil.setPreference(Preferences.SELECTED_USER, JSON.stringify(userUpdate));
      props.toggle();
      navigateStep(steps.REQUEST);

      toast.addToast({
        component: <StatusAlert type='alert-success' message='Mobile successfully updated.' />,
        lifetimeMS: 3000
      })
    }
    catch (error: any) {
      setError(error.message)
    }
    finally {
      setLoading(false)
    }
  }

  /* Validation */
  function hasChanges() {
    const changed = stateMobile !== user!.mobile;
    return changed;
  }
  function validateMobile() {
    if (!stateMobile) return [];
    return validate(stateMobile, [VALIDATORS.mobile()]);
  }
  function validateOTP() {
    if (!stateOTP) return [];
    return validate(stateOTP, [VALIDATORS.length('OTP', 6, 6)]);
  }
  function validateFormRequest() {
    const required: string[] = [];
    if (!stateMobile) { required.push('Mobile is required') }

    return [
      ...required,
      ...validateMobile(),
    ];
  }
  function validateFormConfirm() {
    const required: string[] = [];
    if (!stateOTP) { required.push('OTP is required') }

    return [
      ...required,
      ...validateOTP(),
    ];
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-128">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <TextHeader> Update Mobile Number </TextHeader>
      {
        // STEP - Request OTP
        stateStep == steps.REQUEST &&
        <div>
          <div className='my-4'>
            <TextDescription> Please enter the new mobile number you wish to associate to your account.</TextDescription>
          </div>
          <InputForm onSubmit={request}>
            <InputText labelText='Mobile' value={stateMobile} onChange={onChangeMobile} errors={validateMobile()} />
            <div className='py-2'>
              {stateError && <StatusAlert type='alert-error' message={stateError} />}
            </div>
            <InputButton
              type='btn-primary'
              text='Continue'
              loading={stateLoading}
              disabled={validateFormRequest().length > 0 || !hasChanges()}
              onClick={request}
            />
          </InputForm>
        </div>
      }
      {
        // STEP - Confirm OTP
        stateStep == steps.CONFIRM &&
        <div>
          <div className='my-6'>
            <StatusAlert type='alert-success' message={`OTP successfully sent to ${stateMobile}`} />
          </div>
          <div className='my-4'>
            <TextDescription> To confirm your new mobile number please enter the OTP sent to you in the input below. </TextDescription>
          </div>

          <InputForm onSubmit={confirm}>
            <InputText labelText='OTP' value={stateOTP} onChange={onChangeOTP} errors={validateOTP()} />
            <div className='py-2'>
              {stateError && <StatusAlert type='alert-error' message={stateError} />}
            </div>
            <InputButton
              type='btn-primary'
              text='Save'
              loading={stateLoading}
              disabled={validateFormConfirm().length > 0}
              onClick={confirm}
            />
            <div className='my-2'>
            </div>
            <InputButton
              type='btn-default'
              text='Back'
              onClick={() => navigateStep('request')}
            />
          </InputForm>
        </div>
      }
    </Modal>
  )
}