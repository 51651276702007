import { useNavigate } from 'react-router-dom';
import VigilLogo from '../assets/vigil_icon.svg';
import { BackgroundHandicrafts } from '../components/background_handicrafts';
import { InputButton } from '../components/input_button';

export const ScreenUnauthorized: React.FC = () => {
  const navigate = useNavigate()

  function navigateHome() {
    navigate('/home')
  }

  return (
    <div className="flex min-h-full items-center justify-center px-2 py-12 sm:px-6 lg:px-8 bg-base-300">
      <BackgroundHandicrafts />
      <div className="w-full max-w-md space-y-8 card card-bordered card-body shadow-xl p-5 bg-base-100">
        <div>
          <img
            className="mx-auto h-24 w-auto"
            src={VigilLogo}
            alt="Vigil"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight">
            Unauthorized Access
          </h2>
          <p className='text-center'>
            You do not have the required permissions to view this page.
          </p>
          <div className='mt-5'>
            <InputButton text='Go Home' type='btn-link' loading={false} disabled={false} onClick={navigateHome}></InputButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScreenUnauthorized
