import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IBeaconOrganization extends IBaseSyncable {
  uuidBeacon: string;
  uuidOrganization: string;
}

export const schemaBeaconOrganization: TTSchema<IBeaconOrganization> = {
  ...schemaBaseSyncable,
  uuidBeacon: {
    type: String,
    indexed: true,
  },
  uuidOrganization: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceBeaconOrganization = new TTSchemaSyncableInstance<IBeaconOrganization>('beacon_organizations', schemaBeaconOrganization, 0);
