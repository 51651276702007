import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IEventOrganization extends IBaseSyncable {
  uuidEvent: string;
  uuidOrganization: string;
}

export const schemaEventOrganization: TTSchema<IEventOrganization> = {
  ...schemaBaseSyncable,
  uuidEvent: {
    type: String,
    indexed: true,
  },
  uuidOrganization: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceEventOrganization = new TTSchemaSyncableInstance<IEventOrganization>('event_organizations', schemaEventOrganization, 0);
