import React, { useContext, useState } from 'react';
import VigilLogo from '../../assets/vigil_icon.svg';
import { InputForm } from '../../components/input_form';
import { InputText } from '../../components/input_text';
import { InputButton } from '../../components/input_button';
import { StatusAlert } from '../../components/status_alert';
import { BackgroundHandicrafts } from '../../components/background_handicrafts';
import { useNavigate } from 'react-router-dom';
import { ContextUser } from '../../providers/provider_user';
import { ContextSession } from '../../providers/provider_session';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { TTuuid } from 'tt-uuid';
import { Preferences } from '../../preference_keys';
import { IDashActivityLogOrgLoginLogout } from 'vigil-datamodel';
import { ROUTES } from '../../routes';
import { VALIDATORS, validate } from '../../validation';
import { sleep } from 'tt-coms';

interface ScreenHomeOnboardingNewOrganizationProps { }
// TODO: Come rewrite this whole page in the style of the profile pages
export const ScreenHomeOnboardingNewOrganization: React.FC<ScreenHomeOnboardingNewOrganizationProps> = (props) => {
  /* Utils */
  const navigate = useNavigate();
  const vigil = useContext(ContextVigilClient);
  const session = useContext(ContextSession);

  /* State */
  const contextUser = useContext(ContextUser);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");
  const [stateOrganizationName, setOrganizationName] = useState("");

  /* UI Updates */
  function onChangeOrganizationName(event: React.ChangeEvent<HTMLInputElement>) {
    setOrganizationName(event.target.value);
  }

  /* Actions */
  async function actionCreateOrganization() {
    try {
      setLoading(true)
      setError("")
      const uuidOrganization = TTuuid.getCuuid();

      // Create organization
      const result = await vigil.functions.organizationCreate({
        input: {
          name: stateOrganizationName,
          payfastToken: '',
        }
      });

      // Select organization
      await vigil.setPreference(Preferences.SELECTED_ORGANIZATION, result.organization.uuid);

      // Log event
      session?.logEvent<IDashActivityLogOrgLoginLogout>(
        'orgLogin',
        {
          orgUuid: result.organization.uuid
        });

      // Go home
      await sleep(500);
      navigate(ROUTES.ROUTE_HOME)
    }
    catch (error: any) {
      setError(error.message)
    }
    finally {
      setLoading(false)
    }
  }

  /* Validations */
  function validateOrganizationName() {
    if (!stateOrganizationName) return [];
    return validate(stateOrganizationName, [VALIDATORS.length('Organization name', 4, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateOrganizationName) { required.push('Email or mobile is required') }
    return [
      ...required,
      ...validateOrganizationName(),
    ];
  }

  /* Component */
  return (
    <div className="flex w-full min-h-full items-center justify-center px-2 py-12 sm:px-6 lg:px-8 bg-base-300">
      {/* <BackgroundHandicrafts /> */}
      <div className="w-full max-w-md space-y-4 card card-bordered card-body shadow-xl p-5 bg-base-100">
        <div>
          <img
            className="mx-auto h-24 w-auto"
            src={VigilLogo}
            alt="Vigil"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight">
            Create your Organization
          </h2>
          <p className='mt-4'>
            Welcome! Create your organization and embark on a journey of growth, impact, and success.
          </p>
        </div>
        <InputForm onSubmit={actionCreateOrganization} >
          <div>
            <InputText labelText='Organization Name' value={stateOrganizationName} onChange={onChangeOrganizationName} errors={validateOrganizationName()} />
          </div>
          <div className='mt-8'>
            <InputButton text='Continue' loading={stateLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={actionCreateOrganization} />
            <div className="my-2">
              {stateError && <StatusAlert message={stateError} type='alert-error' />}
            </div>
          </div>
        </InputForm>
      </div>
    </div>
  )
};
