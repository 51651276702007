import { ROUTES } from "./routes";
import { FullPageError } from "./components/full_page_error";
import { FullPageSuspend } from "./components/full_page_suspend";
import { RouteObject } from "react-router-dom";
import { ScreenAdmin } from "./screens/admin/admin";
import { ScreenAdminBeacons } from "./screens/admin/admin_beacons";
import { ScreenAdminDevices } from "./screens/admin/admin_devices";

export const RouterAdmin: RouteObject[] = [{
  path: ROUTES.ROUTE_ADMIN,
  errorElement: <FullPageError />,
  element: <FullPageSuspend><ScreenAdmin /></FullPageSuspend>,
  children: [
    {
      path: ROUTES.ROUTE_ADMIN_BEACONS,
      element: <ScreenAdminBeacons />
    },
    {
      path: ROUTES.ROUTE_ADMIN_DEVICES,
      element: <ScreenAdminDevices  />
    },
  ]
}]
