import React, { useContext, useState, useEffect, useRef } from 'react';
import { VigilMap, VigilMapController } from '../../components/vigil_map';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { Coordinate, VigilMapMessageId, VigilMapV2Props, VigilMapV2ThemeId, getBoundingBox } from 'vigil-map';
import { useStateLive } from '../../hooks/hook_live_state';
import useBreakpoints from '../../hooks/hook_breakpoints';
import { TTuuid } from 'tt-uuid';
import { ContextTheme } from '../../providers/provider_theme';

interface ScreenHomeOverviewMapProps { }

export const ScreenHomeOverviewMap: React.FC<ScreenHomeOverviewMapProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const breakpoints = useBreakpoints();
  const theme = useContext(ContextTheme);

  /* State */
  const [stateSnapped, setSnapped] = useState(false);
  const [stateMapController, setMapController] = useState(null as null | VigilMapController);
  const [stateMapProps, setMapProps] = useState({} as VigilMapV2Props);

  // Live state for getting server representation of map
  const stateMapServer = useStateLive(async () => {
    if (!organization) { return }
    const result = await vigil.functions.map({ filter: { uuidsOrganizations: [organization.uuid], beacons: true, devices: true } });
    return result;
  }, [organization], 5000);

  // Reactive state for getting map package representation of map
  useEffect(() => {
    // Get first organization, later we could have multiple orgs
    const organizationMapState = stateMapServer.stateResult?.organizations[0];
    const mapProps: VigilMapV2Props = {};
    if (organizationMapState) {
      // TODO: Perhaps the backend should just return the data as the frontend wants it ? No conversion needed.
      // Convert beacons from backend -> frontend
      if (organizationMapState.beacons) {
        mapProps.beacons = [];
        for (const beacon of organizationMapState.beacons) {
          mapProps.beacons.push({
            uuid: beacon.uuid,
            lat: beacon.latitude,
            lng: beacon.longitude,
            name: beacon.name
          });
        }
      }

      // Convert devices from backend -> frontend
      if (organizationMapState.devices) {
        mapProps.devices = [];
        for (const pair of organizationMapState.devices) {
          // Ensure we have the fields we need
          if (!pair.deviceStateLog.locationLat || !pair.deviceStateLog.locationLng) { continue }

          // Create new representation
          mapProps.devices.push({
            uuid: pair.device.uuid,
            user: pair.user ? { name: `${pair.user.firstName} ${pair.user.lastName}` } : undefined,
            chargeLevel: pair.deviceStateLog.chargeLevel,
            chargeState: pair.deviceStateLog.chargeState == "plugged" ? "Charging" : "Discharging", // TODO: Come unify this, why do we have two sets for the same thing ?
            lastSeen: TTuuid.decodeCuuid(pair.deviceStateLog.uuid).time,
            lat: pair.deviceStateLog.locationLat,
            lng: pair.deviceStateLog.locationLng,
          });
        }
      }
    }

    if (theme == 'dark') {
      mapProps.theme = VigilMapV2ThemeId.DARK_MATTER;
    }

    mapProps.zoomControl = breakpoints['MD'] ? true : false;

    setMapProps(mapProps)
  }, [stateMapServer.stateResult, breakpoints])

  useEffect(() => {
    // Fit bounds
    const points: Coordinate[] = [];
    stateMapProps.beacons?.forEach((beacon) => {
      points.push({ lat: beacon.lat, lng: beacon.lng });
    })
    stateMapProps.devices?.forEach((device) => {
      points.push({ lat: device.lat, lng: device.lng });
    })

    if (stateMapController && !stateSnapped && points.length > 0) {
      setSnapped(true);
      const bounds = getBoundingBox(points);
      stateMapController?.sendVigilMessage({
        id: VigilMapMessageId.FIT_BOUNDS,
        bounds: bounds,
        options: {
          maxZoom: 15,
          padding: {
            left: 150,
            right: 150,
            top: 150,
            bottom: 150
          }
        }
      })
    }
  }, [stateMapProps, stateMapController])

  return (
    <div className='w-full h-full rounded-xl overflow-hidden'>
      <VigilMap setMapController={setMapController} state={stateMapProps} loading={stateMapServer.stateLoading} />
    </div>
  );
};
