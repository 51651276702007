import React from 'react';
import { InputBase, InputBaseProps } from './input_base';

interface InputTextAreaProps extends InputBaseProps {
  value: string,
  placeholder?: string,
  onChange?: (value: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export const InputTextArea: React.FC<InputTextAreaProps> = (props) => {
  function getClassName() {
    let result = 'w-full h-full textarea textarea-bordered';
    if (props.errors.length) {
      result += ' textarea-error'
    }
    return result
  }
  return (
    <InputBase {...props}>
      <textarea
        placeholder={props.placeholder}
        className={getClassName()}
        onChange={props.onChange}
        value={props.value}
      />
    </InputBase>
  );
};
