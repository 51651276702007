import React, { useEffect, useRef, useState } from 'react';
import { InputBase, InputBaseProps } from './input_base';

export interface OptionSearchDropdown {
  label: string;
  value: string | number;
}

interface InputSearchDropdownProps extends InputBaseProps {
  options: OptionSearchDropdown[];
  placeholder?: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect?: (value: string | number) => void;
  dropdownPosition?: 'top' | 'bottom';
  label?: React.ReactNode;
  labelPosition?: 'front' | 'back';
}

export const InputSearchDropdown: React.FC<InputSearchDropdownProps> = (props) => {
  const [selectedOption, setSelectedOption] = useState<OptionSearchDropdown | null>(null);
  const [search, setSearch] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const filteredOptions = props.options.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  function getInputClassName() {
    let result = 'input input-bordered flex items-center gap-2';
    if (props.errors.length) {
      result += ' input-error'
    }
    return result
  }

  const getDropdownPositionClasses = () => {
    if (props.dropdownPosition === 'top') {
      return 'bottom-full mb-2';
    }
    return 'top-full mt-2';
  };

  const renderLabel = () => {
    if (!props.label) return null;
    return props.label;
  };

  return (
    <div>
      <InputBase {...props}>
        <div ref={wrapperRef} className="relative">
          <label className={getInputClassName()}>
            {props.labelPosition !== 'back' && renderLabel()}
            <input
              type="text"
              className="grow"
              placeholder={props.placeholder}
              onClick={() => setShowDropdown(true)}
              value={selectedOption ? selectedOption.label : search}
              onChange={(e) => {
                if (e.target.value !== selectedOption?.value) {
                  setSelectedOption(null);
                }
                setSearch(e.target.value);
                props.onChange && props.onChange(e);
              }}
            />
            {props.labelPosition === 'back' && renderLabel()}
          </label>
          {showDropdown && (
            <div className={`dropdown-content drop-shadow-xl rounded-box h-40 overflow-y-auto absolute w-full z-10 ${getDropdownPositionClasses()}`}>
              <ul tabIndex={0} className="menu bg-base-100 rounded-box">
                {filteredOptions.map((option, index) => (
                  <li key={index}>
                    <a
                      href="#"
                      onClick={() => {
                        setSelectedOption(option);
                        setShowDropdown(false);
                        props.onSelect && props.onSelect(option.value);
                      }}
                    >
                      {option.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </InputBase>
    </div>
  );
};