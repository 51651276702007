import { useContext, useState } from "react";
import { ContextVigilClient } from "./provider_vigil_client";
import { ContextUser } from "./provider_user";
import { ContextOrganization } from "./provider_organization";
import { ContextToast } from "./provider_toast";
import { TTuuid } from "tt-uuid";
import { INotification, NotificationId, NotificationLogStatus } from "vigil-datamodel";
import { useInterval } from "../hooks/hook_interval";
import { sleep } from "tt-coms";


interface NotificationProps {
  notification: INotification;
  onDismiss: () => void;
}

const NotificationPanic: React.FC<NotificationProps> = (props: NotificationProps) => {
  if (props.notification.id !== NotificationId.Panic) return <></>;
  const data = props.notification.data;
  return (
    <div className="bg-error text-base-100 p-4 rounded-xl">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-4 top-4" onClick={() => props.onDismiss()}>✕</button>
      <div className="font-semibold">{`Panic from: ${data.userFirstName} ${data.userLastName}`}</div>
      <div className="font-semibold">{`Panic time: ${TTuuid.decodeCuuid(props.notification.uuid).time.toLocaleDateString()} ${TTuuid.decodeCuuid(props.notification.uuid).time.toLocaleTimeString()}`}</div>
      <a className="text-blue-500 underline" target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps?q=${props.notification.data.lat},${props.notification.data.lng}`}>Panic Location</a>
    </div>
  )
}

const NotificationLateStart: React.FC<NotificationProps> = (props: NotificationProps) => {
  if (props.notification.id !== NotificationId.LateStart) return <></>;
  const data = props.notification.data;
  return (
    <div className="bg-warning text-base-100 p-4 rounded-xl">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-4 top-4" onClick={() => props.onDismiss()}>✕</button>
      <div className="font-semibold">{`Late start on site: ${data.siteName}`}</div>
    </div>
  )
}

const NotificationUnsuccessfulPatrol: React.FC<NotificationProps> = (props: NotificationProps) => {
  if (props.notification.id !== NotificationId.UnsuccessfulPatrol) return <></>;
  const data = props.notification.data;
  return (
    <div className="bg-warning text-base-100 p-4 rounded-xl">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-4 top-4" onClick={() => props.onDismiss()}>✕</button>
      <div className="font-semibold">{`Unsuccessfull patrol on site: ${data.siteName}`}</div>
      <div className="font-semibold">{`Only ${data.percentageCompleted !== 0 ? (data.percentageCompleted / data.percentageRequired) * 100 + "%" : "0%"} successfull`}</div>
      <div className="font-semibold">{`Required ${data.percentageRequired}%`}</div>
    </div>
  )
}

function playSound(sound: 'panic' | 'warning') {
  // TODO: Be aware that the sound will not play unless the user has interacted with the page
  let audioUrl = '';
  if (sound === 'panic') audioUrl = '../../alarm.ogg'
  if (sound === 'warning') audioUrl = '../../alarm_notify.ogg'
  const audio = new Audio(audioUrl);
  audio.play();
}

interface ProviderNotificationsProps { }
export const ProviderNotifications: React.FC<ProviderNotificationsProps> = (props: ProviderNotificationsProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);
  const toast = useContext(ContextToast);
  const user = useContext(ContextUser);
  const organization = useContext(ContextOrganization);
  const [stateNotificationNewestUuid, setNotificationNewestUuid] = useState("00000000000000_0000000" as string);

  /* State */
  async function dismissNotification(idToast: string, notification: INotification) {
    await vigil.functions.notificationsMarkDismissed({ uuidNotification: notification.uuid, uuidUser: user!.uuid });
    toast.removeToast(idToast);
  }

  /* Get notifications */
  async function notify(notification: INotification) {
    if (notification.id === NotificationId.Panic) {
      const id = toast.addToast({
        component: <NotificationPanic notification={notification} onDismiss={() => dismissNotification(id, notification)} />,
      })
      playSound('panic');
    }
    if (notification.id === NotificationId.LateStart) {
      const id = toast.addToast({
        component: <NotificationLateStart notification={notification} onDismiss={() => dismissNotification(id, notification)} />,
      })
      playSound('warning');
    }
    if (notification.id === NotificationId.UnsuccessfulPatrol) {
      const id = toast.addToast({
        component: <NotificationUnsuccessfulPatrol notification={notification} onDismiss={() => dismissNotification(id, notification)} />,
      })
      playSound('warning');
    }

    // We mark the notification as received
    await sleep(2000);
    await vigil.functions.notificationsMarkReceived({ uuidNotification: notification.uuid, uuidUser: user!.uuid });
  }

  async function fetchNotifications() {
    // If the depenancies are empty early exit
    if (!user || !organization) { return };

    // Find notifications that are unacknowledged, but less than 15 minutes old
    const notifications = await vigil.functions.notificationsUnseen({
      uuidUser: user.uuid,
      uuidOrganization: organization.uuid,
      createdAfter: new Date(new Date().getTime() - (15 * 60 * 1000)) // 15 minutes ago
    });

    // Check if there are any new notifications
    for (const notification of notifications) {
      if (notification.uuid > stateNotificationNewestUuid) {
        await notify(notification);
        setNotificationNewestUuid(notification.uuid);
      }
    }
  }

  useInterval(() => {
    fetchNotifications();
  }, 10_000);

  return <></>
}
