import React from 'react';
import { StatusLoading } from './status_loading';
import { ButtonSize, ButtonType, Size } from './shared_options';

interface InputButtonIconProps {
  icon: any,
  shape: 'btn-circle' | 'btn-square',
  type?: ButtonType,
  buttonSize?: ButtonSize,
  iconSize?: Size,
  disabled?: boolean,
  loading?: boolean,
  onClick?: () => void,
}

export const InputButtonIcon: React.FC<InputButtonIconProps> = (props) => {
  const buttonSize = props.buttonSize || 'md';
  const iconSize = props.iconSize || '6'
  return (
    <button className={`btn ${props.type || 'btn-default'} btn-${buttonSize} ${props.shape}`} disabled={props.disabled || props.loading} onClick={props.onClick}>
      <div className='flex'>
        {props.loading && <StatusLoading size={iconSize}/>}
        {!props.loading && React.createElement(props.icon, { className: `w-${iconSize} h-${iconSize}` })}
      </div>
    </button>
  );
};
