import React, { useContext, useEffect, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { ISiteDirectory, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/hook_modal';
import { ModalSiteCreate } from './func_site/modal_site_create';
import { ModalSiteDelete } from './func_site/modal_site_delete';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { ContextRoles } from '../../providers/provider_roles';
import { convertQueryFilterToMongoFilter } from './helper_home';
import { TTActions } from 'tt-permissions';
import { useInterval } from '../../hooks/hook_interval';
import { SitesTable } from '../shared/sites_table';

interface ScreenHomeSitesProps { }

export const ScreenHomeSites: React.FC<ScreenHomeSitesProps> = (props) => {
  const navigate = useNavigate()
  const contextOrganization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const vigil = useContext(ContextVigilClient);

  const [ticker, setTicker] = useState(0);

  const [stateSites, setSites] = useState<ISiteDirectory[]>([]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState<QueryFilter[]>([]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState<Sorting>({ id: 'uuid', direction: 'descending' });
  const [stateTablePagingSelected, setTablePagingSelected] = useState<Paging>({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 });

  const { isOpen: isOpenModalCreateSite, toggle: toggleModalCreateSite } = useModal();
  const { isOpen: isOpenModalDeleteSite, toggle: toggleModalDeleteSite } = useModal();

  const [selectedSitesState, setSelectedSites] = useState<ISiteDirectory[]>([]);

  async function fetchSitesBackground() {
    if (!contextOrganization) { return }

    const response = await vigil.functions.siteDirectory({
      uuidOrganization: contextOrganization.uuid,
      filter: convertQueryFilterToMongoFilter(stateTableFiltersSelected),
      ordering: { field: stateTableSortingSelected.id, direction: stateTableSortingSelected.direction },
      pagination: { page: stateTablePagingSelected.currentPage, count: stateTablePagingSelected.itemsPerPage }
    });

    console.log(response.pagination.count)

    if (JSON.stringify(response.items) != JSON.stringify(stateSites)) {
      setSites(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }

  async function fetchSites() {
    try {
      setLoading(true);
      await fetchSitesBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchSites();
  }, [contextOrganization, ticker])

  useInterval(async () => {
    await fetchSitesBackground();
  }, 5000, false);

  function navigateSite(selectedSite: ISiteDirectory) {
    navigate(ROUTES.ROUTE_HOME_SITE.replace(':uuid', selectedSite.uuid))
  }

  function deleteSites(selectedSites: ISiteDirectory[]) {
    setSelectedSites(selectedSites)
    toggleModalDeleteSite();
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalSiteCreate isOpen={isOpenModalCreateSite} toggle={toggleModalCreateSite} onSubmit={async () => await fetchSites()} />
      <ModalSiteDelete isOpen={isOpenModalDeleteSite} toggle={toggleModalDeleteSite} onSubmit={async () => await fetchSites()} sites={selectedSitesState} />

      <SitesTable
        sites={stateSites}
        loading={stateLoading}
        error={stateError}
        onSiteClick={navigateSite}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1); }}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1); }}
        tablePagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1); }}
        selectable={true}
        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new site</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RSite),
            onClick: () => toggleModalCreateSite(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RSite),
            onClick: (selectedItems) => deleteSites(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RSite),
            onClick: (selectedItems) => deleteSites(selectedItems)
          }
        ]}
      />
    </div>
  );
};