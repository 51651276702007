import React from 'react';

interface CheckboxProps {
  label: string;
  checked: boolean;
  toggle: (checked: boolean) => void;
  labelSemiBold?: boolean;
  labelBold?: boolean;
  labelItalic?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  function getLabelClass() {
    let labelClass = 'label-text pl-2';
    if (props.labelSemiBold) labelClass += ' font-semibold';
    if (props.labelBold) labelClass += ' font-bold';
    if (props.labelItalic) labelClass += ' italic';
    return labelClass;
  }

  return (
    <label className="p-0 label cursor-pointer max-w-fit">
      <input
        type="checkbox"
        checked={props.checked}
        className="checkbox"
        onChange={() => props.toggle(!props.checked)}
      />
      <span className={getLabelClass()}>{props.label}</span>
    </label>
  );
};