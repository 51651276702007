import { useEffect, useRef } from 'react';

export function useInterval(callback: Function, delay: number, immediate?: boolean) {
  const savedCallback: any = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    immediate && savedCallback.current();

    function tick() {
      savedCallback.current && savedCallback.current();
    }
    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, []);
}
