import React, { useRef, useEffect } from 'react';

interface Tab {
  id: string;
  text: string;
  icon: React.ComponentType<any>;
}

interface TabsProps {
  items: Tab[];
  selectedId: string;
  onSelect: (id: string) => void;
}

export const Tabs: React.FC<TabsProps> = (props) => {
  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const selectedTab = tabsRef.current?.querySelector(`[data-id="${props.selectedId}"]`);
    if (selectedTab) {
      selectedTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [props.selectedId]);

  return (
    <div ref={tabsRef} className="tabs tabs-boxed whitespace-nowrap">
      <div className="overflow-x-auto">
        <div className="inline-flex">
          {props.items.map((item) => (
            <div
              key={item.id}
              data-id={item.id}
              className={`text-sm flex-shrink-0 px-4 py-1.5 flex items-center ${item.id === props.selectedId ? 'bg-primary text-white' : 'text-gray-500'
                } rounded-lg cursor-pointer`}
              onClick={() => props.onSelect(item.id)}
            >
              <div>
                <item.icon className="mr-1" width={18} />
              </div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};