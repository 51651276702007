
import { useEffect, useState } from 'react';
import HandicraftAccept from '../assets/handicrafts/undraw_handcrafts_accept.svg';
import HandicraftAddFiles from '../assets/handicrafts/undraw_handcrafts_add_files.svg';
import HandicraftAnalytics from '../assets/handicrafts/undraw_handcrafts_analytics.svg';
import HandicraftBook from '../assets/handicrafts/undraw_handcrafts_book.svg';
import HandicraftComputer from '../assets/handicrafts/undraw_handcrafts_computer.svg';
import HandicraftGrowth from '../assets/handicrafts/undraw_handcrafts_growth.svg';
import HandicraftLocation from '../assets/handicrafts/undraw_handcrafts_location.svg';
import HandicraftLocationPin from '../assets/handicrafts/undraw_handcrafts_location_pin.svg';
import HandicraftLock from '../assets/handicrafts/undraw_handcrafts_lock.svg';
import HandicraftMan from '../assets/handicrafts/undraw_handcrafts_man.svg';
import HandicraftNumberOne from '../assets/handicrafts/undraw_handcrafts_number_one.svg';
import HandicraftSearch from '../assets/handicrafts/undraw_handcrafts_search.svg';
import HandicraftSearchFiles from '../assets/handicrafts/undraw_handcrafts_search_files.svg';
import HandicraftSignal from '../assets/handicrafts/undraw_handcrafts_signal.svg';
import HandicraftSmartphone from '../assets/handicrafts/undraw_handcrafts_smartphone.svg';
import HandicraftToDo from '../assets/handicrafts/undraw_handcrafts_to_do.svg';
import HandicraftTravel from '../assets/handicrafts/undraw_handcrafts_travel.svg';
import HandicraftUser from '../assets/handicrafts/undraw_handcrafts_user.svg';
import HandicraftWoman from '../assets/handicrafts/undraw_handcrafts_woman.svg';

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const BackgroundHandicrafts: React.FC = () => {
  const [handicraftInstances, setHandicraftInstances] = useState<any[]>([])

  function buildHandicrafts() {
    const handicraftOptions = [
      HandicraftAccept,
      HandicraftAddFiles,
      HandicraftAnalytics,
      HandicraftBook,
      HandicraftComputer,
      HandicraftGrowth,
      HandicraftLocation,
      HandicraftLocationPin,
      HandicraftLock,
      HandicraftMan,
      HandicraftNumberOne,
      HandicraftSearch,
      HandicraftSearchFiles,
      HandicraftSignal,
      HandicraftSmartphone,
      HandicraftToDo,
      HandicraftTravel,
      HandicraftUser,
      HandicraftWoman
    ]

    const handicraftInstancesLocal = []

    for (let i=0; i < 40; i ++) {
      const optionIndex = getRandomInt(0, handicraftOptions.length -1)
      handicraftInstancesLocal.push({
        key: i.toString(),
        top: Math.round(Math.random() * screen.height),
        left: Math.round(Math.random() * screen.width),
        size: 64 + Math.random() * 64,
        image: handicraftOptions[optionIndex],
        angle: Math.round(Math.random() * 360),
      })
    }

    setHandicraftInstances(handicraftInstancesLocal);
  }
  
  useEffect(() => buildHandicrafts(), [])
  return (
    <div className="absolute top-0 left-0 right-0 bottom-0" style={{opacity: 0.15, overflow: 'hidden'}}>
      {handicraftInstances.map((h) => {
        return <img src={h.image} key={h.key} className='absolute' style={{
          top: h.top + "px",
          left: h.left + "px",
          width: h.size + "px",
          height: h.size + "px",
          transform: `rotate(${h.angle}deg)`,
          userSelect: 'none'
        }}></img>
      })}
    </div>
  )
}
