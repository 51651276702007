import { TTSchema, TTSchemaInstance } from "./schema.js";

export interface IBeanDiff {
  uuid: string;
  table: string;
  fields: any;
}

export const schemaBeanDiff: TTSchema<IBeanDiff> = {
  uuid: {
    type: String,
    primary: true,
    indexed: true,
  },
  table: {
    type: String,
  },
  fields: {
    type: Object,
  }
}

export const schemaInstanceBeanDiff = new TTSchemaInstance<IBeanDiff>('_beanDiffs', schemaBeanDiff, 0);
