import React from 'react';

interface ScreenHomeOnboardingProps { }

export const ScreenHomeOnboarding: React.FC<ScreenHomeOnboardingProps> = (props) => {

  return (
    <div className='p-4 h-full w-full flex flex-col'>
      Welcome to Vigil Secure. <br/>
      You have not joined or created an organization. <br/>
      To do so navigate to your profile / organizations here : <a href='/profile/organizations' className='link'>Profile Organizations</a>
    </div >
  );
};
