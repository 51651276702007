import { CuuidV2 } from "./cuuid_v2";

function uuidConvertV1ToV2(uuid: string) {
  // UUID V1: _NtCjIrGPmEpp0RuX:6a
  if (uuid.startsWith('_')) {
    return CuuidV2.fixUuid(uuid);
  }
  return null;
}

function uuidConvertV2ToV3(uuid: string) {
  // UUID V2: 1692765009457_3TDKdqB_fi
  if (uuid.split('_').length >= 2) {
    let part0 = uuid.split('_')[0];
    let part1 = uuid.split('_')[1];
    if (part0.length == 13) part0 = '0' + part0;
    return part0 + '_' + part1;
  }
  return null;
}

export function uuidAllDecode(uuid: string) {
  // UUID V3: 01692765009457_3TDKdqB
  return uuidConvertV2ToV3(uuidConvertV1ToV2(uuid) ?? uuid) ?? uuid;
}