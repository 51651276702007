import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";
import { NotificationLogStatus } from "./main";

export interface INotificationLog extends IBaseSyncable {
  uuidNotification: string;
  uuidUser: string;
  type: NotificationLogStatus;
}

export const schemaNotificationLogs: TTSchema<INotificationLog> = {
  ...schemaBaseSyncable,
  uuidNotification: {
    type: String,
    indexed: true,
  },
  uuidUser: {
    type: String,
    indexed: true,
  },
  type: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceNotificationLogs = new TTSchemaSyncableInstance<INotificationLog>('notification_logs', schemaNotificationLogs, 0);
