import React, { useContext } from 'react';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Preferences } from '../../preference_keys';
import { ContextTheme } from '../../providers/provider_theme';

/* Theme Previews */
// TODO: Perhaps we can make this a single component
const ThemePreviewLight =
`
<svg width="228" height="120" viewBox="0 0 228 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H228V120H0V0Z" fill="white" />
<rect x="29" y="54" width="144" height="53" rx="2" fill="#F6F8FA" />
<rect x="184" y="54" width="32" height="36" rx="2" fill="#F6F8FA" />
<rect opacity="0.3" x="29" y="59" width="144" height="12" fill="#34D058" />
<path opacity="0.6" d="M0 0H228V24H0V0Z" fill="#F6F8FA" />
<rect x="13" y="9" width="32" height="6" rx="3" fill="#D1D5DA" />
<rect x="29" y="36" width="48" height="6" rx="3" fill="#D1D5DA" />
<rect x="34" y="62" width="64" height="6" rx="3" fill="#34D058" />
<rect x="210" y="36" width="6" height="6" rx="1" fill="#D73A49" />
<rect x="202" y="36" width="6" height="6" rx="1" fill="#34D058" />
<rect x="53" y="9" width="32" height="6" rx="3" fill="#D1D5DA" />
<rect x="93" y="9" width="32" height="6" rx="3" fill="#D1D5DA" />
</svg>
`

const ThemePreviewDark =
`
<svg width="228" height="120" viewBox="0 0 228 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H228V120H0V0Z" fill="#161B22" />
<rect x="29" y="54" width="144" height="53" rx="2" fill="#30363D" />
<rect x="184" y="54" width="32" height="36" rx="2" fill="#30363D" />
<rect opacity="0.3" x="29" y="59" width="144" height="12" fill="#2EA043" />
<path opacity="0.6" d="M0 0H228V23H0V0Z" fill="#484F58" />
<rect x="13" y="9" width="32" height="6" rx="3" fill="#8B949E" />
<rect x="29" y="36" width="48" height="6" rx="3" fill="#6E7681" />
<rect x="34" y="62" width="64" height="6" rx="3" fill="#3FB950" />
<rect x="210" y="36" width="6" height="6" rx="1" fill="#DA3633" />
<rect x="202" y="36" width="6" height="6" rx="1" fill="#3FB950" />
<rect x="53" y="9" width="32" height="6" rx="3" fill="#8B949E" />
<rect x="93" y="9" width="32" height="6" rx="3" fill="#8B949E" />
</svg>
`

interface ScreenProfileAppearanceProps { }

export const ScreenProfileAppearance: React.FC<ScreenProfileAppearanceProps> = (props) => {
  const themes = [
    {
      id: 'light',
      name: 'Light',
      preview: ThemePreviewLight
    },
    {
      id: 'dark',
      name: 'Dark',
      preview: ThemePreviewDark
    }
  ]

  const vigil = useContext(ContextVigilClient);
  const selectedTheme = useContext(ContextTheme);

  function getSelected(theme: any) {
    return theme.id == selectedTheme
  }

  function select(theme: any) {
    vigil.setPreference(Preferences.SELECTED_THEME, theme.id);
  }

  return (
    <div className='py-4'>
      <div className='rounded-t-xl bg-base-300 p-2'>
        <div className='flex items-center'>
          <div className='font-semibold'>Theme</div>
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2'>
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 m-2 mt-6'>
          {
            themes.map((theme) => {
              return <div key={theme.id} className={`border border-base-300 rounded-2xl overflow-hidden cursor-pointer ${getSelected(theme) ? 'border-primary border-2' : ''}`} onClick={() => select(theme)}>
                <img src={'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(theme.preview)} className='w-full'></img>
                <div className='p-2'>
                  {theme.name}
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  );
};

