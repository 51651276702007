import { useRouteError } from "react-router-dom";
import { StatusAlert } from "./status_alert";

interface FullPageErrorProps {
  error?: string
}

export const FullPageError: React.FC<FullPageErrorProps> = (props) => {
  const error = props.error || useRouteError() || 'Something went wrong and no error message was set';
  return (
    <div className="flex min-h-full items-center justify-center">
      <div>
        <StatusAlert message={error as any} type='alert-error'/>
      </div>
    </div>
  )
}
