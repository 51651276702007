import { TTDefinition, TTFunctionError, TTFunctionErrorJSON } from 'tt-coms';

import { schemaInstanceIdentity } from './bean_identity.js';
import { schemaInstanceAuthChallenge } from './bean_auth_challenge.js';
import { schemaInstanceUser } from './bean_user.js';
import { schemaInstanceUserSecrets } from './bean_user_secrets.js';
import { schemaInstanceUserOrganizationInvite } from './bean_user_organization_invite.js';
import { schemaInstanceOrganization } from './bean_organization.js';
import { schemaInstanceOrganizationRole } from './bean_organization_role.js';
import { schemaInstanceOrganizationRoleUser } from './bean_organization_role_user.js';
import { schemaInstanceUserOrganization } from './bean_user_organization.js';
import { schemaInstanceSite } from './bean_site.js';
import { schemaInstanceBeacon } from './bean_beacon.js';
import { schemaInstanceSiteOrganization } from './bean_site_organization.js';
import { schemaInstanceBeaconOrganization } from './bean_beacon_organization.js';
import { schemaInstanceBeaconSite } from './bean_beacon_site.js';
import { schemaInstanceEvent } from './bean_event.js';
import { schemaInstanceEventInstance } from './bean_event_instance.js';
import { schemaInstanceEventOrganization } from './bean_event_organization.js';
import { schemaInstanceScript } from './bean_script.js';
import { schemaInstanceChart } from './bean_chart.js';
import { schemaInstanceDevice } from './bean_device.js';
import { schemaInstanceDeviceOrganization } from './bean_device_organization.js';
import { schemaInstanceDeviceUser } from './bean_device_user.js';
import { schemaInstanceDeviceStateLog } from './bean_device_state_log.js';
import { schemaInstanceDeviceActivityLog } from './bean_device_activity_log.js';
import { schemaInstanceNotification } from './bean_notification.js';
import { schemaInstanceNotificationLogs } from './bean_notification_log.js';
import { schemaInstanceDashSession } from './bean_dash_session.js';
import { schemaInstanceDashStateLog } from './bean_dash_state_log.js';
import { schemaInstanceDashActivityLog } from './bean_dash_activity_log.js';
import { schemaInstanceEventLog } from './bean_event_log.js';
import { schemaInstanceAction } from './bean_action.js';
import { schemaInstanceReport } from './bean_report.js';
import * as Permissions from "./permissions.js";
import { VigilFunctions } from './functions.js';

export { type IVigilToken, tokenDecoder, tokenValidator } from './token.js'
// TODO: We can maybe do this part better later, to avoid having to import all the beans separately
export { type IIdentity } from './bean_identity.js';
export { type IAuthChallenge } from './bean_auth_challenge.js';
export { type IUser } from './bean_user.js';
export { type IUserSecrets } from './bean_user_secrets.js';
export { type IUserOrganizationInvite } from './bean_user_organization_invite.js';
export { type IOrganization } from './bean_organization.js';
export { type IOrganizationRole } from './bean_organization_role.js';
export { type IOrganizationRoleUser } from './bean_organization_role_user.js';
export { type IUserOrganization } from './bean_user_organization.js';
export { type ISite } from './bean_site.js';
export { type ISiteOrganization } from './bean_site_organization.js';
export { type IBeacon } from './bean_beacon.js';
export { type IBeaconSite } from './bean_beacon_site.js';
export { type IBeaconOrganization } from './bean_beacon_organization.js';
export { type IEvent } from './bean_event.js';
export { type IEventInstance } from './bean_event_instance.js';
export { type IEventOrganization } from './bean_event_organization.js';
export { type IScript } from './bean_script.js';
export { type IChart } from './bean_chart.js';
export { type IDevice } from './bean_device.js';
export { type IDeviceOrganization } from './bean_device_organization.js';
export { type IDeviceStateLog } from './bean_device_state_log.js';
export { type IDeviceActivityLog } from './bean_device_activity_log.js';
export { type IDeviceUser, DeviceUserStatus } from './bean_device_user.js';
export { type INotification } from './bean_notification.js';
export { type INotificationLog } from './bean_notification_log.js';
export { type IDashSession } from './bean_dash_session.js';
export { type IDashStateLog } from './bean_dash_state_log.js';
export { type IDashActivityLog } from './bean_dash_activity_log.js';
export { type IEventInstanceLog } from './bean_event_log.js';
export { type IAction } from './bean_action.js';
export { type IReport } from './bean_report.js';
export { type VigilChat } from './type_chat.js';

export { BeaconType } from './bean_beacon.js';
export { type BaseEventTrigger } from './bean_event.js';

export { schemaInstanceIdentity };
export { schemaInstanceAuthChallenge };
export { schemaInstanceUser };
export { schemaInstanceUserSecrets };
export { schemaInstanceOrganization };
export { schemaInstanceOrganizationRole };
export { schemaInstanceOrganizationRoleUser };
export { schemaInstanceUserOrganization };
export { schemaInstanceUserOrganizationInvite };
export { schemaInstanceSite };
export { schemaInstanceSiteOrganization };
export { schemaInstanceBeacon };
export { schemaInstanceBeaconSite };
export { schemaInstanceBeaconOrganization };
export { schemaInstanceEvent };
export { schemaInstanceEventInstance };
export { schemaInstanceEventOrganization };
export { schemaInstanceScript };
export { schemaInstanceChart };
export { schemaInstanceDevice };
export { schemaInstanceDeviceOrganization };
export { schemaInstanceDeviceUser };
export { schemaInstanceDeviceStateLog };
export { schemaInstanceDeviceActivityLog };
export { schemaInstanceNotification };
export { schemaInstanceNotificationLogs };
export { schemaInstanceDashSession };
export { schemaInstanceDashStateLog };
export { schemaInstanceDashActivityLog };
export { schemaInstanceEventLog };
export { schemaInstanceAction };
export { schemaInstanceReport };

export class VigilError extends TTFunctionError {
  public toJSON(): TTFunctionErrorJSON {
    return {
      message: this.message
    }
  }
}

export const VigilDefinition = new TTDefinition('vigil', [
  schemaInstanceIdentity,
  schemaInstanceAuthChallenge,
  schemaInstanceUser,
  schemaInstanceUserSecrets,
  schemaInstanceOrganization,
  schemaInstanceOrganizationRole,
  schemaInstanceOrganizationRoleUser,
  schemaInstanceUserOrganization,
  schemaInstanceUserOrganizationInvite,
  schemaInstanceSite,
  schemaInstanceSiteOrganization,
  schemaInstanceBeacon,
  schemaInstanceBeaconOrganization,
  schemaInstanceBeaconSite,
  schemaInstanceEvent,
  schemaInstanceEventInstance,
  schemaInstanceEventOrganization,
  schemaInstanceScript,
  schemaInstanceChart,
  schemaInstanceDevice,
  schemaInstanceDeviceOrganization,
  schemaInstanceDeviceUser,
  schemaInstanceDeviceStateLog,
  schemaInstanceDeviceActivityLog,
  schemaInstanceNotification,
  schemaInstanceNotificationLogs,
  schemaInstanceDashSession,
  schemaInstanceDashStateLog,
  schemaInstanceDashActivityLog,
  schemaInstanceEventLog,
  schemaInstanceAction,
  schemaInstanceReport
]);

// Permissions export
export { Permissions };

// Functions export
export * from './functions.js';

// Notifications export
export * from './bean_notification.js'

// Charts export
export * from './bean_chart.js'

// Events export
export * from './bean_event_log.js'

// Event export
export * from './bean_event.js'

// Dash Activity Log export
export * from './bean_dash_activity_log.js'

// Permissions export
export * from './permissions.js'

// Permissions export
export * from './bean_device.js'
