import { createWSClient, createTRPCProxyClient, loggerLink, wsLink } from '@trpc/client';
import { TTuuid } from 'tt-uuid';
import { type TTComsRouterType } from 'tt-coms-server';

export class TTComsApi {  
  private readonly connectionUuid: string = TTuuid.getCuuid();
  public client;
  constructor(url: string) {
    const wsClient = createWSClient({
      url: url + '?connectionUuid=' + encodeURIComponent(this.connectionUuid),
      WebSocket
    });
    
    this.client = createTRPCProxyClient<TTComsRouterType>({
      links: [
        wsLink({ client: wsClient }),
      ],
    });
  }
}
