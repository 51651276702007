import React, { ReactNode } from 'react';
import { StatusLoading } from './status_loading';

export interface InputButtonProps {
  className?: string,
  text?: string,
  before?: ReactNode,
  after?: ReactNode,
  type?: "btn-default" | "btn-primary" | "btn-secondary" | "btn-accent" | "btn-ghost" | "btn-link" | "btn-info" | "btn-success" | "btn-warning" | "btn-error",
  size?: "btn-sm" | "btn-md" | "btn-lg",
  disabled?: boolean,
  loading?: boolean,
  onClick?: () => void,
  styles?: string[],
}

export const InputButton: React.FC<InputButtonProps> = (props) => {
  function getContent() {
    if (props.loading) {
      return <StatusLoading />
    }
    return (
      <span className='flex items-center'>
        {props.before}
        {props.text}
        {props.after}
      </span>
    )
  }
  function getClassName() {
    return `btn ${props.type || 'btn-default'} ${props.size || ''} ${props.styles?.join(' ')} w-full`
  }
  return (
    <div className={props.className}>
      <button type="button" className={getClassName()} disabled={props.disabled || props.loading} onClick={props.onClick}>
        {getContent()}
      </button>
    </div>
  );
};
