import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";
import { ICreator } from "./type_creator";

export enum NotificationLogStatus {
  Received = 'received',
  Acknowledged = 'acknowledged',
  Expired = 'expired',
  Dismissed = 'dismissed',
}

export enum NotificationId {
  Panic = 'panic',
  LateStart = 'lateStart',
  UnsuccessfulPatrol = 'unsuccessfulPatrol',
}

export interface INotificationPanic {
  userUuid: string,
  userFirstName: string,
  userLastName: string,
  deviceUuid?: string,
  deviceSerial?: string,
  siteUuid?: string,
  siteName?: string,
  lat: number,
  lng: number,
  alt: number,
  acc: number,
}

export interface INotificationLateStart {
  siteUuid: string,
  siteName: string,
}

export interface INotificationUnsuccessfulPatrol {
  uuidSite: string,
  siteName: string,
  percentageCompleted: number,
  percentageRequired: number,
}

export type INotification = IBaseSyncable & { uuidOrganization: string, creator: ICreator } &
  (
    | { id: NotificationId.Panic, data: INotificationPanic }
    | { id: NotificationId.LateStart, data: INotificationLateStart }
    | { id: NotificationId.UnsuccessfulPatrol, data: INotificationUnsuccessfulPatrol }
  )

export const schemaNotification: TTSchema<INotification> = {
  ...schemaBaseSyncable,
  uuidOrganization: {
    type: String,
    indexed: true,
  },
  creator: {
    type: Object,
    indexed: true,
  },
  id: {
    type: String,
    indexed: true,
  },
  data: {
    type: Object,
    indexed: false,
  },
}

export const schemaInstanceNotification = new TTSchemaSyncableInstance<INotification>('notification', schemaNotification, 0);
