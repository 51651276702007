import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { Preferences } from "../preference_keys";
import { ContextVigilClient } from "./provider_vigil_client";
import { FullPageLoader } from "../components/full_page_loader";
import { FullPageError } from "../components/full_page_error";

export const ContextTheme = createContext(null as unknown as string);

interface ProviderThemeProps {
  children: ReactNode
}
export const ProviderTheme: React.FC<ProviderThemeProps> = (props: ProviderThemeProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);

  /* State */
  const [stateTheme, setTheme] = useState(null as string | null);
  const [stateThemeLoading, setThemeLoading] = useState(true);
  const [stateThemeError, setThemeError] = useState('');

  /* Reactivity */
  const subscriptionThemePref = useRef(null as any);
  useEffect(() => {
    subscriptionThemePref.current?.unsubscribe();

    (async () => {
      try {
        // Set loading
        setThemeLoading(true);
        setThemeError('');

        subscriptionThemePref.current = await vigil.getPreferenceReactive(Preferences.SELECTED_THEME, (value) => {
          value = value as string || 'light';
          console.log('Setting theme: ' + value)
          setTheme(value);
          setThemeLoading(false);
        });
      }
      catch (error: any) {
        setThemeError(error.message);
        setThemeLoading(false);
      }
    })();

    return () => { subscriptionThemePref.current?.unsubscribe() }
  }, []);

  if (stateThemeLoading) {
    return <FullPageLoader />
  }

  if (stateThemeError || !stateTheme) {
    return <FullPageError error={stateThemeError} />
  }

  return (
    <div className="h-full" data-theme={stateTheme}>
      <ContextTheme.Provider value={stateTheme}>
        {props.children}
      </ContextTheme.Provider>
    </div>
  )
}
