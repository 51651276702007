import React from 'react';

interface TextDescriptionProps {
  children?: React.ReactNode;
}

export const TextDescription: React.FC<TextDescriptionProps> = (props) => {
  return (
    <div className='text-lg'>
      {props.children || ''}
    </div>
  );
};
