import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IOrganizationRole, IUser, Permissions } from 'vigil-datamodel';
import { FullPageLoader } from '../../components/full_page_loader';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../routes';
import { InputButton } from '../../components/input_button';
import { IconBrokenLinkOutline, IconChevronRightSolid, IconLinkSolid, IconPencilSquareSolid, IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { TTuuid } from 'tt-uuid';
import { useModal } from '../../hooks/hook_modal';
import { StatusAlert } from '../../components/status_alert';
import { ContextOrganization } from '../../providers/provider_organization';
import { ModalUserDelete } from './func_user/modal_user_delete';
import { ModalUserRolesCreate } from './func_role_user/modal_user_roles_create';
import { ModalUserRolesDelete } from './func_role_user/modal_user_roles_delete';
import { ModalRoleCreate } from './func_role/modal_role_create';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';

interface ScreenHomeUserProps { }

export const ScreenHomeUser: React.FC<ScreenHomeUserProps> = (props) => {
  /* State */
  const navigate = useNavigate()
  const contextOrganization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);

  const [stateError, setError] = useState<string>('');

  const [stateUserLoading, setUserLoading] = useState(true);
  const [stateUser, setUser] = useState<IUser>();

  const [stateRolesLoading, setRolesLoading] = useState(true);
  const [stateRoles, setRoles] = useState<IOrganizationRole[]>();

  const [stateSelectedRole, setSelectedRole] = useState<IOrganizationRole>();

  const { isOpen: isOpenModalUpdateUser, toggle: toggleModalUpdateUser } = useModal();
  const { isOpen: isOpenModalDeleteUser, toggle: toggleModalDeleteUser } = useModal();
  const { isOpen: isOpenModalCreateRole, toggle: toggleModalCreateRole } = useModal();
  const { isOpen: isOpenModalDeleteUserRoles, toggle: toggleModalDeleteUserRoles } = useModal();
  const { isOpen: isOpenModalCreateUserRoles, toggle: toggleModalCreateUserRoles } = useModal();

  const params = useParams();
  const uuidUser = params['uuid'] || '';

  useEffect(() => { fetchUser(uuidUser); fetchRoles(uuidUser); }, [uuidUser]);

  async function fetchUser(uuid: string) {
    try {
      setUserLoading(true);
      const user = await vigil.functions.userFindOne({ filter: { uuid } });
      setUser(user);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setUserLoading(false);
    }
  }

  async function fetchRoles(uuidUser: string) {
    if (!contextOrganization) return;
    try {
      setRolesLoading(true);
      const userRoles = await vigil.functions.organizationRoleUserFindMany({ filter: { uuidUser: uuidUser, uuidOrganization: contextOrganization.uuid } });
      const uuidRoles = userRoles.map(userRole => userRole.uuidRole);
      const roles = await vigil.functions.roleFindMany({ filter: { uuid: { $in: uuidRoles } } });
      setRoles(roles);
      setRolesLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setRolesLoading(false);
    }
  }

  function toggleSelectedRole(role: IOrganizationRole) {
    if (stateSelectedRole == role) {
      setSelectedRole(undefined);
    } else {
      setSelectedRole(role);
    }
  }

  async function linkRoleToUser(role: IOrganizationRole) {
    if (!uuidUser) return;
    if (!contextOrganization) return;
    try {
      await vigil.functions.userRolesLinkCreate({
        uuidOrganization: contextOrganization.uuid,
        uuidRoles: [role.uuid],
        uuidUser: uuidUser,
      })
      await fetchRoles(uuidUser);
    } catch (error: any) {
      setError(error.message);
    }
  }

  if (stateUserLoading || !stateUser || stateRolesLoading) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full flex flex-col h-full'>
      {/* <ModalUpdateRole isOpen={isOpenModalUpdateRole} toggle={toggleModalUpdateRole} onSubmit={async () => await fetchRole(id)} uuidRole={id} /> */}
      <ModalUserDelete isOpen={isOpenModalDeleteUser} toggle={toggleModalDeleteUser} users={[stateUser]} onSubmit={async () => navigate(ROUTES.ROUTE_HOME_USERS)} />
      <ModalRoleCreate isOpen={isOpenModalCreateRole} toggle={toggleModalCreateRole} onSubmit={async (user) => await linkRoleToUser(user)} />
      <ModalUserRolesCreate isOpen={isOpenModalCreateUserRoles} toggle={toggleModalCreateUserRoles} uuidUser={uuidUser} onSubmit={async () => await fetchRoles(uuidUser)} />
      <ModalUserRolesDelete isOpen={isOpenModalDeleteUserRoles} toggle={toggleModalDeleteUserRoles} uuidUser={uuidUser} uuidRoles={stateSelectedRole ? [stateSelectedRole.uuid] : []} onSubmit={async () => await fetchRoles(uuidUser)} />

      <Breadcrumbs crumbs={[{ id: 'roles', onClick: () => navigate(ROUTES.ROUTE_HOME_USERS), text: 'Users' }, { id: uuidUser, onClick: () => { }, text: stateUser.firstName + ' ' + stateUser.lastName }]} />

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <div className='flex items-center'>
        <div className='flex flex-grow items-end'>
          <div className="flex items-center mr-6">
            <span className='font-semibold'>Date Created:</span>
            <span className="text-sm ml-2">{TTuuid.decodeCuuid(stateUser.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateUser.uuid).time.toLocaleTimeString()}</span>
          </div>
          <div className="flex items-center mr-6">
            <span className='font-semibold'>Last Updated:</span>
            <span className="text-sm ml-2">{TTuuid.decodeCuuid(stateUser.changeStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateUser.changeStamp).time.toLocaleTimeString()}</span>
          </div>
        </div>

        {/* <div className='mx-4'>
          <InputButton text='Edit Role' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={false} onClick={() => toggleModalUpdateRole()} />
        </div> */}
        <InputButton text='Delete User' before={<IconTrashSolid className='h-5 mr-2' />} type='btn-error' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.RUser)} onClick={() => toggleModalDeleteUser()} />
      </div>

      <div className='flex flex-grow py-2'>
        <div className="card w-80 mr-2 z-[1]">
          <div className='rounded-t-xl bg-base-300'>
            <div className='flex justify-center font-bold mt-2'>Linked Roles</div>
            <div className='p-2 flex flex-row'>
              <InputButton text='Create Role' before={<IconPlusSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RRole)} onClick={() => toggleModalCreateRole()} />
              <div className='mx-2'></div>
              <InputButton text='Link Role' before={<IconLinkSolid className='h-5 mr-2' />} type='btn-secondary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RUserRoleLink)} onClick={() => toggleModalCreateUserRoles()} />
            </div>
          </div>

          <div className='rounded-b-xl bg-base-200 p-2 h-full'>
            {stateRoles && stateRoles.map((role) => {
              const decodedUuid = TTuuid.decodeCuuid(role.uuid);
              const dateCreated = decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString();
              const decodedChangeStamp = TTuuid.decodeCuuid(role.changeStamp);
              const dateUpdated = decodedChangeStamp.time.toLocaleDateString() + " " + decodedChangeStamp.time.toLocaleTimeString();
              return (
                <div key={role.uuid} onClick={() => toggleSelectedRole(role)} className={`card dropdown dropdown-right w-full hover:cursor-pointer hover:bg-base-200 flex flex-row items-center p-2 drop-shadow-xl mb-2 ${stateSelectedRole == role ? 'bg-base-200 dropdown-open' : 'bg-base-100 dropdown-close'}`}>
                  <div className='flex flex-col flex-grow text-left'>
                    <span className='text-md font-semibold'>{role.name}</span>
                  </div>
                  <IconChevronRightSolid className={`h-5 transform transition-transform duration-300 ${stateSelectedRole == role ? 'rotate-180' : ''}`} />

                  <div onClick={(event) => event.stopPropagation()} className={`cursor-auto ml-3 p-2 shadow dropdown-content bg-base-100 rounded-box w-64 outline outline-1 outline-neutral-content ${stateSelectedRole == role ? '' : 'hidden'}`}>
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1" onClick={() => toggleSelectedRole(role)}>✕</button>
                    <div className='text-lg font-semibold mb-2 text-center'>{role.name}</div>
                    <div className='text-left'>
                      <div className='text-xs'>UUID: {role.uuid}</div>
                      <div className='text-xs'>Permission String: {role.permissionString}</div>
                      <div className='text-xs'>Date created: {dateCreated}</div>
                      <div className='text-xs mb-4'>Last updated: {dateUpdated}</div>
                      <InputButton text='Edit Role' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={false} onClick={() => navigate(ROUTES.ROUTE_HOME_ROLE.replace(':uuid', role.uuid))} />
                      <div className='mt-2' />
                      <InputButton text='Unlink Role' before={<IconBrokenLinkOutline className='h-4 mr-2 fill-warning-content' />} type='btn-warning' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.RUserRoleLink)} onClick={() => toggleModalDeleteUserRoles()} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {/* Create the UI to represent all the user info */}
        <div className="card flex-grow">
          <div className='rounded-t-xl bg-base-300 p-2 flex flex-row'>
            <div className='flex flex-grow items-center'>
              <span className='font-semibold'>User Info</span>
            </div>
          </div>

          <div className='rounded-b-xl bg-base-200 p-2 h-full'>
            <div className="flex items-center mb-1">
              <div className="text-sm font-semibold pl-2">ID:</div>
              <div className="text-sm ml-2">{stateUser.id}</div>
            </div>
            <div className="flex items-center mb-1">
              <div className="text-sm font-semibold pl-2">First Name:</div>
              <div className="text-sm ml-2">{stateUser.firstName}</div>
            </div>
            <div className="flex items-center mb-1">
              <div className="text-sm font-semibold pl-2">Last Name:</div>
              <div className="text-sm ml-2">{stateUser.lastName}</div>
            </div>
            <div className="flex items-center mb-1">
              <div className="text-sm font-semibold pl-2">Email:</div>
              <div className="text-sm ml-2">{stateUser.email}</div>
            </div>
            <div className="flex items-center mb-1">
              <div className="text-sm font-semibold pl-2">Mobile:</div>
              <div className="text-sm ml-2">{stateUser.mobile}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
