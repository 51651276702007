
import { Dexie, Table } from 'dexie';
import { TTBeanTarget, TTDefinition, TTSchemaSyncableInstance } from 'tt-coms';
import { schemaInstanceCheckpoint, schemaInstanceKeyStore } from 'tt-coms';
import { TTSchemaInstance } from 'tt-coms';
import { IBeanDiff, schemaInstanceBeanDiff } from 'tt-coms';
import { TTCollectionClient } from './db_collection.js';
import { TTComsDBDiffManager } from './db_diff_manager.js';
import { TTComsDBQueryManager } from './db_query_manager.js';
import { TTComsApi } from './api.js';
import { TTuuid } from 'tt-uuid';

function getDexieSetupFromDefinition(schemas: TTSchemaInstance<any>[]): { schema: any, version: number } {
  // Set up versioned schema
  let maxVersion = 1;
  const schema = {} as any;
  for (const bean of schemas) {
    const indexedFields: Array<string> = [];
    for (const field of bean.getFields()) {
      if (field.primary) {
        indexedFields.push('&' + field.name);
        continue;
      }
      if (field.indexed) {
        indexedFields.push(field.name);
      }
    }
    schema[bean.name] = indexedFields.join(', ');
  }

  return {
    schema: schema,
    version: maxVersion
  };
}

const SERVER_CHANGE_MARKER = '__tt_server_change__'


export class TTComsClientDB extends Dexie {
  public readonly syncableTableNames: string[];
  private collections: Record<string, TTCollectionClient<any>> = {};
  private diffManager: TTComsDBDiffManager;
  private queryManager: TTComsDBQueryManager;
  private api: TTComsApi;

  constructor(definition: TTDefinition, api: TTComsApi) {
    // Get shim (optional)
    const windowAny =  (window as any )
    const shim = windowAny.shim_indexeddb ? windowAny.shim_indexeddb || window : window;

    // Init dexie, using the shims if they exist
    super(definition.name, {
      indexedDB: shim.indexedDB,
      IDBKeyRange: shim.IDBKeyRange,
    });

    // Save api
    this.api = api;

    // Create diff manager
    this.diffManager = new TTComsDBDiffManager(this);

    // Create query manager
    this.queryManager = new TTComsDBQueryManager();

    // Apply schema to dexie
    const schemasClient = definition.schemas.filter((schema) => { return schema.targets.includes(TTBeanTarget.Client) })
    const schemas = [...schemasClient, schemaInstanceCheckpoint, schemaInstanceKeyStore, schemaInstanceBeanDiff]
    const dexieSetup = getDexieSetupFromDefinition(schemas);
    this.version(2).stores(dexieSetup.schema);

    // Create collections
    this.syncableTableNames = []; 
    for (const schema of schemas) {
      const dexie = this as any;
      const table = dexie[schema.name] as Table<any, string>;
      this.collections[schema.name] = new TTCollectionClient<any>(table, this.queryManager, this.api);
      if (schema instanceof TTSchemaSyncableInstance) {
        this.syncableTableNames.push(schema.name);
      }
    }

    // Build hooks for diff detection
    const db = this;
    this.use({
      stack: "dbcore",
      name: "tt-coms-syncable",
      create(downlevelDatabase) {
        return {
          ...downlevelDatabase,
          table(tableName) {
            const downlevelTable = downlevelDatabase.table(tableName);
            return {
              ...downlevelTable,
              mutate: async req => {
                const myRequest = { ...req };

                // If we're not syncable do normal
                if (db.syncableTableNames.includes(tableName) === false) {
                  return await downlevelTable.mutate(myRequest);
                }

                // Otherwise
                // Collect a bunch of meta data and crap for later
                const values = (myRequest as any).values;
                const valuesOld: Record<string, any> = {};
                const valuesNew: Record<string, any> = {};
                const valuesServer: Record<string, any> = {};

                if (values) {
                  for (const valueNew of values) {
                    // If this is a change coming from the server
                    if (valueNew[SERVER_CHANGE_MARKER] === true) {
                      delete valueNew[SERVER_CHANGE_MARKER];
                      
                      if (!valueNew.uuid) {
                        throw new Error('Server change detected but no uuid found.')
                      }
                      if (!valueNew.serverStamp) {
                        throw new Error('Server change detected but no server stamp found.')
                      }

                      valuesServer[valueNew.uuid] = valueNew;
                      continue;
                    }

                    if (valueNew.length) {
                      // TODO: Implement support for array values here, so bulkPut can work
                      throw new Error('TTComs does not support array values for now.')
                    }
                    // Make sure there's a uuid and changeStamp
                    if (!valueNew.uuid) {
                      throw new Error('Syncable change detected but no uuid found.')
                    }
                    if (!valueNew.changeStamp) {
                      valueNew.changeStamp = TTuuid.getCuuid();
                    }
                    const collection = db.getCollectionByName(tableName);
                    const table = collection.getTable();
                    const valueOld = await table.where('uuid').equals(valueNew.uuid).first();
                    valuesOld[valueNew.uuid] = valueOld;
                    valuesNew[valueNew.uuid] = valueNew;
                  }
                }

                // Apply mutation
                const response = await downlevelTable.mutate(myRequest);

                // After mutation succeeds, add the diff
                for (const uuid of Object.keys(valuesNew)) {
                  db.diffManager.add(uuid, tableName, valuesOld[uuid], valuesNew[uuid]);
                }

                return response;
              }
            }
          }
        };
      }
    });
  }

  public async start() {
    await this.open();
    await this.diffManager.start();
  }

  public async stop() {
    await this.diffManager.stop();
    this.close();
  }

  public getCollection<T>(schema: TTSchemaInstance<T>): TTCollectionClient<T> {
    return this.getCollectionByName(schema.name) as TTCollectionClient<T>;
  }
  
  public getCollectionByName(name: string): TTCollectionClient<any> {
    if (!this.collections[name]) { throw new Error(`Collection with name ${name} was requested, but not found. Did you forget to register it in the TTDefinition constructor ?`) }
    return this.collections[name] as TTCollectionClient<any>;
  }

  public getDiffManager() {
    return this.diffManager;
  }

  public getQueryManager() {
    return this.queryManager;
  }

  public async clear() {
    for (const collection of Object.values(this.collections)) {
      await collection.getTable().clear()
    }

    await this.delete()
    await this.open();
  }

  public async applyServerChange(beanDiff: IBeanDiff) {
    const incoming = {
      'uuid': beanDiff.uuid,
      ...beanDiff.fields,
      [SERVER_CHANGE_MARKER]: true
    }
    const collection = this.getCollectionByName(beanDiff.table);
    const table = collection.getTable();
    const current = await table.get(beanDiff.uuid);
    if (!current) {
      await table.put(incoming);
      return;
    }
    if (current['changeStamp'] <= incoming['changeStamp']) {
      const merged = {
        ...current,
        ...incoming
      }
      await table.put(merged);
    }
  }
}
