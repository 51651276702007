import React, { useContext, useState } from 'react';
import { InputButton, InputButtonProps } from './input_button';

interface InputButtonAsyncProps extends InputButtonProps {
  asyncAction: () => Promise<void>,
  onError?: (error: Error) => void,
  onSuccess?: () => void,
}

export const InputButtonAsync: React.FC<InputButtonAsyncProps> = (props) => {
  const [stateLoading, setLoading] = useState(false);
  async function onClick() {
    try {
      setLoading(true);
      await props.asyncAction();
      props.onSuccess && props.onSuccess();
    } catch(error) {
      props.onError && props.onError(error as Error);
    } finally {
      setLoading(false);
    }
  }
  return <InputButton {...props} loading={stateLoading} onClick={onClick}/>  
}
