import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

// ActionTrigger
export enum ActionTriggerId {
  Scheduled = 'scheduled',
  Dispatch = 'dispatch',
}

export type BaseActionTrigger = {
  type: ActionTriggerId;
  enabled: boolean;
}

export type ActionTriggerSchedule = BaseActionTrigger & {
  enabled: boolean;
  type: ActionTriggerId.Scheduled;
  cron: string;
}

export type ActionTriggerDispatch = BaseActionTrigger & {
  enabled: boolean;
  type: ActionTriggerId.Dispatch;
}

export type ActionTrigger = ActionTriggerSchedule | ActionTriggerDispatch;

// ActionStep
export enum ActionStepId {
  Email = 'email',
  SMS = 'sms',
  Whatsapp = 'whatsapp',
}

export type BaseActionStep = {
  type: ActionStepId;
  enabled: boolean;
}

export interface ActionStepEmailButton {
  text: string;
  url: string;
}

export type ActionStepEmail = BaseActionStep & {
  enabled: boolean;
  type: ActionStepId.Email,
  toEmails: string[],
  subject: string,
  body: string,
  buttons: ActionStepEmailButton[],
  attachments: string[],
}

export type ActionStepSMS = BaseActionStep & {
  enabled: boolean;
  type: ActionStepId.SMS,
  toNumbers: string[],
  body: string,
}

export type ActionStepWhatsapp = BaseActionStep & {
  enabled: boolean;
  type: ActionStepId.Whatsapp,
  toNumbers: string[],
  body: string,
}

export type ActionStep = ActionStepEmail | ActionStepSMS | ActionStepWhatsapp;

export interface IAction extends IBaseSyncable {
  uuidOrganization: string;
  name: string;
  triggers: ActionTrigger[];
  steps: ActionStep[];
  enabled: boolean;
  lastRunDate?: string;
}

export const schemaAction: TTSchema<IAction> = {
  ...schemaBaseSyncable,
  uuidOrganization: {
    type: String,
    indexed: true,
  },
  name: {
    type: String,
    indexed: true,
  },
  triggers: {
    type: Array<ActionTrigger>,
    indexed: true,
  },
  steps: {
    type: Array<ActionStep>,
    indexed: true,
  },
  enabled: {
    type: Boolean,
    indexed: true,
  },
  lastRunDate: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceAction = new TTSchemaSyncableInstance<IAction>('actions', schemaAction, 0);
