import { VigilClient } from "vigil-client";

function getStartsWith(siteName: string) {
  const upperSiteName = siteName.toUpperCase();
  const nameLength = upperSiteName.length;

  if (nameLength < 4) {
    return upperSiteName + 'X'.repeat(4 - nameLength);
  } else if (nameLength > 4) {
    return upperSiteName[0] + upperSiteName[1] + upperSiteName[Math.ceil(nameLength / 2)] + upperSiteName[nameLength - 1];
  }
  return upperSiteName;
}

export async function getBeaconDefaultName(vigil: VigilClient, uuidOrganization: string, params: ({ uuidSite: string, startWith?: never } | { uuidSite?: never, startWith: string })) {
  let startWith = '';

  if (params.uuidSite) {
    const site = await vigil.functions.siteFindOne({ filter: { uuid: params.uuidSite } });
    startWith = getStartsWith(site.name).toUpperCase();
  } else if (params.startWith) {
    startWith = params.startWith.toUpperCase();
  }

  const beaconOrganization = await vigil.functions.beaconOrganizationFindMany({ filter: { uuidOrganization } });
  const beacons = await vigil.functions.beaconFindMany({ filter: { uuid: { $in: beaconOrganization.map(beaconOrg => beaconOrg.uuidBeacon) }, name: { $regex: `^${startWith}`, $options: "i" } } });

  const largestNum = Math.max(...beacons.map(beacon => {
    const split = beacon.name.split('-');
    const nameNum = parseInt(split[split.length - 1]);
    if (isNaN(nameNum)) return -1;
    return nameNum;
  }));

  if (Infinity === largestNum || -Infinity === largestNum) return `${startWith}-0`;

  return `${startWith}-${largestNum + 1}`;
}
