import React from 'react';
import { IconOutlineCheckCircle, IconOutlineExclamationCircle, IconOutlineExclamationTriangle, IconOutlineInformationCircle } from './icons';

interface StatusAlertProps {
  message: string,
  type: "alert-info" | "alert-success" | "alert-warning" | "alert-error" | "",
  styles?: string[],
}

export const StatusAlert: React.FC<StatusAlertProps> = (props) => {
  function getIcon() {
    const map = {
      'alert-info': <IconOutlineInformationCircle />,
      'alert-success': <IconOutlineCheckCircle />,
      'alert-warning': <IconOutlineExclamationTriangle />,
      'alert-error': <IconOutlineExclamationCircle />,
      '': <div></div>
    }

    return map[props.type];
  }
  return (
    <div className={`alert ${props.type} ${props.styles?.join(' ')}`}>
      <div className='flex items-center'>
        <div className='flex-none w-8 mr-2'> {getIcon()} </div>
        <div className='text-left'>{props.message}</div>
      </div>
    </div>
  );
};
