import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { ContextVigilClient } from "./provider_vigil_client";
import { IOrganization } from "vigil-datamodel";
import { ContextUser } from "./provider_user";
import { Preferences } from "../preference_keys";
import { FullPageLoader } from "../components/full_page_loader";

export const ContextOrganization = createContext(null as IOrganization | null);

interface ProviderOrganizationProps {
  children: ReactNode
}
export const ProviderOrganization: React.FC<ProviderOrganizationProps> = (props: ProviderOrganizationProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);
  const user = useContext(ContextUser);

  /* State */
  const [stateOrganizationSelected, setOrganizationSelected] = useState(null as string | null);
  const [stateOrganizationSelectedLoading, setOrganizationSelectedLoading] = useState(true);
  const [stateOrganization, setOrganization] = useState(null as IOrganization | null);
  const [stateOrganizationLoading, setOrganizationLoading] = useState(true);

  /* Reactivity */
  const subscriptionOrgPref = useRef(null as any);
  useEffect(() => {
    (async () => {
      subscriptionOrgPref.current = await vigil.getPreferenceReactive(Preferences.SELECTED_ORGANIZATION, (value) => {
        console.log('Setting organization selected: ' + value);
        setOrganizationSelected(value as string | null);
        setOrganizationSelectedLoading(false);
      });
    })();

    return () => { subscriptionOrgPref.current?.unsubscribe() }
  }, []);

  useEffect(() => {
    (async () => {
      if (stateOrganizationSelectedLoading) {
        return;
      }
      if (!stateOrganizationSelected) {
        setOrganization(null);
        setOrganizationLoading(false);
        return;
      }

      const organizations = await vigil.functions.organizationFindManyPublic({ filter: { uuid: stateOrganizationSelected } });
      setOrganization(organizations.length > 0 ? organizations[0] : null);
      setOrganizationLoading(false);
    })();
  }, [stateOrganizationSelected, stateOrganizationSelectedLoading]);

  if (stateOrganizationSelectedLoading || stateOrganizationLoading) {
    return <FullPageLoader />
  }

  return (
    <ContextOrganization.Provider value={stateOrganization}>
      {props.children}
    </ContextOrganization.Provider>
  )
}
