import React from 'react';
import { InputBase, InputBaseProps } from './input_base';

export interface OptionSelect {
  label: string;
  value: string | number;
}

interface InputSelectProps extends InputBaseProps {
  value: string | number,
  options: OptionSelect[];
  onChange?: (value: React.ChangeEvent<HTMLSelectElement>) => void
}

export const InputSelect: React.FC<InputSelectProps> = (props) => {
  function styleErrors() {
    return props.errors.length ? 'select-error' : '';
  }

  return (
    <div>
      <InputBase {...props}>
        <select className={`select select-bordered w-full ${styleErrors()}`} value={props.value} onChange={props.onChange}>
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </InputBase>
    </div>
  );
};
