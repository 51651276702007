import { TTComsClient, TTCollectionClient } from "tt-coms-client";
import { BuildTTComFunctions } from "tt-coms-functions-client";
import { VigilFunctions, IOrganization, VigilDefinition, IUser, schemaInstanceUser, schemaInstanceOrganization, tokenDecoder, tokenValidator, IUserOrganization, schemaInstanceUserOrganization, schemaInstanceSite, ISite, ISiteOrganization, schemaInstanceSiteOrganization, IBeaconOrganization, IBeaconSite, schemaInstanceBeaconOrganization, schemaInstanceBeaconSite, IEvent, IEventOrganization, schemaInstanceEvent, schemaInstanceEventOrganization, IScript, schemaInstanceScript, IUserOrganizationInvite, schemaInstanceUserOrganizationInvite, IOrganizationRole, IOrganizationRoleUser, schemaInstanceOrganizationRole, schemaInstanceOrganizationRoleUser, IBeacon, IChart, schemaInstanceChart, schemaInstanceBeacon, IDeviceOrganization, IDevice, IDeviceUser, schemaInstanceDeviceUser, schemaInstanceDevice, schemaInstanceDeviceOrganization, schemaInstanceDeviceStateLog, IDeviceStateLog, INotification, INotificationLog, schemaInstanceNotification, schemaInstanceNotificationLogs, schemaInstanceDashSession, schemaInstanceDashStateLog, IDashActivityLog, schemaInstanceDashActivityLog, IDashSession, IDashStateLog, IDeviceActivityLog, schemaInstanceDeviceActivityLog, IEventInstance, schemaInstanceEventInstance, IEventInstanceLog, schemaInstanceEventLog, VigilContextClient } from "vigil-datamodel";
import { IContext } from "./context";

export type Preference = string | number | boolean | object | null;

export class VigilClient {
  private readonly ttcoms: TTComsClient<typeof VigilDefinition>;
  public readonly functions;

  // Collections
  public readonly collectionUsers: TTCollectionClient<IUser>;
  public readonly collectionOrganizations: TTCollectionClient<IOrganization>;
  public readonly collectionUserOrganizations: TTCollectionClient<IUserOrganization>;
  public readonly collectionUserOrganizationInvites: TTCollectionClient<IUserOrganizationInvite>;
  public readonly collectionSites: TTCollectionClient<ISite>;
  public readonly collectionSiteOrganizations: TTCollectionClient<ISiteOrganization>;
  public readonly collectionBeacons: TTCollectionClient<IBeacon>;
  public readonly collectionBeaconOrganizations: TTCollectionClient<IBeaconOrganization>;
  public readonly collectionBeaconSites: TTCollectionClient<IBeaconSite>;
  public readonly collectionEvents: TTCollectionClient<IEvent>;
  public readonly collectionEventInstanceLogs: TTCollectionClient<IEventInstanceLog>;
  public readonly collectionEventInstances: TTCollectionClient<IEventInstance>;
  public readonly collectionEventOrganizations: TTCollectionClient<IEventOrganization>;
  public readonly collectionScripts: TTCollectionClient<IScript>;
  public readonly collectionOrganizationRoles: TTCollectionClient<IOrganizationRole>;
  public readonly collectionOrganizationRoleUsers: TTCollectionClient<IOrganizationRoleUser>;
  public readonly collectionCharts: TTCollectionClient<IChart>;
  public readonly collectionDevices: TTCollectionClient<IDevice>;
  public readonly collectionDeviceOrganizations: TTCollectionClient<IDeviceOrganization>;
  public readonly collectionDeviceUsers: TTCollectionClient<IDeviceUser>;
  public readonly collectionDeviceStateLog: TTCollectionClient<IDeviceStateLog>;
  public readonly collectionDeviceActivityLogs: TTCollectionClient<IDeviceActivityLog>;
  public readonly collectionNotifications: TTCollectionClient<INotification>;
  public readonly collectionNotificationLogs: TTCollectionClient<INotificationLog>;
  public readonly collectionDashSessions: TTCollectionClient<IDashSession>;
  public readonly collectionDashStateLogs: TTCollectionClient<IDashStateLog>;
  public readonly collectionDashActivityLogs: TTCollectionClient<IDashActivityLog>;

  constructor(protocol: "ws" | "wss", host: string, functionsEndpoint: string) {
    // Build connection strings
    const ttcomsUrl = `${protocol}://${host}/ws-ttcoms`;

    // Init ttcoms and get collections
    this.ttcoms = new TTComsClient<typeof VigilDefinition>(VigilDefinition, { url: ttcomsUrl, tokenValidator: tokenValidator });
    this.functions = BuildTTComFunctions({
      functions: VigilFunctions,
      endpoint: functionsEndpoint + "/fn",
      contextFn: async () => {
        const token = await this.ttcoms.getToken();
        return {
          token: token
        } as VigilContextClient
      }
    });

    this.collectionUsers = this.ttcoms.db.getCollection(schemaInstanceUser);
    this.collectionOrganizations = this.ttcoms.db.getCollection(schemaInstanceOrganization);
    this.collectionUserOrganizations = this.ttcoms.db.getCollection(schemaInstanceUserOrganization);
    this.collectionUserOrganizationInvites = this.ttcoms.db.getCollection(schemaInstanceUserOrganizationInvite);
    this.collectionSites = this.ttcoms.db.getCollection(schemaInstanceSite);
    this.collectionSiteOrganizations = this.ttcoms.db.getCollection(schemaInstanceSiteOrganization);
    this.collectionBeacons = this.ttcoms.db.getCollection(schemaInstanceBeacon);
    this.collectionBeaconOrganizations = this.ttcoms.db.getCollection(schemaInstanceBeaconOrganization);
    this.collectionBeaconSites = this.ttcoms.db.getCollection(schemaInstanceBeaconSite);
    this.collectionEvents = this.ttcoms.db.getCollection(schemaInstanceEvent);
    this.collectionEventInstanceLogs = this.ttcoms.db.getCollection(schemaInstanceEventLog);
    this.collectionEventInstances = this.ttcoms.db.getCollection(schemaInstanceEventInstance);
    this.collectionEventOrganizations = this.ttcoms.db.getCollection(schemaInstanceEventOrganization);
    this.collectionScripts = this.ttcoms.db.getCollection(schemaInstanceScript);
    this.collectionOrganizationRoles = this.ttcoms.db.getCollection(schemaInstanceOrganizationRole);
    this.collectionOrganizationRoleUsers = this.ttcoms.db.getCollection(schemaInstanceOrganizationRoleUser);
    this.collectionCharts = this.ttcoms.db.getCollection(schemaInstanceChart);
    this.collectionDevices = this.ttcoms.db.getCollection(schemaInstanceDevice);
    this.collectionDeviceOrganizations = this.ttcoms.db.getCollection(schemaInstanceDeviceOrganization);
    this.collectionDeviceUsers = this.ttcoms.db.getCollection(schemaInstanceDeviceUser);
    this.collectionDeviceStateLog = this.ttcoms.db.getCollection(schemaInstanceDeviceStateLog);
    this.collectionDeviceActivityLogs = this.ttcoms.db.getCollection(schemaInstanceDeviceActivityLog);
    this.collectionNotifications = this.ttcoms.db.getCollection(schemaInstanceNotification);
    this.collectionNotificationLogs = this.ttcoms.db.getCollection(schemaInstanceNotificationLogs);
    this.collectionDashSessions = this.ttcoms.db.getCollection(schemaInstanceDashSession);
    this.collectionDashStateLogs = this.ttcoms.db.getCollection(schemaInstanceDashStateLog);
    this.collectionDashActivityLogs = this.ttcoms.db.getCollection(schemaInstanceDashActivityLog);
  }

  public async start() {
    // Start ttcoms
    await this.ttcoms.start();
  }

  public async stop() {
    // Stop ttcoms
    await this.ttcoms.stop();
  }

  public async signOut() {
    this.ttcoms.setToken(null);
    await this.ttcoms.db.clear();
  }

  public async getContextFromToken(token: string | null): Promise<IContext> {
    const tokenDecoded = await tokenDecoder(token || '');
    const authenticated = await tokenValidator(token || '');
    return {
      authenticated: authenticated,
      tokenDecoded: tokenDecoded,
    }
  }

  public async getContext(): Promise<IContext> {
    const token = await this.ttcoms.getToken();
    const context = await this.getContextFromToken(token);
    return context;
  }

  public async getContextReactive(listener: (context: IContext) => void) {
    const subscription = await this.ttcoms.getTokenReactive(async (token) => {
      const context = await this.getContextFromToken(token);
      listener(context);
    })

    return subscription;
  }

  public async getToken() {
    return await this.ttcoms.getToken();
  }

  public async setToken(token: string) {
    await this.ttcoms.setToken(token);
  }

  public async getPreference(key: string) {
    return await this.ttcoms.getPreference(key)
  }

  public async getPreferenceReactive(key: string, listener: (value: Preference) => void) {
    return await this.ttcoms.getPreferenceReactive(key, listener);
  }

  public async setPreference(key: string, value: Preference) {
    return await this.ttcoms.setPreference(key, value);
  }
}
