import { liveQuery, Table } from "dexie";
import { IBaseSyncable, TTFilterQuery, TTPagination, TTComsCleanCreate, TTComsCleanUpdate } from "tt-coms";
import { TTComsDBQueryManager } from "./db_query_manager.js";
import { TTComsApi } from "./api.js";
import { DexieQueryExecutor } from "./db_mongo_to_dexie.js";
import { TTSubscription } from "./ttcoms_subscription.js";
import { TTComsQueryResult, TTSortQuery } from "tt-coms/src/query.js";
import { TTuuid } from "tt-uuid";

function findFirstKey(query: TTFilterQuery<any>) {
  let key = null;
  for (const key of Object.keys(query)) {
    console.log(key)
  }
}

export class TTCollectionClient<T> {
  private table;
  private queryManager;
  private api;

  constructor(table: Table<T, string>, queryManager: TTComsDBQueryManager, api: TTComsApi) {
    this.table = table;
    this.queryManager = queryManager;
    this.api = api;
  }

  /**
   * @deprecated
   */
  public getTable() {
    return this.table;
  }

  // public findSyncable(query: TTFilterQuery<T>, listener: (update: T[]) => void): Promise<TTSubscription> {
  //   // By default we query for non deleted items.
  //   // If deleted is explicity defined, respect it.
  //   // TODO: We can actually delete the beans on the client.
  //   // If we get a message from server where deleted=1, we should delete the bean.
  //   const queryUntyped = query as any;
  //   if (queryUntyped['deleted'] === undefined) {
  //     queryUntyped['deleted'] = 0;
  //   }

  //   return new Promise((resolve, reject) => {
  //     const observable = liveQuery<T[]>(async () => {
  //       try {
  //         const dexieQuery = new DexieQueryExecutor(this.getTable(), query);
  //         const items = await dexieQuery.executeQuery();

  //         // Find oldest & newest, or use new values 
  //         let oldest: any = items.length > 0 ? items[0] : null;
  //         let newest: any = items.length > 0 ? items[0] : null;
  //         for (const item of items) {
  //           const itemSyncable = item as IBaseSyncable;
  //           if (itemSyncable.serverStamp < oldest.serverStamp) {
  //             oldest = itemSyncable as T;
  //           }
  //           if (itemSyncable.serverStamp > newest.serverStamp) {
  //             newest = itemSyncable as T;
  //           }
  //         }

  //         const oldestSyncable = oldest as IBaseSyncable | null;
  //         const newestSyncable = newest as IBaseSyncable | null;

  //         // Add query to backend.
  //         this.queryManager.addQuery({
  //           collection: this.table.name,
  //           query: query,
  //           serverOldest: oldestSyncable ? oldestSyncable.serverStamp : null,
  //           serverNewest: newestSyncable ? newestSyncable.serverStamp : null,
  //         })

  //         return items;
  //       } catch (error) {
  //         console.error(error)
  //         reject(error);
  //         throw error;
  //       }
  //     });

  //     const subscription = observable.subscribe({
  //       next: (items) => {
  //         listener(items);
  //         resolve(subscription);
  //       }
  //     });
  //   });
  // }

  public async findOne(query: TTFilterQuery<T>): Promise<T | undefined> {
    return await this.table.get(query);
  }

  public async createOne(input: TTComsCleanCreate<T>, options?: { overwrite?: Partial<T> }) {
    const bean = {
      uuid: TTuuid.getCuuid(),
      changeStamp: TTuuid.getCuuid(),
      serverStamp: TTuuid.getCuuid(),
      deleted: 0,
      ...input,
      ...(options?.overwrite || null)
    }
    await this.table.put(bean as T)
    return bean;
  }

  public async createMany(inputs: TTComsCleanCreate<T>[]) {
    const beans = inputs.map(input => {
      return {
        uuid: TTuuid.getCuuid(),
        changeStamp: TTuuid.getCuuid(),
        serverStamp: TTuuid.getCuuid(),
        deleted: 0,
        ...input
      }
    })
    await this.table.bulkPut(beans as T[])
    return beans;
  }

  public async deleteOne(query: TTFilterQuery<T>) {
    const bean = await this.table.get(query);
    if (bean === undefined) {
      return;
    }
    const beanDeleted = {
      ...bean,
      deleted: 1,
      changeStamp: TTuuid.getCuuid(),
    }
    await this.table.put(beanDeleted as T)
    return beanDeleted;
  }

  public async upsertOne(query: TTFilterQuery<T>, input: TTComsCleanCreate<T>) {
    const bean = await this.table.get(query);
    if (bean === undefined) {
      return await this.createOne(input);
    }
    const beanUpdated = {
      ...bean,
      ...input,
      changeStamp: TTuuid.getCuuid(),
    }
    // TODO: Need to fix this, but it works for now at least.
    await this.table.db.transaction('rw', this.table, async () => {
      await this.table.where('uuid').equals((bean as any).uuid).delete();
      await this.table.put(beanUpdated as T);
    });
    return beanUpdated;
  }
}
