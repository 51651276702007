import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IUserSecrets extends IBaseSyncable {
  uuidUser: string;
  biometricFaceEmbedding?: number[];
}

export const schemaUserSecrets: TTSchema<IUserSecrets> = {
  ...schemaBaseSyncable,
  uuidUser: {
    type: String,
    indexed: true,
  },
  biometricFaceEmbedding: {
    type: Array<Number>,
    indexed: false
  }
}

export const schemaInstanceUserSecrets = new TTSchemaSyncableInstance<IUserSecrets>('user_secrets', schemaUserSecrets, 0);
