import { matchPath } from 'react-router-dom';

export const ROUTES = {
  ROUTE_APP_BASE: '',
  ROUTE_BASE: '',

  // Public routes
  ROUTE_PUBLIC: '/public',
  ROUTE_PUBLIC_REPORT: '/public/report/:report_info',
  ROUTE_PUBLIC_REPORT_OVERVIEW: '/public/report/:report_info/overview',
  ROUTE_PUBLIC_REPORT_EVENTS: '/public/report/:report_info/events',
  ROUTE_PUBLIC_REPORT_SITES: '/public/report/:report_info/sites',
  ROUTE_PUBLIC_REPORT_DEVICES: '/public/report/:report_info/devices',
  ROUTE_PUBLIC_REPORT_EVENT: '/public/report/:report_info/event/:uuid',
  ROUTE_PUBLIC_REPORT_SITE: '/public/report/:report_info/site/:uuid',
  ROUTE_PUBLIC_REPORT_DEVICE: '/public/report/:report_info/device/:uuid',
  ROUTE_PUBLIC_REPORT_DEVICE_LOGS_ACTIVITY: '/public/report/:report_info/device/:uuid/logs_activity',
  ROUTE_PUBLIC_REPORT_DEVICE_LOGS_STATE: '/public/report/:report_info/device/:uuid/logs_state',

  // FTUE routes
  ROUTE_TOOLBOX: '/toolbox',
  ROUTE_SIGN_IN_REQUEST: '/sign_in_request',
  ROUTE_SIGN_IN_CONFIRM: '/sign_in_confirm',
  ROUTE_SIGN_UP : '/sign_up',
  ROUTE_USER_CREATE: '/user_create',
  ROUTE_USER_CREATE_EMAIL_REQUEST: '/user_create_email_request',
  ROUTE_USER_CREATE_EMAIL_CONFIRM: '/user_create_email_confirm',
  ROUTE_USER_CREATE_MOBILE_REQUEST: '/user_create_mobile_request',
  ROUTE_USER_CREATE_MOBILE_CONFIRM: '/user_create_mobile_confirm',
  ROUTE_USER_MERGE: '/user_merge',
  ROUTE_VERIFY_EMAIL_REQUEST : '/verify_email_request',
  ROUTE_VERIFY_EMAIL_CONFIRM : '/verify_email_confirm',
  ROUTE_VERIFY_MOBILE : '/verify_mobile',

  // Profile routes
  ROUTE_PROFILE: '/profile',
  ROUTE_PROFILE_BASIC_INFO: '/profile/basic_info',
  ROUTE_PROFILE_APPEARANCE: '/profile/appearance',
  ROUTE_PROFILE_ORGANIZATIONS: '/profile/organizations',

  // Home routes
  ROUTE_HOME: '/home',
  ROUTE_HOME_ONBOARDING: '/home/onboarding',
  ROUTE_HOME_ONBOARDING_NEW_ORGANIZATION: '/home/onboarding/new_organization',
  ROUTE_HOME_OVERVIEW: '/home/overview',
  ROUTE_HOME_OVERVIEW_MAP: '/home/overview/map',
  ROUTE_HOME_OVERVIEW_EVENTS: '/home/overview/events',
  ROUTE_HOME_EVENT: '/home/event/:uuid',
  ROUTE_HOME_EVENT_OVERVIEW: '/home/event/:uuid/overview',
  ROUTE_HOME_EVENT_INSTANCES: '/home/event/:uuid/instances',
  ROUTE_HOME_EVENT_RAW_LOGS: '/home/event/:uuid/raw_logs',
  ROUTE_HOME_EVENTS: '/home/events',
  ROUTE_HOME_EVENT_INSTANCE: '/home/event_instance/:uuid',
  ROUTE_HOME_EVENT_INSTANCE_OVERVIEW: '/home/event_instance/:uuid/overview',
  ROUTE_HOME_EVENT_INSTANCE_MAP: '/home/event_instance/:uuid/map',
  ROUTE_HOME_EVENT_INSTANCE_LOGS: '/home/event_instance/:uuid/logs',
  ROUTE_HOME_ASSISTENT: '/home/assistent',
  ROUTE_HOME_INVENTORY: '/home/inventory',
  ROUTE_HOME_BILLING: '/home/billing',
  ROUTE_HOME_SETTINGS: '/home/settings',
  ROUTE_HOME_ROLES: '/home/roles',
  ROUTE_HOME_USERS: '/home/users',
  ROUTE_HOME_SITES: '/home/sites',
  ROUTE_HOME_BEACONS: '/home/beacons',
  ROUTE_HOME_DEVICES: '/home/devices',
  ROUTE_HOME_DEVICE: '/home/device/:uuid',
  ROUTE_HOME_DEVICE_LOGS_ACTIVITY: '/home/device/:uuid/logs_activity',
  ROUTE_HOME_DEVICE_BEHAVIOURS: '/home/device/:uuid/behaviours',
  ROUTE_HOME_DEVICE_LOGS_STATE: '/home/device/:uuid/logs_state',
  ROUTE_HOME_DEVICE_HISTORY_LOCATION: '/home/device/:uuid/history_location',
  ROUTE_HOME_DEVICE_HISTORY_STATISTICS: '/home/device/:uuid/history_statistics',
  ROUTE_HOME_SITE: '/home/site/:uuid',
  ROUTE_HOME_BEACON: '/home/beacon/:uuid',
  ROUTE_HOME_ROLE: '/home/role/:uuid',
  ROUTE_HOME_USER: '/home/user/:uuid',
  ROUTE_HOME_NOTIFICATIONS: '/home/notifications',
  ROUTE_HOME_NOTIFICATION: '/home/notification/:uuid',
  ROUTE_HOME_ACTIONS: '/home/actions',
  ROUTE_HOME_ACTION: '/home/action/:uuid',
  ROUTE_HOME_ACTION_OVERVIEW: '/home/action/:uuid/overview',
  ROUTE_HOME_REPORTS: '/home/reports',
  ROUTE_HOME_REPORT: '/home/report/:uuid',
  ROUTE_HOME_REPORT_OVERVIEW: '/home/report/:uuid/overview',

  // Vigil admin routes
  ROUTE_ADMIN: '/admin',
  ROUTE_ADMIN_BEACONS: '/admin/beacons',
  ROUTE_ADMIN_DEVICES: '/admin/devices',

  normalize: (pathname: string) => {return pathname.replace(/\/$/, "").replace(ROUTES.ROUTE_APP_BASE, '')},
  matches: (template: string, pathname: string) => { return matchPath(template, pathname) }
}
