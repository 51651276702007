import { FC, useContext, useEffect, useState } from 'react';
import { Table } from '../../components/table/table';
import { IDeviceStateLog } from 'vigil-datamodel';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { TTuuid } from 'tt-uuid';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { useParams } from 'react-router-dom';
import { useInterval } from '../../hooks/hook_interval';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { convertQueryFilterToMongoFilter } from './helper_home';

interface ScreenHomeDeviceLogsStateProps { }

export const ScreenHomeDeviceLogsState: FC<ScreenHomeDeviceLogsStateProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const [stateDeviceStateLogs, setDeviceStateLogs] = useState([] as IDeviceStateLog[]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");
  const [ticker, setTicker] = useState(0);
  const params = useParams();
  const uuidDevice = params['uuid'] || '';

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState([] as QueryFilter[]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState({ id: 'uuid', direction: 'descending' } as Sorting);
  const [stateTablePagingSelected, setTablePagingSelected] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 } as Paging);

  /* Getters */
  async function fetchEventsBackground() {
    const response = await vigil.functions.deviceStateLogs({
      filter: { ...convertQueryFilterToMongoFilter(stateTableFiltersSelected), uuidDevice: uuidDevice },
      ordering: {
        field: stateTableSortingSelected.id,
        direction: stateTableSortingSelected.direction
      },
      pagination: {
        page: stateTablePagingSelected.currentPage,
        count: stateTablePagingSelected.itemsPerPage
      }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateDeviceStateLogs)) {
      setDeviceStateLogs(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }

  async function fetchEvents() {
    try {
      setLoading(true);
      await fetchEventsBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update immediately
  useEffect(() => {
    fetchEvents();
  }, [ticker])

  // Update every 5 seconds
  useInterval(async () => {
    await fetchEventsBackground();
  }, 5000, false);

  return (
    <div className='flex flex-col h-full'>
      <Table<IDeviceStateLog>
        className='h-full flex flex-col drop-shadow my-2'
        name='Device State Logs'
        data={stateDeviceStateLogs}
        selectable={false}
        loading={stateLoading}
        error={stateError}
        columns={[
          {
            id: 'chargeLevel',
            header: 'Charge Level',
            sortable: true,
            value(item) {
              return <div> {item.chargeLevel}% </div>;
            },
          },
          {
            id: 'chargeState',
            header: 'Charge State',
            sortable: true,
            value(item) {
              return <div> {item.chargeState} </div>;
            },
          },
          {
            id: 'location',
            header: 'Location',
            sortable: false,
            value(item) {
              return <a className="text-blue-500 underline" target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps?q=${item.locationLat},${item.locationLng}`}>Location</a>
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          }
        ]}
        filtersOptions={[
          new DateFilter('uuid', 'Date Created', 'between'),
        ]}
        filtersSelected={stateTableFiltersSelected}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1) }}

        sortingOptions={null}
        sortingSelected={stateTableSortingSelected}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1) }}

        pagingOptions={null}
        pagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1) }}
      />
    </div>
  );
};
