import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IDeviceStateLog extends IBaseSyncable {
  uuidDevice: string;
  networkType: "wifi" | "cellular" | "none" | "unknown" | "bluetooth" | "ethernet" | "wimax" | "vpn" | "other";
  cellStrength: number;
  cellNetwork: string;
  locationAcc?: number;
  locationLng?: number;
  locationLat?: number;
  locationAlt?: number;
  chargeLevel?: number;
  chargeState: "plugged" | "none";
  stepCountHourly?: number;
}

export const schemaDeviceStateLog: TTSchema<IDeviceStateLog> = {
  ...schemaBaseSyncable,
  uuidDevice: {
    type: String,
    indexed: true,
  },
  networkType: {
    type: String,
    indexed: true,
  },
  cellStrength: {
    type: Number,
    indexed: true,
  },
  cellNetwork: {
    type: String,
    indexed: true,
  },
  locationAcc: {
    type: Number,
    indexed: true,
  },
  locationLng: {
    type: Number,
    indexed: true,
  },
  locationLat: {
    type: Number,
    indexed: true,
  },
  locationAlt: {
    type: Number,
    indexed: true,
  },
  chargeLevel: {
    type: Number,
    indexed: true,
  },
  chargeState: {
    type: String,
    indexed: true,
  },
  stepCountHourly: {
    type: Number,
    indexed: true,
  },
}

export const schemaInstanceDeviceStateLog = new TTSchemaSyncableInstance<IDeviceStateLog>('device_state_logs', schemaDeviceStateLog, 0);
