import { useContext, useEffect, useRef, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { Modal, ModalProps } from "../../../components/modal";
import { VigilQRDisplay } from "../../../components/qr_display";
import { VQROrganizationInviteDevice, VigilQRCodes } from "vigil-qr";

interface ModalDeviceLinkProps extends ModalProps {
  uuidSite?: string;
  onSubmit?: () => Promise<void>;
}

export const ModalDeviceLink: React.FC<ModalDeviceLinkProps> = (props) => {
  const contextOrganization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const qr: VQROrganizationInviteDevice = {
    uuidOrganization: contextOrganization!.uuid
  }

  /* Validation */
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Link New Device</h3>
      <p>
        To add a new device to this organization, scan the code below.
      </p>
      <VigilQRDisplay qrcode={() => VigilQRCodes.organizationInviteDevice.toSVGDataURL({uuidOrganization: contextOrganization!.uuid}) } />
    </Modal>
  )
}
