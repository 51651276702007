import { FC, ReactNode, createContext, useState } from 'react';

interface ToastInput {
  component: ReactNode;
  lifetimeMS?: number;
}

interface ToastContext {
  addToast: (input: ToastInput) => string;
  removeToast: (id: string) => void;
}

interface ToastProps {
  children: React.ReactNode;
}

let toastCount = 0;

export const ContextToast = createContext(null as unknown as ToastContext);

export const Toast: FC<ToastProps> = (props) => {

  const [stateToasts, setToasts] = useState({} as Record<string, ToastInput>);

  function addToast(toastInput: ToastInput) {
    // Add to array
    const id = (toastCount++).toString();

    setToasts({
      ...stateToasts,
      [id]: toastInput,
    });

    // Invoke removal
    if (toastInput.lifetimeMS) {
      setTimeout(() => {
        setToasts(stateToasts => {
          const stateToastsUpdate = { ...stateToasts };
          delete stateToastsUpdate[id];
          return stateToastsUpdate
        });
      }, toastInput.lifetimeMS)
    }

    return id;
  }

  function removeToast(id: string) {
    setToasts(stateToasts => {
      const stateToastsUpdate = { ...stateToasts };
      delete stateToastsUpdate[id];
      return stateToastsUpdate
    });
  }

  return (
    <ContextToast.Provider value={{ addToast, removeToast }}>
      <div className={`toast toast-top toast-center z-50`}>
        {
          Object.entries(stateToasts).map(([key, toastInput]) => {
            return <div key={key}>
              {toastInput.component}
            </div>
          })
        }
      </div>
      {props.children}
    </ContextToast.Provider >
  );
};
