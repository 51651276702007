import React from 'react';

interface ScreenHomeBillingProps  {}

export const ScreenHomeBilling: React.FC<ScreenHomeBillingProps> = (props) => {
  return (
    <div>
      billing: you owe us money
    </div>
  );
};
