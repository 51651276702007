import jwt_decode from "jwt-decode";

export interface IVigilToken {
  uuidIdentity: string,
  target: string,
  targetType: 'email' | 'mobile', // Possibly 'secret' ?
  expires: number,
}

export async function tokenDecoder(token: string) {
  try {
    const tokenDecoded = jwt_decode(token) as IVigilToken;
    // TODO: Add some checks here to ensure it actually is an IVigilToken
    return tokenDecoded;
  }
  catch (error) {
    console.log(error)
    return undefined;
  }
}

export async function tokenValidator(token: string) {
  const tokenDecoded = await tokenDecoder(token);
  return tokenDecoded ? new Date().valueOf() < tokenDecoded['expires'] : false;
}
