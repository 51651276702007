import React, { useState } from 'react';
import { TabUsersAccept } from './home_users_accept';
import { TabUsersInvite } from './home_users_invite';

interface ScreenHomeUsersProps { }

export const ScreenHomeUsers: React.FC<ScreenHomeUsersProps> = (props) => {
  const [stateActiveTabId, setActiveTabId] = useState<'accept' | 'invite'>('accept');

  function tabClass(tabId: 'accept' | 'invite') {
    let style = "tab tab-lifted tab-md ";
    if (stateActiveTabId == tabId) {
      style += 'tab-active'
    }
    return style;
  }

  return (
    <div className='p-4 h-full w-full flex flex-col'>
      <div className="tabs flex">
        <a onClick={() => setActiveTabId('accept')} className={tabClass('accept')}>Accepted Users</a>
        <a onClick={() => setActiveTabId('invite')} className={tabClass('invite')}>Pending Invites</a>
      </div>
      {stateActiveTabId == 'accept' && <TabUsersAccept />}
      {stateActiveTabId == 'invite' && <TabUsersInvite />}
    </div >
  );
};
