import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IDeviceOrganization extends IBaseSyncable {
  uuidDevice: string;
  uuidOrganization: string;
}

export const schemaDeviceOrganization: TTSchema<IDeviceOrganization> = {
  ...schemaBaseSyncable,
  uuidDevice: {
    type: String,
    indexed: true,
  },
  uuidOrganization: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceDeviceOrganization = new TTSchemaSyncableInstance<IDeviceOrganization>('device_organizations', schemaDeviceOrganization, 0);
