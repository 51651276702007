import React, { useContext, useEffect, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IReportDirectory, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/hook_modal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { ContextRoles } from '../../providers/provider_roles';
import { convertQueryFilterToMongoFilter } from './helper_home';
import { TTActions } from 'tt-permissions';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { useInterval } from '../../hooks/hook_interval';
import { ModalReportCreate } from './func_report/modal_report_create';
import { ModalReportDelete } from './func_report/modal_report_delete';


interface ScreenHomeReportsProps { }

export const ScreenHomeReports: React.FC<ScreenHomeReportsProps> = (props) => {
  const navigate = useNavigate()
  const contextOrganization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const vigil = useContext(ContextVigilClient);

  const [ticker, setTicker] = useState(0);

  const [stateReports, setReports] = useState([] as IReportDirectory[]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState([] as QueryFilter[]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState({ id: 'uuid', direction: 'descending' } as Sorting);
  const [stateTablePagingSelected, setTablePagingSelected] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 } as Paging);

  const { isOpen: isOpenModalCreateReport, toggle: toggleModalCreateReport } = useModal();
  const { isOpen: isOpenModalDeleteReport, toggle: toggleModalDeleteReport } = useModal();

  const [selectedReportsState, setSelectedReports] = useState<IReportDirectory[]>([]);

  /* Getters */
  async function fetchReportsBackground() {
    if (!contextOrganization) { return }

    const response = await vigil.functions.reportDirectory({
      uuidOrganization: contextOrganization.uuid,
      filter: convertQueryFilterToMongoFilter(stateTableFiltersSelected),
      ordering: { field: stateTableSortingSelected.id, direction: stateTableSortingSelected.direction },
      pagination: { page: stateTablePagingSelected.currentPage, count: stateTablePagingSelected.itemsPerPage }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateReports)) {
      setReports(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }

  async function fetchReports() {
    try {
      setLoading(true);
      await fetchReportsBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update immediately
  useEffect(() => {
    fetchReports();
  }, [contextOrganization, ticker])

  // Update every 5 seconds
  useInterval(async () => {
    await fetchReportsBackground();
  }, 5000, false);

  function navigateReport(selectedReport: IReportDirectory) {
    navigate(ROUTES.ROUTE_HOME_REPORT.replace(':uuid', selectedReport.uuid))
  }

  function deleteReports(selectedReports: IReportDirectory[]) {
    setSelectedReports(selectedReports)
    toggleModalDeleteReport();
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalReportCreate isOpen={isOpenModalCreateReport} toggle={toggleModalCreateReport} onSubmit={async () => await fetchReports()} />
      <ModalReportDelete isOpen={isOpenModalDeleteReport} toggle={toggleModalDeleteReport} onSubmit={async () => await fetchReports()} reports={selectedReportsState} />

      <Table<IReportDirectory>
        className='h-full flex flex-col drop-shadow'
        name='Reports'
        data={stateReports}
        selectable={true}
        loading={stateLoading}
        error={stateError}
        columns={[
          {
            id: 'name',
            header: 'Name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateReport(item)}>
                  {item.name}
                </div>
              );
            },
          },
          {
            id: 'config.type',
            header: 'Type',
            sortable: true,
            value(item) {
              return <div>{item.config.type.toUpperCase()}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'changeStamp',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('name', 'Name', 'contains'),
          new TextFilter('config.type', 'Type', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
          new DateFilter('changeStamp', 'Last Updated', 'between'),
        ]}
        filtersSelected={stateTableFiltersSelected}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1); }}

        sortingOptions={null}
        sortingSelected={stateTableSortingSelected}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1); }}

        pagingOptions={null}
        pagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1); }}

        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new report</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RReport),
            onClick: () => toggleModalCreateReport(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RReport),
            onClick: (selectedItems) => deleteReports(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RReport),
            onClick: (selectedItems) => deleteReports(selectedItems)
          }
        ]}
      />
    </div >
  );
};
