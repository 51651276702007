export interface Coordinate {
  lat: number;
  lng: number;
}

export function getBoundingBox(coordinates: Coordinate[]): [[number, number], [number, number]] {
  if (coordinates.length === 0) {
    return [[0, 0], [0, 0]];
  }

  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  for (const { lat, lng } of coordinates) {
    minLat = Math.min(minLat, lat);
    maxLat = Math.max(maxLat, lat);
    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  }

  const southWest: [number, number] = [minLng, minLat];
  const northEast: [number, number] = [maxLng, maxLat];

  return [southWest, northEast];
}