import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";
import { INotificationLateStart, INotificationPanic, INotificationUnsuccessfulPatrol } from "./bean_notification";
import { ICreator } from "./type_creator";

export enum EventInstanceLogId {
  NotificationPanic = 'notificationPanic',
  NotificationLateStart = 'notificationLateStart',
  NotificationUnsuccessfulPatrol = 'notificationUnsuccessfulPatrol',
  DutyOn = 'dutyOn',
  DutyOff = 'dutyOff',
  Beacon = 'beacon',
  BeaconCheckIn = 'beaconCheckIn',
  GeofenceCheckIn = 'geofenceCheckIn',
}

interface IEventInstanceLogNotificationBase { uuidNotification: string, uuidEventLogParent?: string }
export type IEventInstanceLogNotificationPanic = IEventInstanceLogNotificationBase & INotificationPanic
export type IEventInstanceLogNotificationLateStart = IEventInstanceLogNotificationBase & INotificationLateStart
export type IEventInstanceLogNotificationUnsuccessfulPatrol = IEventInstanceLogNotificationBase & INotificationUnsuccessfulPatrol

export interface IEventInstanceLogDutyOn {
  duration: number;
}

export interface IEventInstanceLogDutyOff {
  duration: number;
}

export interface IEventInstanceLogBeacon {
  uuidEventLogParent?: string;
  beaconUuid: string;
  beaconName: string;
  beaconLat: number;
  beaconLng: number;
  beaconAlt: number;
  userUuid: string;
  userLat: number;
  userLng: number;
  userAlt: number;
  userAccuracy: number;
}

export interface IEventInstanceLogBeaconCheckIn {
  beaconUuid: string;
  beaconName: string;
  beaconLat: number;
  beaconLng: number;
  beaconAlt: number;
  userUuid: string;
  userLat: number;
  userLng: number;
  userAlt: number;
  userAccuracy: number;
}

export interface IEventInstanceLogGeofenceCheckIn {
  eventUuid: string;
  eventInstanceUuid: string;
  userUuid: string;
  userLat: number;
  userLng: number;
  userAlt: number;
  userAccuracy: number;
}

export type IEventInstanceLog = IBaseSyncable & { uuidEventInstance: string, creator: ICreator } &
  (
    | { id: EventInstanceLogId.NotificationPanic, data: IEventInstanceLogNotificationPanic }
    | { id: EventInstanceLogId.NotificationLateStart, data: IEventInstanceLogNotificationLateStart }
    | { id: EventInstanceLogId.NotificationUnsuccessfulPatrol, data: IEventInstanceLogNotificationUnsuccessfulPatrol }
    | { id: EventInstanceLogId.DutyOn, data: IEventInstanceLogDutyOn }
    | { id: EventInstanceLogId.DutyOff, data: IEventInstanceLogDutyOff }
    | { id: EventInstanceLogId.Beacon, data: IEventInstanceLogBeacon }
    | { id: EventInstanceLogId.BeaconCheckIn, data: IEventInstanceLogBeaconCheckIn }
    | { id: EventInstanceLogId.GeofenceCheckIn, data: IEventInstanceLogGeofenceCheckIn }
  )

export const schemaEventLog: TTSchema<IEventInstanceLog> = {
  ...schemaBaseSyncable,
  uuidEventInstance: {
    type: String,
    indexed: true,
  },
  creator: {
    type: Object,
    indexed: true,
  },
  id: {
    type: String,
    indexed: true,
  },
  data: {
    type: Object,
    indexed: true,
  },
}

export const schemaInstanceEventLog = new TTSchemaSyncableInstance<IEventInstanceLog>('event_log', schemaEventLog, 0);
