import { ReactNode, createContext, useEffect, useState } from "react";
import { VigilClient } from "vigil-client";
import { FullPageLoader } from "../components/full_page_loader";
import { FullPageError } from "../components/full_page_error";
import { Environment } from "../environment";

export const ContextVigilClient = createContext(null as unknown as VigilClient);

interface ProviderVigilClientProps {
  children: ReactNode
}
export const ProviderVigilClient: React.FC<ProviderVigilClientProps> = (props: ProviderVigilClientProps) => {
  const [stateClient, setClient] = useState(null as VigilClient | null);
  const [stateClientLoading, setClientLoading] = useState(true);
  const [stateClientError, setClientError] = useState('');

  // Set up Vigil Client Manager & Get context
  useEffect(() => {
    (async () => {
      try {
        // Set loading
        setClientLoading(true);
        setClientError('');

        // Setup client
        const client = new VigilClient(
          Environment.SERVICE_BACKEND_PROTOCOL as 'ws' | 'wss',
          Environment.SERVICE_BACKEND_HOST,
          Environment.SERVICE_BACKEND_HOST_URL,
        );
        await client.start();

        // Set client
        setClient(client);

        // Set loading
        setClientLoading(false);
        console.log('Vigil Client Started');
      }
      catch (error: any) {
        setClientError(error.message);
        setClientLoading(false);
      }
    })();
  }, []);

  if (stateClientLoading) {
    return <FullPageLoader />
  }

  if (stateClientError || !stateClient) {
    console.error(stateClientError)
    console.warn("PROTIP: Check that you have an .env file")
    return <FullPageError error={stateClientError} />
  }

  return (
    <ContextVigilClient.Provider value={stateClient}>
      {props.children}
    </ContextVigilClient.Provider>
  )
}
