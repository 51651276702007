import { FC, useContext, useEffect, useState } from 'react';
import { ModalDeviceLink } from './func_device/modal_device_link';
import { useModal } from '../../hooks/hook_modal';
import { IDeviceDirectory } from 'vigil-datamodel';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { convertQueryFilterToMongoFilter } from './helper_home';
import { ContextRoles } from '../../providers/provider_roles';
import { ModalDeviceDelete } from './func_device/modal_device_delete';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { useInterval } from '../../hooks/hook_interval';
import { DevicesTable } from '../shared/devices_table';

interface ScreenHomeDevicesProps { }

export const ScreenHomeDevices: FC<ScreenHomeDevicesProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);

  const navigate = useNavigate()

  const [stateDevices, setDevices] = useState<IDeviceDirectory[]>([]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState<QueryFilter[]>([]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState<Sorting>({ id: 'uuid', direction: 'descending' });
  const [stateTablePagingSelected, setTablePagingSelected] = useState<Paging>({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 });

  const [ticker, setTicker] = useState(0);

  const { isOpen: isOpenModalCreateDevice, toggle: toggleModalCreateDevice } = useModal();
  const { isOpen: isOpenModalDeleteDevice, toggle: toggleModalDeleteDevice } = useModal();

  const [selectedDevicesState, setSelectedDevices] = useState<IDeviceDirectory[]>([]);

  async function fetchDevicesBackground() {
    if (!organization) { return }

    const response = await vigil.functions.deviceDirectory({
      uuidOrganization: organization.uuid,
      filter: convertQueryFilterToMongoFilter(stateTableFiltersSelected),
      ordering: { field: stateTableSortingSelected.id, direction: stateTableSortingSelected.direction },
      pagination: { page: stateTablePagingSelected.currentPage, count: stateTablePagingSelected.itemsPerPage }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateDevices)) {
      setDevices(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }

  async function fetchDevices() {
    try {
      setLoading(true);
      await fetchDevicesBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDevices();
  }, [organization, ticker])

  useInterval(async () => {
    await fetchDevicesBackground();
  }, 5000, false);

  function deleteDevices(selectedDevice: IDeviceDirectory[]) {
    setSelectedDevices(selectedDevice);
    toggleModalDeleteDevice();
  }

  function navigateDevice(selectedDevice: IDeviceDirectory) {
    navigate(ROUTES.ROUTE_HOME_DEVICE.replace(':uuid', selectedDevice.uuid))
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalDeviceLink isOpen={isOpenModalCreateDevice} toggle={toggleModalCreateDevice} />
      <ModalDeviceDelete isOpen={isOpenModalDeleteDevice} toggle={toggleModalDeleteDevice} devices={selectedDevicesState} onSubmit={async () => { await fetchDevices() }} />

      <DevicesTable
        devices={stateDevices}
        loading={stateLoading}
        error={stateError}
        onDeviceClick={navigateDevice}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1) }}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1) }}
        tablePagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1) }}
        selectable={true}
        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />Link Device</div>,
            color: 'primary',
            onClick: () => toggleModalCreateDevice(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            onClick: (selectedItems) => deleteDevices(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            onClick: (selectedItems) => deleteDevices(selectedItems)
          }
        ]}
      />
    </div>
  );
};