import React from 'react';

interface ScreenHomeInventoryProps  {}

export const ScreenHomeInventory: React.FC<ScreenHomeInventoryProps> = (props) => {
  return (
    <div>
      inventory: order some products from us
    </div>
  );
};
