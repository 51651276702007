import { TTSchema, TTSchemaInstance, TTBeanTarget, schemaBaseSyncable, IBaseSyncable } from "tt-coms";

export interface IAuthChallenge extends IBaseSyncable {
  type: 'email' | 'mobile',
  target: string;
  valueHash: string;
  expiry: Date;
}

export const schemaAuthChallenge: TTSchema<IAuthChallenge> = {
  ...schemaBaseSyncable,
  type: {
    type: String,
  },
  target: {
    type: String,
  },
  valueHash: {
    type: String,
  },
  expiry: {
    type: Date,
  },
}

export const schemaInstanceAuthChallenge = new TTSchemaInstance<IAuthChallenge>('auth_challenges', schemaAuthChallenge, 0, [TTBeanTarget.Server]);
