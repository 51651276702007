import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export enum DeviceUserStatus {
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

export interface IDeviceUser extends IBaseSyncable {
  uuidDevice: string;
  uuidUser: string;
  status: DeviceUserStatus;
}

export const schemaDeviceUser: TTSchema<IDeviceUser> = {
  ...schemaBaseSyncable,
  uuidDevice: {
    type: String,
    indexed: true,
  },
  uuidUser: {
    type: String,
    indexed: true,
  },
  status: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceDeviceUser = new TTSchemaSyncableInstance<IDeviceUser>('device_users', schemaDeviceUser, 0);
