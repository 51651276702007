import { VigilQRCode, JSONObject } from "./qrcode.js";
import { VigilQRScanner } from "./scanner.js";
import { VQROrganizationInviteUser, VQROrganizationInviteDevice, VQRBeacon } from "./qrcodes.js";

// Exports
export { VigilQRCode }
export { type JSONObject }
export { type VQROrganizationInviteUser }
export { type VQROrganizationInviteDevice }
export { type VQRBeacon }

export const VigilQRCodes = {
  organizationInviteUser: new VigilQRCode<VQROrganizationInviteUser>('inviteUser'),
  organizationInviteDevice: new VigilQRCode<VQROrganizationInviteDevice>('inviteDevice'),
  beacon: new VigilQRCode<VQRBeacon>('beacon'),
}

export const VigilQRCodeScanner = new VigilQRScanner(Object.values(VigilQRCodes))
