import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ROUTES } from '../../routes';
import { Permissions, IEvent } from 'vigil-datamodel';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { TTuuid } from 'tt-uuid';
import { TTActions } from 'tt-permissions';
import { IconPencilSquareSolid, IconTrashSolid } from '../../components/icons';
import { InputButton } from '../../components/input_button';
import { ContextRoles } from '../../providers/provider_roles';
import { useModal } from '../../hooks/hook_modal';
import { ModalEventDelete } from './func_event/modal_event_delete';
import { ModalEventUpdate } from './func_event/modal_event_update';
import { InputForm } from '../../components/input_form';
import { InputText } from '../../components/input_text';
import { TextHeader } from '../../components/text_header';

interface ScreenHomeEventProps { }

export const ScreenHomeEventOverview: React.FC<ScreenHomeEventProps> = (props) => {
  /* State */
  const navigate = useNavigate()
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);

  const [stateLoading, setLoading] = useState(false);
  // const [stateError, setError] = useState("");
  // const [stateFirstName, setFirstName] = useState(user?.firstName || '');
  // const [stateLastName, setLastName] = useState(user?.lastName || '');

  function hasChanges() {
    // const changed =
    //   stateFirstName !== user!.firstName ||
    //   stateLastName !== user!.lastName;

    return true;
  }

  function validateForm() {
    const required: string[] = [];

    return [
      ...required,
    ];
  }

  function save() {

  }

  const [stateError, setError] = useState<string>('');
  const [stateEventLoading, setEventLoading] = useState(true);
  const [stateEvent, setEvent] = useState<IEvent>();

  const { isOpen: isOpenModalUpdatePatrol, toggle: toggleModalUpdatePatrol } = useModal();
  const { isOpen: isOpenModalDeletePatrol, toggle: toggleModalDeletePatrol } = useModal();

  const params = useParams();
  const uuidEvent = params['uuid'] || '';

  useEffect(() => { fetchEvent() }, [uuidEvent]);

  async function fetchEvent() {
    try {
      setEventLoading(true);
      const event = await vigil.functions.eventFindOne({ filter: { uuid: uuidEvent } });
      setEvent(event);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setEventLoading(false);
    }
  }

  if (stateEventLoading || !stateEvent) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='flex w-full flex-col'>
      <ModalEventUpdate isOpen={isOpenModalUpdatePatrol} toggle={toggleModalUpdatePatrol} event={stateEvent} onSubmit={async () => fetchEvent()} />
      <ModalEventDelete isOpen={isOpenModalDeletePatrol} toggle={toggleModalDeletePatrol} events={[stateEvent]} onSubmit={async () => navigate(ROUTES.ROUTE_HOME_EVENTS)} />

      <div className='border border-base-300 rounded-xl my-4 p-2'>
        <div className='flex'>
          <div className='flex-grow'>
            <TextHeader> Details </TextHeader>
          </div>
          <InputButton text='Edit Event' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.U, Permissions.REvent)} onClick={() => toggleModalUpdatePatrol()} />
          <div className='mx-2' />
          <InputButton text='Delete Event' before={<IconTrashSolid className='h-5 mr-2' />} type='btn-error' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.REvent)} onClick={() => toggleModalDeletePatrol()} />
        </div>
        <InputForm onSubmit={() => { console.log('submit') }}>
          <InputText errors={[]} labelText='Event Name' value={stateEvent.name} />
          <InputText errors={[]} labelText='Created At' value={TTuuid.decodeCuuid(stateEvent.uuid).time.toLocaleDateString() + " : " + TTuuid.decodeCuuid(stateEvent.uuid).time.toLocaleTimeString()} disabled={true} />
          <InputText errors={[]} labelText='Updated At' value={TTuuid.decodeCuuid(stateEvent.changeStamp).time.toLocaleDateString() + " : " + TTuuid.decodeCuuid(stateEvent.changeStamp).time.toLocaleTimeString()} disabled={true} />
          {/* <InputButton type='btn-primary' text='Save' loading={stateLoading} disabled={hasChanges() == false || validateForm().length > 0} onClick={save} /> */}
        </InputForm>
      </div>



    </div>
  )
};


// function EventScheduleTrigger(props: { trigger: EventTrigger & { type: EventTriggerId.Scheduled } }) {
//   return (
//     <div className='flex flex-grow items-end'>
//       <div className="flex items-center mr-6">
//         <span className='font-semibold'>Schedule Trigger:</span>
//         <span className="text-sm ml-2"><span className='italic'>Start Time</span> - {getEventStartTimeInStr(props.trigger.eventCron)}</span>
//         <span className="text-sm ml-2"><span className='italic'>Occurances</span> - {getEventOccuranceInStr(props.trigger.eventCron)}</span>
//       </div>
//     </div>
//   )
// }


// <div className='flex items-center'>
// <div className='flex-grow'>
//   <div className='flex flex-grow items-end'>
//     <div className="flex items-center mr-6">
//       <span className='font-semibold'>Date Created:</span>
//       <span className="text-sm ml-2">{TTuuid.decodeCuuid(stateEvent.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateEvent.uuid).time.toLocaleTimeString()}</span>
//     </div>
//     <div className="flex items-center mr-6">
//       <span className='font-semibold'>Last Updated:</span>
//       <span className="text-sm ml-2">{TTuuid.decodeCuuid(stateEvent.changeStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateEvent.changeStamp).time.toLocaleTimeString()}</span>
//     </div>
//     <div className="flex items-center mr-6">
//       <span className='font-semibold'>Duration:</span>
//       <span className="text-sm ml-2">{getEventDurationInStr(stateEvent.triggers[0].eventLifetimeMS)}</span>
//     </div>
//   </div>
//   {stateEvent.triggers.map((trigger, index) => {
//     if (trigger.type === EventTriggerId.Scheduled) {
//       return <EventScheduleTrigger key={index} trigger={trigger} />
//     }
//   })}
// </div>

// <div className='mx-4'>
//   <InputButton text='Edit Patrol' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.U, Permissions.REvent)} onClick={() => toggleModalUpdatePatrol()} />
// </div>
// <InputButton text='Delete Patrol' before={<IconTrashSolid className='h-5 mr-2' />} type='btn-error' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.REvent)} onClick={() => toggleModalDeletePatrol()} />
// </div>

// {stateEvent.configs.map((config, index) => {
// if (config.type === EventConfigId.Patrol) {
//   return <HomeEventPatrol key={index} patrolConfig={config} />
// }
// })}
// </div>

