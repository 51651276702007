import { Table } from "dexie";
import { IBeanDiff, schemaInstanceBeanDiff, sleep } from "tt-coms";
import { TTComsClientDB } from "./db.js";
import { flatten } from 'flat'

export class TTComsDBDiffManager {
  private readonly changesOutbound: Map<string, IBeanDiff> = new Map();
  private readonly db: TTComsClientDB;
  private stopMainLoop: boolean = false;

  constructor(db: TTComsClientDB) {
    this.db = db;
  }

  public async start() {
    // Load from offline collection
    const collection = this.db.getCollection<IBeanDiff>(schemaInstanceBeanDiff).getTable();
    const offlineBeans = await collection.toArray();
    for (const offlineBean of offlineBeans) {
      this.changesOutbound.set(offlineBean.uuid, offlineBean);
    }

    // Start save loop
    this.stopMainLoop = false;
    this.loop();
  }

  public async stop() {
    this.stopMainLoop = true;
  }

  public add(uuid: string, table: string, from: any, to: any) {
    // Flatten beans
    const flatFrom = flatten(from || {}, { delimiter: "." }) as Record<string, any>;
    const flatTo = flatten(to || {}, { delimiter: "." }) as Record<string, any>;

    // Check the diff
    const diff = {} as Record<string, any>;
    for (const key of Object.keys(flatTo)) {
      if (flatFrom[key] !== flatTo[key]) {
        diff[key] = flatTo[key];
      }
    }

    const current = this.changesOutbound.get(uuid) || { uuid: uuid, table: table, fields: {} };
    const merged = { uuid: uuid, table: table, fields: { ...current.fields, ...diff } };
    this.changesOutbound.set(uuid, merged);
  }

  public collect(size: number): Array<IBeanDiff> {
    const changes = [];
    for (const entry of this.changesOutbound.entries()) {
      const uuid = entry[0];
      const change = entry[1];

      changes.push(change);
    }

    return JSON.parse(JSON.stringify(changes));
  }

  public confirm(confirmations: Array<IBeanDiff>) {
    for (const confirmation of confirmations) {
      // Get current diff
      const current = this.changesOutbound.get(confirmation.uuid) || { uuid: confirmation.uuid, table: confirmation.table, fields: {} };

      // For each field, if unchanged, delete
      for (const key of Object.keys(confirmation.fields)) {
        if (current.fields[key] == confirmation.fields[key]) {
          delete current.fields[key];
        }
      }

      // If no more fields left, remove
      if (Object.keys(current.fields).length == 0) {
        this.changesOutbound.delete(confirmation.uuid)
      }
    }
  }


  private async loop() {
    while (true) {
      if (this.stopMainLoop) break;
      await sleep(1000 * 10);
      if (this.stopMainLoop) break;
      const collection = this.db.getCollection<IBeanDiff>(schemaInstanceBeanDiff).getTable();

      // Clear values
      const stateDisk = await collection.toArray();
      const deletes = [];
      for (const change of stateDisk) {
        if (!this.changesOutbound.has(change.uuid)) {
          deletes.push(change.uuid);
        }
      }
      await collection.bulkDelete(deletes);

      // Write values
      const stateMemory = Array.from(this.changesOutbound.values());
      await collection.bulkPut(stateMemory)
    }
  }
}

