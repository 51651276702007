
import React, { useContext } from 'react';
import VigilLogo from '../../assets/vigil_icon.svg';

import { IconBars3Outline } from '../../components/icons';
import { InputButtonIcon } from '../../components/input_button_icon';
import { NavbarUser } from './navbar_user';
import { ContextOrganization } from '../../providers/provider_organization';
import useBreakpoints from '../../hooks/hook_breakpoints';

interface NavbarProps {
  onClickMenu?: () => void
}

export const Navbar: React.FC<NavbarProps> = (props) => {
  const breakpoints = useBreakpoints();
  const organization = useContext(ContextOrganization);
  return (
    <div className='navbar bg-base-200 border-solid'>
      {/* Menu */}
      <div className='mx-0'>
        {
          organization && props.onClickMenu &&
          <InputButtonIcon icon={IconBars3Outline} shape='btn-circle' loading={false} onClick={props.onClickMenu} />
        }
      </div>
      {/* Vigil Logo */}
      <div className="mx-1">
        <div className='flex flex-none'>
          <img src={VigilLogo} className="w-10 mr-2" alt="" />
          <span className="text-xl font-semibold self-center">Vigil </span>
        </div>
      </div>
      {/* Organization tool */}
      {
        organization && <div className='rounded-full border border-base-300 p-2 ml-2'>
          <img src={`https://ui-avatars.com/api/?name=${organization?.name[0]}&background=random`} className="w-6 h-6 rounded-full" alt="" />
          {breakpoints['MD'] && <span className="ml-1">{organization?.name}</span>}
        </div>
      }

      {/* middle */}
      <div className='flex-1'></div>
      {/* User tool */}
      <div className="">
        <NavbarUser />
      </div>
    </div>
  );
};
