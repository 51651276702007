import React, { ReactNode } from 'react';

export interface ChartBaseProps {
  label: string,
  error?: string,
  loading?: boolean,
  children?: ReactNode;
}

export const ChartBase: React.FC<ChartBaseProps> = (props) => {
  return (
    <div className="card bg-base-100 outline outline-1 outline-base-content">
      <div className='label label-text justify-center'> { props.label } </div>
      { props.children }
    </div>
  );
};
