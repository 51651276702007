import React, { ReactNode } from 'react';

export interface InputBaseProps {
  className?: string;
  labelText: string;
  labelTooltip?: string;
  errors: string[];
  disabled?: boolean;
  children?: ReactNode;
}

export const InputBase: React.FC<InputBaseProps> = (props) => {
  return (
    <div className={`${props.className ?? ''} ${props.disabled ? 'opacity-50 pointer-events-none' : ''}`}>
      <div className='tooltip flex' data-tip={props.labelTooltip}>
        <label className='label label-text'> {props.labelText} </label>
      </div>
      {props.children}
      <div className='mt-2'>
        {
          props.errors.map((error) => {
            return <label className='label label-text text-error p-0' key={error}> * {error} </label>
          })
        }
      </div>
    </div>
  );
};
