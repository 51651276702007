import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IOrganizationRoleUser extends IBaseSyncable {
  uuidOrganization: string,
  uuidUser: string,
  uuidRole: string,
}

export const schemaOrganizationRoleUser: TTSchema<IOrganizationRoleUser> = {
  ...schemaBaseSyncable,
  uuidOrganization: {
    type: String,
    indexed: true,
  },
  uuidUser: {
    type: String,
    indexed: true,
  },
  uuidRole: {
    type: String,
    indexed: true,
  }
}

export const schemaInstanceOrganizationRoleUser = new TTSchemaSyncableInstance<IOrganizationRoleUser>('organization_role_users', schemaOrganizationRoleUser, 0);
