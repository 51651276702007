import React, { useState, useEffect } from 'react';
import { Checkbox } from './checkbox';

interface CheckboxItem {
  label: string;
  checked: boolean;
}

export type CheckboxItems = Record<string, CheckboxItem>;

export interface CheckboxGroupProps {
  label?: string;
  items: CheckboxItems;
  onChange: (updatedItems: CheckboxItems) => void;
  columns?: number;
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const [groupItems, setGroupItems] = useState<CheckboxItems>(props.items);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setGroupItems(props.items);
  }, [props.items]);

  useEffect(() => {
    setSelectAll(Object.values(groupItems).every(item => item.checked));
  }, [groupItems]);

  const handleSelectAll = (checked: boolean) => {
    const updatedItems: CheckboxItems = Object.fromEntries(
      Object.entries(groupItems).map(([value, item]) => [value, { ...item, checked }])
    );
    setGroupItems(updatedItems);
    props.onChange(updatedItems);
  };

  const handleItemToggle = (value: string, checked: boolean) => {
    const updatedItems: CheckboxItems = {
      ...groupItems,
      [value]: { ...groupItems[value], checked }
    };
    setGroupItems(updatedItems);
    props.onChange(updatedItems);
  };

  const getGridClasses = () => {
    const baseClasses = "mt-1 grid gap-1 w-full";
    const columns = props.columns || 4;
    return `${baseClasses} grid-cols-1 sm:grid-cols-2 md:grid-cols-${Math.min(columns, 3)} lg:grid-cols-${columns}`;
  };

  return (
    <div>
      {props.label && (
        <Checkbox
          label={props.label}
          checked={selectAll}
          toggle={handleSelectAll}
          labelSemiBold={true}
        />
      )}
      <div className={getGridClasses()}>
        {Object.entries(groupItems).map(([value, item]) => (
          <Checkbox
            key={value}
            label={item.label}
            checked={item.checked}
            toggle={(checked) => handleItemToggle(value, checked)}
          />
        ))}
      </div>
    </div>
  );
};