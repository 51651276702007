
interface Crumb {
  id: string,
  text: string,
  onClick: (crumb: Crumb) => void
}

interface BreadcrumbsProps {
  crumbs: Crumb[]
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  return (
    <div className="text-sm breadcrumbs flex">
      <ul className="flex flex-wrap">
        {props.crumbs.map((crumb) => {
          return <li key={crumb.id + crumb.text}><a onClick={(a) => crumb.onClick(crumb)}>{crumb.text}</a></li>
        })}
      </ul>
    </div>
  )
}
