import { useContext, useState } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { IReport } from "vigil-datamodel";

interface ModalReportDeleteProps extends ModalProps {
  reports: IReport[];
  onSubmit?: () => Promise<void>;
}

export const ModalReportDelete: React.FC<ModalReportDeleteProps> = (props) => {
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  /* DB Functions */
  async function deleteReports() {
    try {
      setError('');
      setSubmitLoading(true);
      await vigil.functions.reportDelete({ uuids: props.reports.map(report => report.uuid) });
      props.onSubmit && await props.onSubmit();
      props.toggle();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-128">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Delete {props.reports.length > 1 ? 'Reports' : 'Report'}</h3>
      <div>Are you sure you want to delete the selected {props.reports.length > 1 ? 'Reports' : 'Report'}? This will:</div>
      <ul className="list-disc list-inside">
        <li className="font-semibold">Delete the following {props.reports.length > 1 ? 'reports' : 'report'}:</li>
        <ul className="list-decimal list-inside pl-6">
          {props.reports.map(site => <li key={site.uuid}>{site.name}</li>)}
        </ul>
        <li>Delete the {props.reports.length > 1 ? 'reports' : 'report'} <b>PERMANENTLY</b>, and cannot be undone.</li>
      </ul>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Delete' loading={stateSubmitLoading} disabled={false} type='btn-error' onClick={async () => { await deleteReports() }}></InputButton>
      </div>
    </Modal>
  )
}