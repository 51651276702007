import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";


export interface IDashActivityLogUserLoginLogout {
  userUuid: string,
}

export interface IDashActivityLogOrgLoginLogout {
  orgUuid: string,
}

export interface IDashActivityOrgSwitch {
  fromUuid: string,
  toUuid: string,
}

export interface IDashActivityLog extends IBaseSyncable {
  uuidSession: string;
  type: "userLogin" | "userLogout" | "orgLogin" | "orgLogout" | "orgSwitch",
  value: IDashActivityLogUserLoginLogout | IDashActivityLogOrgLoginLogout | IDashActivityOrgSwitch
}

export const schemaDashActivityLog: TTSchema<IDashActivityLog> = {
  ...schemaBaseSyncable,
  uuidSession: {
    type: String,
    indexed: true,
  },
  type: {
    type: String,
    indexed: true,
  },
  value: {
    type: Object,
    indexed: true,
  },
}

export const schemaInstanceDashActivityLog = new TTSchemaSyncableInstance<IDashActivityLog>('dash_activity_log', schemaDashActivityLog, 0);
