import React from 'react';

interface InputFormProps {
  children?: React.ReactNode;
  onSubmit?: () => void;
}

export const InputForm: React.FC<InputFormProps> = (props) => {
  return (
    <form onSubmit={(event) => { event.preventDefault(); props.onSubmit && props.onSubmit() }}>
      {props.children}
      <button type='submit' className='invisible'></button>
    </form>
  );
};
