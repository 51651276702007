import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";


export interface IDeviceActivityLogUserLoginLogout {
  userUuid: string,
}

export interface IDeviceActivityLogOrgLoginLogout {
  orgUuid: string,
}

export interface IDeviceActivityLogVersionUpdate {
  version: string,
}

export interface IDeviceActivityLog extends IBaseSyncable {
  uuidDevice: string;
  type: "userLogin" | "userLogout" | "orgLogin" | "orgLogout" | "versionUpdate",
  value: IDeviceActivityLogUserLoginLogout | IDeviceActivityLogOrgLoginLogout | IDeviceActivityLogVersionUpdate
}

export const schemaDeviceActivityLog: TTSchema<IDeviceActivityLog> = {
  ...schemaBaseSyncable,
  uuidDevice: {
    type: String,
    indexed: true,
  },
  type: {
    type: String,
    indexed: true,
  },
  value: {
    type: Object,
    indexed: true,
  },
}

export const schemaInstanceDeviceActivityLog = new TTSchemaSyncableInstance<IDeviceActivityLog>('device_activity_log', schemaDeviceActivityLog, 0);
