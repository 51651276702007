import { useEffect, useState } from "react";
import { InputBase } from "../../../components/input_base";
import { ActionTriggerId, ActionTriggerSchedule } from "vigil-datamodel/src/bean_action";
import { InputTime, TimeState } from "../../../components/input_time";
import { Occurance, getCronExpression, getOccurance, getTimeState } from "../helper_cron";

interface ActionTriggerScheduleProps {
  trigger?: ActionTriggerSchedule;
  setTrigger: (trigger: ActionTriggerSchedule) => void;
  setValidateForm: (validateForm: string[]) => void;
}

export const ActionTriggerScheduleComponent: React.FC<ActionTriggerScheduleProps> = (props) => {
  const [stateTimePicker, setTimePicker] = useState<TimeState>(props.trigger && props.trigger.cron != '' ?
    getTimeState(props.trigger.cron) : { hour: 5, minute: 0, period: 'AM' });
  const [stateOccurance, setOccurance] = useState<Occurance>(props.trigger && props.trigger.cron != '' ?
    getOccurance(props.trigger.cron) : { MON: true, TUE: true, WED: true, THU: true, FRI: true, SAT: true, SUN: true });

  useEffect(() => {
    props.setTrigger({
      type: ActionTriggerId.Scheduled,
      cron: getCronExpression(stateTimePicker, stateOccurance),
      enabled: true,
    });
    props.setValidateForm(validateForm());
  }, [stateTimePicker, stateOccurance]);

  /* Functions */
  function toggleOccurance(day: keyof Occurance) {
    setOccurance({ ...stateOccurance, [day]: !stateOccurance[day] });
  }

  /* Validations */
  function validateOccurance() {
    if (!stateOccurance.MON && !stateOccurance.TUE && !stateOccurance.WED && !stateOccurance.THU && !stateOccurance.FRI && !stateOccurance.SAT && !stateOccurance.SUN) return ['Need to select atleast one'];
    return [];
  }

  function validateForm() {
    const validateForm: string[] = [];
    return [
      ...validateForm,
      ...validateOccurance(),
    ]
  }

  return (
    <div className="flex flex-wrap">
      <InputTime className='mr-2' labelText='Time' value={stateTimePicker} onChange={setTimePicker} errors={[]} > </InputTime>
      <InputBase labelText='Occurance' errors={validateOccurance()}>
        <div className="w-80 flex flex-wrap">
          {Object.keys(stateOccurance).map((day, index) => {
            return (
              <label className="p-0 mr-1 mb-1 label cursor-pointer" key={index}>
                <input type="checkbox" checked={stateOccurance[day as keyof Occurance]} onChange={() => toggleOccurance(day as keyof Occurance)} className="checkbox checkbox-sm" />
                <div className="mx-0.5"></div>
                <span className="label-text">{day}</span>
              </label>
            )
          })}
        </div>
      </InputBase>
    </div>
  )
}