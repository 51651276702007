import { LngLatLike, LngLatBoundsLike } from 'maplibre-gl';

export interface VigilMapUser {
  name: string
}

export interface VigilMapDeviceHistoryItem {
  lat: number
  lng: number
  acc: number
}

export interface VigilMapDevice {
  uuid: string
  chargeLevel?: number
  chargeState?: 'Charging' | 'Discharging'
  lastSeen?: Date
  lat: number
  lng: number
  user?: VigilMapUser
  history?: VigilMapDeviceHistoryItem[]
}

export interface VigilMapBeacon {
  uuid: string
  name: string
  lat: number
  lng: number
  color?: 'red' | 'green' | 'blue' | 'yellow' | 'orange' | 'purple'
}

export enum VigilMapV2ThemeId {
  BASIC = 'BASIC',
  DARK_MATTER = 'DARK_MATTER'
}

export interface VigilMapAgentInfo {
  lat: number
  lng: number
}

export interface VigilMapV2Props {
  sourceURL?: string,
  theme?: VigilMapV2ThemeId,
  beacons?: VigilMapBeacon[],
  devices?: VigilMapDevice[],
  users?: VigilMapUser[],
  center?: LngLatLike,
  zoom?: number,
  maxBounds?: LngLatBoundsLike,
  zoomControl?: boolean,
  agentInfo?: VigilMapAgentInfo | null
}
