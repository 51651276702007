import { useState, useEffect } from 'react';
import { useInterval } from './hook_interval';
import { sleep } from 'tt-coms';

type FetchFunction<T> = () => Promise<T>;

interface UseStateLiveResult<T> {
  stateResult: T | null;
  stateLoading: boolean;
  stateError: string;
  refetch: () => Promise<void>;
}

export const useStateLive = <T>(fetchFunction: FetchFunction<T>, dependencies: any[], intervalTime?: number, onUpdate?: () => void): UseStateLiveResult<T> => {
  const [stateResult, setResult] = useState<T | null>(null);
  const [stateLoading, setLoading] = useState<boolean>(true);
  const [stateError, setError] = useState<string>("");

  const fetchDataBackground = async () => {
    const data = await fetchFunction();
    // if (JSON.stringify(data) !== JSON.stringify(stateResult)) {
      setResult(data);
      onUpdate && onUpdate();
    // }
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      await fetchDataBackground()
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, dependencies);

  if (intervalTime) {
    useInterval(fetchDataBackground, intervalTime, false);
  }

  return { stateResult, stateLoading, stateError, refetch: fetchData };
};
