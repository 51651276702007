import React, { useContext, useEffect, useRef, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IUserInviteDirectory, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { TTOnlyOneKey } from 'tt-coms/src/query';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/hook_modal';
import { escapeRegExp } from './helper_home';
import { ModalUserInviteDelete } from './func_user_invite/modal_user_invite_delete';
import { TTActions } from 'tt-permissions';
import { ContextRoles } from '../../providers/provider_roles';
import { FilterQuery } from "mongoose";
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { useInterval } from '../../hooks/hook_interval';


interface TabUsersInviteProps { }

export const TabUsersInvite: React.FC<TabUsersInviteProps> = (props) => {
  const contextOrganization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);

  const [stateUserInvites, setUserInvites] = useState([] as IUserInviteDirectory[]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState([] as QueryFilter[]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState({ id: '', direction: 'ascending' } as Sorting);
  const [stateTablePagingSelected, setTablePagingSelected] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 } as Paging);

  const [ticker, setTicker] = useState(0);

  const { isOpen: isOpenModalUserInviteDelete, toggle: toggleModalUserInviteDelete } = useModal();

  const [selectedUserInvitesState, setUserSelectedInvites] = useState<IUserInviteDirectory[]>([]);

  /* Getters */
  async function fetchUserInvitesBackground() {
    if (!contextOrganization) { return }

    // Filter handling
    let matchUserInviteDirectory: FilterQuery<IUserInviteDirectory> = {};
    for (const filter of stateTableFiltersSelected) {
      if (filter.query == 'contains' && filter.id === 'invitee_contact') {
        matchUserInviteDirectory.inviteeContact = { $regex: escapeRegExp(filter.value.toString()), $options: 'i' }
      }
      if (filter.query == 'between' && (filter.id === 'date_created' || filter.id === 'last_updated')) {
        const startDate = new Date((filter.value.valueOf() as any)['startDate'])
        const endDate = new Date((filter.value.valueOf() as any)['endDate'])
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 59, 999)
        const startUuid = TTuuid.getCuuidFromValues(startDate, 0)
        const endUuid = TTuuid.getCuuidFromValues(endDate, 0)
        if (filter.id === 'date_created') { matchUserInviteDirectory.uuid = { $gte: startUuid, $lte: endUuid } }
        if (filter.id === 'last_updated') { matchUserInviteDirectory.changeStamp = { $gte: startUuid, $lte: endUuid } }
      }
    }

    // Sort handling
    let sort: TTOnlyOneKey<IUserInviteDirectory, 1 | -1> = { inviteeContact: 1 };
    const sortDirection = stateTableSortingSelected.direction === 'ascending' ? 1 : -1;
    if (stateTableSortingSelected.id === 'invitee_contact') { sort = { inviteeContact: sortDirection } }
    if (stateTableSortingSelected.id === 'invite_type') { sort = { inviteType: sortDirection } }
    if (stateTableSortingSelected.id === 'date_created') { sort = { uuid: sortDirection } }
    if (stateTableSortingSelected.id === 'last_updated') { sort = { changeStamp: sortDirection } }

    const response = await vigil.functions.userInviteDirectory({
      uuidOrganization: contextOrganization.uuid,
      filter: matchUserInviteDirectory,
      sort,
      skip: (stateTablePagingSelected.currentPage - 1) * stateTablePagingSelected.itemsPerPage,
      limit: stateTablePagingSelected.itemsPerPage
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateUserInvites)) {
      setUserInvites(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: Math.ceil(response.total / response.limit), totalItems: response.total });
    }
  }
  async function fetchUserInvites() {
    try {
      setLoading(true);
      fetchUserInvitesBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update immediately
  useEffect(() => {
    fetchUserInvites();
  }, [contextOrganization, ticker])

  // Update every 5 seconds
  const interval = useInterval(async () => {
    await fetchUserInvitesBackground();
  }, 5000, false);

  function deleteUserInvites(selectedUserInvites: IUserInviteDirectory[]) {
    setUserSelectedInvites(selectedUserInvites)
    toggleModalUserInviteDelete();
  }

  return (
    <div className='pt-2 h-full w-full'>
      <ModalUserInviteDelete isOpen={isOpenModalUserInviteDelete} toggle={toggleModalUserInviteDelete} onSubmit={async () => await fetchUserInvites()} userInvites={selectedUserInvitesState} />

      <Table<IUserInviteDirectory>
        className='h-full flex flex-col drop-shadow'
        name='Invites'
        data={stateUserInvites}
        selectable={true}
        loading={stateLoading}
        error={stateError}
        columns={[
          {
            id: 'invitee_contact',
            header: 'Invitee Contact',
            sortable: true,
            value(item) {
              return <div>{item.inviteeContact}</div>;
            },
          },
          {
            id: 'invite_type',
            header: 'Invite Type',
            sortable: true,
            value(item) {
              return <div>{item.inviteType}</div>;
            },
          },
          {
            id: 'date_created',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'last_updated',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('invitee_contact', 'Invitee Contact', 'contains'),
          new DateFilter('date_created', 'Date Created', 'between'),
          new DateFilter('last_updated', 'Last Updated', 'between'),
        ]}
        filtersSelected={stateTableFiltersSelected}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1) }}

        sortingOptions={null}
        sortingSelected={stateTableSortingSelected}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1) }}

        pagingOptions={null}
        pagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1) }}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RUserInvite),
            onClick: (selectedItems) => deleteUserInvites(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RUserInvite),
            onClick: (selectedItems) => deleteUserInvites(selectedItems)
          }
        ]}
      />
    </div >
  );
};
