import { TTSchema } from "./schema.js";

export interface IBase {
  uuid: string;
}

export const schemaBase : TTSchema<IBase> = {
  uuid: {
    type: String,
    primary: true,
    indexed: true,
  },
}

// We don't createa an instance, because we don't actually store this bean, it's a building block
