import { ReactElement, createContext, useContext, useEffect } from "react";
import { Preferences } from "../preference_keys";
import { ContextVigilClient } from "./provider_vigil_client";
import { IDashActivityLog, IDashActivityLogUserLoginLogout, IDashActivityLogOrgLoginLogout, IDashActivityOrgSwitch } from "vigil-datamodel";
import { TTuuid } from "tt-uuid";

interface ISessionContext {
  logEvent<T extends IDashActivityLogUserLoginLogout | IDashActivityLogOrgLoginLogout | IDashActivityOrgSwitch>(type: IDashActivityLog['type'], value: T): void;
}

interface ProviderSessionProps {
  children: ReactElement
}

export const ContextSession = createContext(null as null | ISessionContext);
export const ProviderSession: React.FC<ProviderSessionProps> = (props: ProviderSessionProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);

  const getBrowserName = () => {
    const userAgent = navigator.userAgent;
    let browserName = "Unknown";

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "Firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "Safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "Opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "Edge";
    } else if (userAgent.match(/msie|trident/i)) {
      browserName = "Internet Explorer";
    }

    return browserName;
  };

  const getOSName = () => {
    const userAgent = navigator.userAgent;
    let osName = "Unknown";

    if (userAgent.match(/win/i)) {
      osName = "Windows";
    } else if (userAgent.match(/mac/i)) {
      osName = "MacOS";
    } else if (userAgent.match(/linux/i)) {
      osName = "Linux";
    } else if (userAgent.match(/iphone|ipad|ipod/i)) {
      osName = "iOS";
    } else if (userAgent.match(/android/i)) {
      osName = "Android";
    }

    return osName;
  };

  async function fetchDashSessionUuid() {
    const uuidSession = (await vigil.getPreference(Preferences.UUID_SESSION)) ?? TTuuid.getCuuid();
    await vigil.collectionDashSessions.upsertOne(
      { uuidSession },
      { uuidSession, browserType: getBrowserName(), operatingSystem: getOSName() });
    await vigil.setPreference(Preferences.UUID_SESSION, uuidSession);
    return uuidSession;
  }

  async function sendDashStateLog() {
    try {
      const sessionUuid = await fetchDashSessionUuid();
      const connection = (navigator as any)['connection'] || null;

      await vigil.collectionDashStateLogs.createOne({
        uuidSession: sessionUuid,
        effectiveType: connection?.effectiveType,
        roundTripTime: connection?.rtt,
        downlink: connection?.downlink,
        status: navigator.onLine ? 'online' : 'offline'
      });
    }
    catch (error: any) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    const intervalId = setInterval(sendDashStateLog, 1000 * 60);
    return () => { clearInterval(intervalId) };
  }, []);

  const logEvent: ISessionContext['logEvent'] = async (type, value) => {
    try {
      const sessionUuid = await fetchDashSessionUuid();
      await vigil.collectionDashActivityLogs.createOne({
        type: type,
        uuidSession: sessionUuid,
        value: value
      });
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ContextSession.Provider value={{ logEvent }}>
      {props.children}
    </ContextSession.Provider>
  )
}
