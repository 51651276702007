import React from 'react';
import { InputBase, InputBaseProps } from './input_base';
import { InputButton, InputButtonProps } from './input_button';

interface InputTextWithButtonProps extends InputBaseProps {
  propsInputText: {
    value: string | number,
    placeholder?: string,
    disabled?: boolean,
    onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void,
  },
  propsInputButton: InputButtonProps,
}

export const InputTextWithButton: React.FC<InputTextWithButtonProps> = (props) => {
  function styleErrors() {
    return props.errors.length ? 'input-error' : '';
  }

  return (
    <div>
      <InputBase {...props}>
        <div className='join w-full '>
          <input
            className={`input input-bordered w-full join-item ${styleErrors()}`}
            {...props.propsInputText}
          />
          <InputButton {...props.propsInputButton} styles={['join-item']} />
        </div>
      </InputBase>
    </div>
  );
};
