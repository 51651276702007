import { TTFunction, TTOnlyOneKey, TTPaginationObject, TTComsCleanCreate, TTComsCleanDelete, TTComsCleanUpdate } from 'tt-coms';
import { androidmanagement_v1 } from 'googleapis';
import { FilterQuery, SortOrder } from "mongoose";

import { TTFunctionObject } from 'tt-coms';
import { IUser } from './bean_user.js';
import { IUserOrganizationInvite } from './bean_user_organization_invite.js';
import { IOrganization } from './bean_organization.js';
import { IOrganizationRole } from './bean_organization_role.js';
import { IUserOrganization } from './bean_user_organization.js';
import { VigilChat } from './type_chat.js';
import { ISite } from './bean_site.js';
import { IBeacon } from './bean_beacon.js';
import { IEvent } from './bean_event.js';
import { IScript } from './bean_script.js';
import { IBeaconOrganization } from './bean_beacon_organization.js';
import { IDevice } from './bean_device.js';
import { IDeviceOrganization } from './bean_device_organization.js';
import { IDeviceUser } from './bean_device_user.js';
import { INotification, INotificationPanic } from './bean_notification.js';
import { INotificationLog } from './bean_notification_log.js';
import { IDeviceActivityLog } from './bean_device_activity_log.js';
import { IBeaconSite } from './bean_beacon_site.js';
import { ISiteOrganization } from './bean_site_organization.js';
import { IEventInstance } from './bean_event_instance.js';
import { IEventInstanceLog } from './bean_event_log.js';
import { IEventOrganization } from './bean_event_organization.js';
import { IOrganizationRoleUser } from './bean_organization_role_user.js';
import { IDeviceStateLog } from './bean_device_state_log.js';
import { IAction } from './bean_action.js';
import { IReport } from './bean_report.js';

export { type IVigilToken, tokenDecoder, tokenValidator } from './token.js'
export { type IIdentity } from './bean_identity.js';
export { type IAuthChallenge } from './bean_auth_challenge.js';
export { type IUser } from './bean_user.js';
export { type IUserOrganizationInvite } from './bean_user_organization_invite.js';
export { type IOrganization } from './bean_organization.js';
export { type IOrganizationRole } from './bean_organization_role.js';
export { type IOrganizationRoleUser } from './bean_organization_role_user.js';
export { type IUserOrganization } from './bean_user_organization.js';
export { type ISite } from './bean_site.js';
export { type ISiteOrganization } from './bean_site_organization.js';
export { type IBeacon } from './bean_beacon.js';
export { type IBeaconSite } from './bean_beacon_site.js';
export { type IBeaconOrganization } from './bean_beacon_organization.js';
export { type IEvent } from './bean_event.js';
export { type IEventOrganization } from './bean_event_organization.js';
export { type IScript } from './bean_script.js';
export { type IChart } from './bean_chart.js';
export { type IDevice } from './bean_device.js';
export { type IDeviceOrganization } from './bean_device_organization.js';
export { type IDeviceUser } from './bean_device_user.js';
export { type IEventInstance } from './bean_event_instance.js';

// Query types
export interface IPagination {
  page: number,
  count: number
}

export interface IOrdering {
  field: string,
  direction: 'ascending' | 'descending'
}

export interface IPaginatedResponse<T> {
  items: T[],
  pagination: {
    pages: number,
    count: number
  }
}

// User types
export type IUserCreate = TTComsCleanCreate<IUser>;
export type IUserUpdate = TTComsCleanUpdate<IUser>;
export type IUserDelete = TTComsCleanDelete<IUser>;
export type IUserDirectory = IUser & { roleCount: number, roles: IOrganizationRole[] };
export type IUserInviteDirectory = IUserOrganizationInvite & { inviteType: string, inviteeContact: string };

// Organization types
export type IOrganizationCreate = TTComsCleanCreate<IOrganization>;
export type IOrganizationUpdate = TTComsCleanUpdate<IOrganization>;
export type IOrganizationDelete = TTComsCleanDelete<IOrganization>;

// Site types
export type ISiteCreate = TTComsCleanCreate<ISite>;
export type ISiteUpdate = TTComsCleanUpdate<ISite>;
export type ISiteDelete = TTComsCleanDelete<ISite>;
export type ISiteDirectory = ISite & { beaconCount: number, beacons: IBeacon[] };

// Beacon types
export type IBeaconCreate = TTComsCleanCreate<IBeacon> & { uuid?: string };
export type IBeaconUpdate = TTComsCleanUpdate<IBeacon>;
export type IBeaconDelete = TTComsCleanDelete<IBeacon>;
export type IBeaconDirectory = IBeacon & { siteCount: number, sites: ISite[] };

// Event types
export type IEventCreate = TTComsCleanCreate<IEvent>;
export type IEventUpdate = TTComsCleanUpdate<IEvent>;
export type IEventDelete = TTComsCleanDelete<IEvent>;
export type IEventDirectory = IEvent;

// Script types
export type IScriptCreate = TTComsCleanCreate<IScript>;
export type IScriptUpdate = TTComsCleanUpdate<IScript>;
export type IScriptDelete = TTComsCleanDelete<IScript>;

// Role types
export type IRoleCreate = TTComsCleanCreate<IOrganizationRole>;
export type IRoleUpdate = TTComsCleanUpdate<IOrganizationRole>;
export type IRoleDelete = TTComsCleanDelete<IOrganizationRole>;
export type IRoleDirectory = IOrganizationRole & { userCount: number, users: IUser[] };

// Action types
export type IActionCreate = TTComsCleanCreate<IAction>;
export type IActionUpdate = TTComsCleanUpdate<IAction>;
export type IActionDelete = TTComsCleanDelete<IAction>;
export type IActionDirectory = IAction;

// Report types
export type IReportCreate = TTComsCleanCreate<IReport>;
export type IReportUpdate = TTComsCleanUpdate<IReport>;
export type IReportDelete = TTComsCleanDelete<IReport>;
export type IReportDirectory = IReport;

// Device types
export type IDeviceCreate = TTComsCleanCreate<IDevice>;

// Device state log types
export type IDeviceStateLogCreate = TTComsCleanCreate<IDeviceStateLog>;

// User Invite types
export type IUserOrganizationInviteCreate = TTComsCleanCreate<IUserOrganizationInvite>;

// Device types
export type IDeviceDirectory = IDevice;

// Notification types
export type INotificationDirectory = INotification & { userReceiveCount: number, userDismissedCount: number };

// Notification Log types
export type INotificationLogCreate = TTComsCleanCreate<INotificationLog>;

// Android Management types
export enum AndroidManagementPolicyNames {
  DedicatedGuard = 'dedicated_guard',
  DedicatedSetup = 'dedicated_setup'
}

// Device Org Sync
export type IDeviceOrgDataSyncServerstamps = {
  newestServerStamp?: string;
}

export type IDeviceOrgDataSyncInput = {
  userOrgLinks?: IDeviceOrgDataSyncServerstamps;
  deviceOrgLinks?: IDeviceOrgDataSyncServerstamps;
  beaconOrgLinks?: IDeviceOrgDataSyncServerstamps;
  beaconSiteLinks?: IDeviceOrgDataSyncServerstamps;
  siteOrgLinks?: IDeviceOrgDataSyncServerstamps;
  eventOrgLinks?: IDeviceOrgDataSyncServerstamps;
  roleOrgLinks?: IDeviceOrgDataSyncServerstamps;
  roleOrgUserLinks?: IDeviceOrgDataSyncServerstamps;

  users?: IDeviceOrgDataSyncServerstamps;
  devices?: IDeviceOrgDataSyncServerstamps;
  beacons?: IDeviceOrgDataSyncServerstamps;
  sites?: IDeviceOrgDataSyncServerstamps;
  events?: IDeviceOrgDataSyncServerstamps;
}

export type IDeviceOrgDataSyncReturn = {
  userOrgLinks?: IUserOrganization[];
  deviceOrgLinks?: IDeviceOrganization[];
  beaconOrgLinks?: IBeaconOrganization[];
  beaconSiteLinks?: IBeaconSite[];
  siteOrgLinks?: ISiteOrganization[];
  eventOrgLinks?: IEventOrganization[];
  roleOrgLinks?: IOrganizationRole[];
  roleOrgUserLinks?: IOrganizationRoleUser[];

  users?: IUser[];
  devices?: IDevice[];
  beacons?: IBeacon[];
  sites?: ISite[];
  events?: IEvent[];
}

export type IProfileResult = {
  organizations: IOrganization[],
  organizationInvites: { invite: IUserOrganizationInvite, organization: IOrganization }[];
}

export type DeviceHistoryItem = {
  date: Date,
  chargeLevel: number,
  chargeState: "Charging" | "Discharging",
  lat: number,
  lng: number,
  acc: number,
}

export enum TimeFrame {
  Minute15 = '15m',
  Hour1 = '1h',
  Hour6 = '6h',
  Day1 = '1d',
  Day3 = '3d',
  Week1 = '1w',
  Week2 = '2w',
}

export type IDeviceUserPair = {
  device: IDevice,
  deviceStateLog: IDeviceStateLog,
  user?: IUser
}

export type IMapStateOrganization = {
  beacons?: IBeacon[]
  devices?: IDeviceUserPair[]
}

export type IMapState = {
  organizations: IMapStateOrganization[]
}

export interface VigilContextClient {
  token: string,
}

export class VigilFunctionsSchema {
  ping = TTFunction<{}, string>();

  // Correct
  signInRequest = TTFunction<{ target: { email?: string; mobile?: string } }, void>();
  signInConfirm = TTFunction<{ target: string, value: string }, { token: string, users: IUser[], userOrganizations: IUserOrganization[], organizations: IOrganization[] }>();

  // Android Management
  androidManagementInvite = TTFunction<{ secret: string, source: 'url' | 'image' | 'data', policy: AndroidManagementPolicyNames }, string>();
  androidManagementDevices = TTFunction<{ secret: string, serialNumbers?: string[] }, androidmanagement_v1.Schema$Device[]>();
  androidManagementSyncDevices = TTFunction<{}, void>();
  androidManagementDeprovisionDevices = TTFunction<{ secret: string, deviceNames: string[] }, string>();
  androidManagementChangePolicy = TTFunction<{ secret: string, deviceNames: string[], toPolicy: AndroidManagementPolicyNames }, string>();

  // Payfast
  addCard = TTFunction<{ target: { uuid: string; type: 'organization' | 'user'; returnUrl: string; cancelUrl: string } }, string>();
  updateCard = TTFunction<{ target: { uuid: string; type: 'organization' | 'user'; returnUrl: string } }, string>();

  // User
  userDirectory = TTFunction<{ uuidOrganization: string, filter: FilterQuery<IUserDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IUserDirectory>>();
  userLinkedTo = TTFunction<{ uuid: string, type: 'organization' | 'role' }, IUser[]>();
  userUnlinkedTo = TTFunction<{ uuidOrganization: string, uuid: string, type: 'role' }, IUser[]>();
  userFindOnePublic = TTFunction<{ filter: FilterQuery<IUser> }, IUser>();
  userFindOne = TTFunction<{ filter: FilterQuery<IUser> }, IUser>();
  userFindManyPublic = TTFunction<{ filter: FilterQuery<IUser>, sort?: TTOnlyOneKey<IUser, SortOrder>, skip?: number, limit?: number }, IUser[]>();
  userFindMany = TTFunction<{ filter: FilterQuery<IUser>, sort?: TTOnlyOneKey<IUser, SortOrder>, skip?: number, limit?: number }, IUser[]>();
  userCreate = TTFunction<{ input: IUserCreate }, IUser>();
  userUpdate = TTFunction<{ uuidUser: string; input: IUserUpdate }, IUser>();
  userUpdateEmailRequest = TTFunction<{ uuidUser: string; email: string }, boolean>();
  userUpdateEmailConfirm = TTFunction<{ uuidUser: string; email: string; otp: string }, IUser>();
  userUpdateMobileRequest = TTFunction<{ uuidUser: string; mobile: string }, boolean>();
  userUpdateMobileConfirm = TTFunction<{ uuidUser: string; mobile: string; otp: string }, IUser>();
  userDelete = TTFunction<{ uuids: string[] }, void>();

  // User Invite
  userInviteDirectory = TTFunction<{ uuidOrganization: string, filter: FilterQuery<IUserInviteDirectory>, sort: TTOnlyOneKey<IUserInviteDirectory, 1 | -1>, skip: number, limit: number }, TTPaginationObject<IUserInviteDirectory>>();

  // User - Organization Link
  userOrganizationFindOne = TTFunction<{ filter: FilterQuery<IUserOrganization> }, IUserOrganization>();
  userOrganizationFindMany = TTFunction<{ filter: FilterQuery<IUserOrganization>, sort?: TTOnlyOneKey<IUserOrganization, SortOrder>, skip?: number, limit?: number }, IUserOrganization[]>();
  userOrganizationLeave = TTFunction<{ uuidOrganization: string }, boolean>();
  // userOrganizationInviteFindOne = TTFunction<{ filter: FilterQuery<IUserOrganizationInvite> }, IUserOrganizationInvite>();
  // userOrganizationInviteFindMany = TTFunction<{ filter: FilterQuery<IUserOrganizationInvite>, sort?: TTOnlyOneKey<IUserOrganizationInvite, SortOrder>, skip?: number, limit?: number }, IUserOrganizationInvite[]>();
  userOrganizationInviteCreate = TTFunction<{ inviteInfo: IUserOrganizationInviteCreate }, IUserOrganizationInvite>();
  userOrganizationInviteDelete = TTFunction<{ uuids: string[] }, void>();
  userOrganizationInviteAccept = TTFunction<{ uuidUserOrganizationInvite: string }, boolean>();
  userOrganizationInviteReject = TTFunction<{ uuidUserOrganizationInvite: string }, boolean>();

  // Organization
  organizationFindOne = TTFunction<{ filter: FilterQuery<IOrganization> }, IOrganization>();
  organizationFindManyPublic = TTFunction<{ filter: FilterQuery<IOrganization>, sort?: TTOnlyOneKey<IOrganization, SortOrder>, skip?: number, limit?: number }, IOrganization[]>();
  organizationFindMany = TTFunction<{ filter: FilterQuery<IOrganization>, sort?: TTOnlyOneKey<IOrganization, SortOrder>, skip?: number, limit?: number }, IOrganization[]>();
  organizationCreate = TTFunction<{ input: IOrganizationCreate }, { organization: IOrganization; link: IUserOrganization }>();
  organizationUpdate = TTFunction<{ uuidOrganization: string; input: IOrganizationUpdate }, IOrganization>();

  // Site
  siteDirectoryPublic = TTFunction<{ uuidOrganization: string, filter: FilterQuery<ISiteDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<ISiteDirectory>>();
  siteDirectory = TTFunction<{ uuidOrganization: string, filter: FilterQuery<ISiteDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<ISiteDirectory>>();
  beaconLinkedToPublic = TTFunction<{ uuidSite: string }, IBeacon[]>();
  beaconLinkedTo = TTFunction<{ uuidSite: string }, IBeacon[]>();
  beaconUnlinkedTo = TTFunction<{ uuidOrganization: string, uuidSite: string }, IBeacon[]>();
  siteLinkedTo = TTFunction<{ uuid: string, type: 'organization' | 'beacon' }, ISite[]>();
  siteUnlinkedTo = TTFunction<{ uuidOrganization: string, uuidLinkedSites: string[] }, ISite[]>();
  siteFindOnePublic = TTFunction<{ filter: FilterQuery<ISite> }, ISite>();
  siteFindOne = TTFunction<{ filter: FilterQuery<ISite> }, ISite>();
  siteFindManyPublic = TTFunction<{ filter: FilterQuery<ISite>, sort?: TTOnlyOneKey<ISite, SortOrder>, skip?: number, limit?: number }, ISite[]>();
  siteFindMany = TTFunction<{ filter: FilterQuery<ISite>, sort?: TTOnlyOneKey<ISite, SortOrder>, skip?: number, limit?: number }, ISite[]>();
  siteCreate = TTFunction<{ uuidOrganization: string; siteInfo: ISiteCreate }, ISite>();
  siteUpdate = TTFunction<{ uuid: string; siteInfo: ISiteUpdate }, void>();
  siteDelete = TTFunction<{ uuids: string[] }, void>();

  // Beacon
  beaconDirectory = TTFunction<{ uuidOrganization: string, filter: FilterQuery<IBeaconDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IBeaconDirectory>>();
  beaconFindOne = TTFunction<{ filter: FilterQuery<IBeacon> }, IBeacon>();
  beaconFindMany = TTFunction<{ filter: FilterQuery<IBeacon>, sort?: TTOnlyOneKey<IBeacon, SortOrder>, skip?: number, limit?: number }, IBeacon[]>();
  beaconCreate = TTFunction<{ uuidOrganization: string; beaconInfo: IBeaconCreate }, IBeacon>();
  beaconUpdate = TTFunction<{ uuid: string; beaconInfo: IBeaconUpdate }, void>();
  beaconDelete = TTFunction<{ uuids: string[] }, void>();

  // Beacon - Organization Link 
  beaconOrganizationFindOne = TTFunction<{ filter: FilterQuery<IBeaconOrganization> }, IBeaconOrganization>();
  beaconOrganizationFindMany = TTFunction<{ filter: FilterQuery<IBeaconOrganization>, sort?: TTOnlyOneKey<IBeaconOrganization, SortOrder>, skip?: number, limit?: number }, IBeaconOrganization[]>();

  // Beacon - Site Link
  beaconSitesLinkCreate = TTFunction<{ uuidBeacon: string; uuidSites: string[] }, void>();
  siteBeaconsLinkCreate = TTFunction<{ uuidSite: string; uuidBeacons: string[] }, void>();
  beaconSitesLinkDelete = TTFunction<{ uuidBeacon: string; uuidSites: string[] }, void>();
  siteBeaconsLinkDelete = TTFunction<{ uuidSite: string; uuidBeacons: string[] }, void>();

  // Event
  eventDirectoryPublic = TTFunction<{ uuidOrganization: string, filter: FilterQuery<IEventDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IEvent>>();
  eventDirectory = TTFunction<{ uuidOrganization: string, filter: FilterQuery<IEventDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IEvent>>();
  eventFindOnePublic = TTFunction<{ filter: FilterQuery<IEvent> }, IEvent>();
  eventFindOne = TTFunction<{ filter: FilterQuery<IEvent> }, IEvent>();
  eventFindManyPublic = TTFunction<{ filter: FilterQuery<IEvent>, sort?: TTOnlyOneKey<IEvent, SortOrder>, skip?: number, limit?: number }, IEvent[]>();
  eventFindMany = TTFunction<{ filter: FilterQuery<IEvent>, sort?: TTOnlyOneKey<IEvent, SortOrder>, skip?: number, limit?: number }, IEvent[]>();
  eventCreate = TTFunction<{ uuidOrganization: string; eventInfo: IEventCreate }, IEvent>();
  eventUpdate = TTFunction<{ uuid: string; eventInfo: IEventUpdate }, void>();
  eventDelete = TTFunction<{ uuids: string[] }, void>();
  eventLinkedTo = TTFunction<{ uuid: string, type: 'organization' }, IEvent[]>();

  // Event - Site Link
  eventConfigPatrolSitesLinkCreate = TTFunction<{ uuidEvent: string; uuidSites: string[] }, void>();
  eventConfigPatrolSitesLinkDelete = TTFunction<{ uuidEvent: string; uuidSites: string[] }, void>();

  // Script
  scriptCreate = TTFunction<{ scriptInfo: IScriptCreate }, IScript>();
  scriptUpdate = TTFunction<{ uuid: string; scriptInfo: IScriptUpdate }, void>();
  scriptDelete = TTFunction<{ uuids: string[] }, void>();

  // Role
  roleDirectory = TTFunction<{ uuidOrganization: string, filter: FilterQuery<IRoleDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IRoleDirectory>>();
  roleLinkedToPublic = TTFunction<{ uuidOrganization?: string, uuidUser?: string }, IOrganizationRole[]>();
  roleLinkedTo = TTFunction<{ uuidOrganization?: string, uuidUser?: string }, IOrganizationRole[]>();
  roleUnlinkedTo = TTFunction<{ uuidOrganization: string, uuid: string, type: 'user' }, IOrganizationRole[]>();
  roleFindOne = TTFunction<{ filter: FilterQuery<IOrganizationRole> }, IOrganizationRole>();
  roleFindManyPublic = TTFunction<{ filter: FilterQuery<IOrganizationRole>, sort?: TTOnlyOneKey<IOrganizationRole, SortOrder>, skip?: number, limit?: number }, IOrganizationRole[]>();
  roleFindMany = TTFunction<{ filter: FilterQuery<IOrganizationRole>, sort?: TTOnlyOneKey<IOrganizationRole, SortOrder>, skip?: number, limit?: number }, IOrganizationRole[]>();
  roleCreate = TTFunction<{ uuidOrganization: string; roleInfo: IRoleCreate }, IOrganizationRole>();
  roleUpdate = TTFunction<{ uuid: string; roleInfo: IRoleUpdate }, void>();
  roleDelete = TTFunction<{ uuids: string[] }, void>();

  // Organization Role User
  organizationRoleUserFindOne = TTFunction<{ filter: FilterQuery<IOrganizationRoleUser> }, IOrganizationRoleUser>();
  organizationRoleUserFindMany = TTFunction<{ filter: FilterQuery<IOrganizationRoleUser>, sort?: TTOnlyOneKey<IOrganizationRoleUser, SortOrder>, skip?: number, limit?: number }, IOrganizationRoleUser[]>();

  // Role - User Link
  roleUsersLinkCreate = TTFunction<{ uuidOrganization: string; uuidRole: string; uuidUsers: string[] }, void>();
  roleUsersLinkDelete = TTFunction<{ uuidOrganization: string; uuidRole: string; uuidUsers: string[] }, void>();
  userRolesLinkCreate = TTFunction<{ uuidOrganization: string; uuidUser: string; uuidRoles: string[] }, void>();
  userRolesLinkDelete = TTFunction<{ uuidOrganization: string; uuidUser: string; uuidRoles: string[] }, void>();

  // Chat
  chat = TTFunction<{ chatContext: { uuidOrganization: string; uuidUser: string }; chats: VigilChat[] }, VigilChat[]>();

  // Aggregate
  aggregateEventFindMany = TTFunction<{ uuidOrganization: string, filter: FilterQuery<IEvent>, sort: TTOnlyOneKey<IEvent, 1 | -1>, skip: number, limit: number }, TTPaginationObject<IEvent>>();

  aggregateLinkedScript = TTFunction<{ uuidEvent: string }, IScript>();

  // Device
  deviceDirectoryPublic = TTFunction<{ uuidOrganization?: string, filter: FilterQuery<IDeviceDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IDeviceDirectory>>();
  deviceDirectory = TTFunction<{ uuidOrganization?: string, filter: FilterQuery<IDeviceDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IDeviceDirectory>>();
  deviceLinkedTo = TTFunction<{ uuid: string, type: 'organization' }, IDevice[]>();
  deviceFindOnePublic = TTFunction<{ filter: FilterQuery<IDevice> }, IDevice>();
  deviceFindOne = TTFunction<{ filter: FilterQuery<IDevice> }, IDevice>();
  deviceFindManyPublic = TTFunction<{ filter: FilterQuery<IDevice>, sort?: TTOnlyOneKey<IDevice, SortOrder>, skip?: number, limit?: number }, IDevice[]>();
  deviceFindMany = TTFunction<{ filter: FilterQuery<IDevice>, sort?: TTOnlyOneKey<IDevice, SortOrder>, skip?: number, limit?: number }, IDevice[]>();
  deviceDelete = TTFunction<{ uuids: string[] }, void>();
  deviceLink = TTFunction<{ device: IDevice, uuidOrganization: string }, { deviceOrganization: IDeviceOrganization, organization: IOrganization }>();
  deviceUserSignIn = TTFunction<{ uuidDevice: string, uuidOrganization: string, userBiometricPicture: string }, { response: "no_face" | "no_user" | "user", user?: IUser, userOrganization?: IUserOrganization, deviceUser?: IDeviceUser, organizationRoleUsers?: IOrganizationRoleUser[] }>();
  deviceUserSignInLink = TTFunction<{ uuidDevice: string, uuidOrganization: string, userInput: { id: string, biometricPicture: string } }, { response: "no_face" | "no_user" | "user", user?: IUser, userOrganization?: IUserOrganization, deviceUser?: IDeviceUser, organizationRoleUsers?: IOrganizationRoleUser[] }>();
  deviceUserSignUp = TTFunction<{ uuidDevice: string, uuidOrganization: string, userInput: { firstName: string, lastName: string, id: string, biometricPicture: string } }, { user: IUser, userOrganization: IUserOrganization, deviceUser: IDeviceUser, organizationRoleUsers?: IOrganizationRoleUser[] }>();
  deviceUserSignInNoFace = TTFunction<{ uuidDevice: string, uuidOrganization: string, userInput: { id: string } }, { response: "no_user" | "user", user?: IUser, userOrganization?: IUserOrganization, deviceUser?: IDeviceUser, organizationRoleUsers?: IOrganizationRoleUser[] }>();
  deviceUserSignUpNoFace = TTFunction<{ uuidDevice: string, uuidOrganization: string, userInput: { firstName: string, lastName: string, id: string } }, { user: IUser, userOrganization: IUserOrganization, deviceUser: IDeviceUser, organizationRoleUsers?: IOrganizationRoleUser[] }>();
  deviceUserSignOut = TTFunction<{ uuidDevice: string, userSecret: string }, IDeviceUser>();
  deviceOrgDataSync = TTFunction<{ uuidOrganization: string, serverStamps: IDeviceOrgDataSyncInput }, IDeviceOrgDataSyncReturn>();
  deviceStateLogCreate = TTFunction<{ deviceStateLogInfo: IDeviceStateLogCreate }, IDeviceStateLog>();
  deviceStateLogsPublic = TTFunction<{ filter: FilterQuery<IDeviceStateLog>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IDeviceStateLog>>();
  deviceStateLogs = TTFunction<{ filter: FilterQuery<IDeviceStateLog>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IDeviceStateLog>>();
  deviceHistory = TTFunction<{ filter: { uuidDevice: string, timeframe: TimeFrame } }, { items: DeviceHistoryItem[] }>();

  // Device Activity Log
  deviceActivityLogFindOne = TTFunction<{ filter: FilterQuery<IDeviceActivityLog> }, IDeviceActivityLog>();
  deviceActivityLogFindManyPublic = TTFunction<{ filter: FilterQuery<IDeviceActivityLog>, sort?: TTOnlyOneKey<IDeviceActivityLog, SortOrder>, skip?: number, limit?: number }, IDeviceActivityLog[]>();
  deviceActivityLogFindMany = TTFunction<{ filter: FilterQuery<IDeviceActivityLog>, sort?: TTOnlyOneKey<IDeviceActivityLog, SortOrder>, skip?: number, limit?: number }, IDeviceActivityLog[]>();

  // Notification
  notificationDirectory = TTFunction<{ uuidOrganization: string, filter: FilterQuery<INotificationDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<INotificationDirectory>>();
  notificationFindOne = TTFunction<{ filter: FilterQuery<INotification> }, INotification>();
  notificationFindMany = TTFunction<{ filter: FilterQuery<INotification>, sort?: TTOnlyOneKey<INotification, SortOrder>, skip?: number, limit?: number }, INotification[]>();
  notificationsUnseen = TTFunction<{ uuidOrganization: string, uuidUser: string, createdAfter: Date }, INotification[]>();
  notificationsMarkReceived = TTFunction<{ uuidNotification: string, uuidUser: string }, void>();
  notificationsMarkDismissed = TTFunction<{ uuidNotification: string, uuidUser: string }, void>();

  // Notification Log
  notificationLogFindOne = TTFunction<{ filter: FilterQuery<INotificationLog> }, INotificationLog>();
  notificationLogFindMany = TTFunction<{ filter: FilterQuery<INotificationLog>, sort?: TTOnlyOneKey<INotificationLog, SortOrder>, skip?: number, limit?: number }, INotificationLog[]>();
  notificationLogCreate = TTFunction<{ notificationLogInfo: INotificationLogCreate }, INotificationLog>();

  // Event instances
  eventInstances = TTFunction<{ filter: { uuidEvent: string }, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IEventInstance>>();
  eventInstancesActive = TTFunction<{ filter: { uuidOrganization: string }, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IEventInstance>>();
  eventInstance = TTFunction<{ filter: { uuidEventInstance: string } }, IEventInstance | null>();
  eventInstanceLogs = TTFunction<{ filter: FilterQuery<IEventInstanceLog & { uuidEvent: string }>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IEventInstanceLog>>();
  eventInstanceMap = TTFunction<{ filter: { uuidEventInstance: string }, pagination: IPagination }, { users: IUser, sites: ISite }>();

  // Actions
  actionDirectory = TTFunction<{ uuidOrganization: string, filter: FilterQuery<IActionDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IActionDirectory>>();
  actionFindOne = TTFunction<{ filter: FilterQuery<IAction> }, IAction>();
  actionFindMany = TTFunction<{ filter: FilterQuery<IAction>, sort?: TTOnlyOneKey<IAction, SortOrder>, skip?: number, limit?: number }, IAction[]>();
  actionCreate = TTFunction<{ actionInfo: IActionCreate }, IAction>();
  actionDelete = TTFunction<{ uuids: string[] }, void>();
  actionUpdate = TTFunction<{ uuid: string; actionInfo: IActionUpdate }, IAction>();

  // Reports
  reportDirectory = TTFunction<{ uuidOrganization: string, filter: FilterQuery<IReportDirectory>, pagination: IPagination, ordering: IOrdering }, IPaginatedResponse<IReportDirectory>>();
  reportFindOnePublic = TTFunction<{ filter: FilterQuery<IReport> }, IReport>();
  reportFindOne = TTFunction<{ filter: FilterQuery<IReport> }, IReport>();
  reportFindMany = TTFunction<{ filter: FilterQuery<IReport>, sort?: TTOnlyOneKey<IReport, SortOrder>, skip?: number, limit?: number }, IReport[]>();
  reportCreate = TTFunction<{ reportInfo: IReportCreate }, IReport>();
  reportDelete = TTFunction<{ uuids: string[] }, void>();
  reportUpdate = TTFunction<{ uuid: string; reportInfo: IReportUpdate }, IReport>();

  // Profile
  profile = TTFunction<{ token: string }, IProfileResult>();

  // Overview - Map
  map = TTFunction<{ filter: { uuidsOrganizations: string[], beacons?: boolean, devices?: boolean, } }, IMapState>();
}

export const VigilFunctions = new VigilFunctionsSchema();