import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";
import { IEvent } from "./bean_event";

export interface IEventInstance extends IBaseSyncable {
  dateTimeStart: string;
  dateTimeEnd: string;
  event: IEvent
}

export const schemaEventInstance: TTSchema<IEventInstance> = {
  ...schemaBaseSyncable,
  dateTimeStart: {
    type: String,
    indexed: true,
  },
  dateTimeEnd: {
    type: String,
    indexed: true,
  },
  event: {
    type: Object,
    indexed: false,
  }
}

export const schemaInstanceEventInstance = new TTSchemaSyncableInstance<IEventInstance>('event_instances', schemaEventInstance, 0);
