import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export enum BeaconType {
  GEO = 'GEO',
  QR = 'QR',
  NFC = 'NFC',
  WIFI = 'WIFI',
  BLE = 'BLE',
}

export interface IBeacon extends IBaseSyncable {
  name: string;
  radius: number;
  longitude: number;
  latitude: number;
  altitude: number;
  type: BeaconType;
}

export const schemaBeacon: TTSchema<IBeacon> = {
  ...schemaBaseSyncable,
  name: {
    type: String,
    indexed: true,
  },
  radius: {
    type: Number,
    indexed: true,
  },
  longitude: {
    type: Number,
    indexed: true,
  },
  latitude: {
    type: Number,
    indexed: true,
  },
  altitude: {
    type: Number,
    indexed: true,
  },
  type: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceBeacon = new TTSchemaSyncableInstance<IBeacon>('beacons', schemaBeacon, 0);
