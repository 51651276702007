import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IOrganizationRole extends IBaseSyncable {
  uuidOrganization: string,
  name: string;
  permissionString: string,
  mutable: boolean,
} 

export const schemaOrganizationRole: TTSchema<IOrganizationRole> = {
  ...schemaBaseSyncable,
  uuidOrganization: {
    type: String,
    indexed: true,
  },
  name: {
    type: String,
    indexed: true,
  },
  permissionString: {
    type: String,
    indexed: true,
  },
  mutable: {
    type: Boolean,
    indexed: true,
  }
}

export const schemaInstanceOrganizationRole = new TTSchemaSyncableInstance<IOrganizationRole>('organization_roles', schemaOrganizationRole, 0);
