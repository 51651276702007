import React from 'react';

interface TextHeaderProps {
  children?: React.ReactNode;
}

export const TextHeader: React.FC<TextHeaderProps> = (props) => {
  return (
    <div className='font-semibold text-2xl'>
      {props.children || ''}
    </div>
  );
};
