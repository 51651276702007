import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IUser extends IBaseSyncable {
  uuidIdentity: string;
  firstName: string;
  lastName: string;
  id?: string;
  email: string;
  mobile: string;
}

export const schemaUser: TTSchema<IUser> = {
  ...schemaBaseSyncable,
  uuidIdentity: {
    type: String,
    indexed: true,
  },
  firstName: {
    type: String,
    indexed: true,
  },
  lastName: {
    type: String,
    indexed: true,
  },
  id: {
    type: String,
    indexed: true,
  },
  email: {
    type: String,
    indexed: true,
  },
  mobile: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceUser = new TTSchemaSyncableInstance<IUser>('users', schemaUser, 0);
