import React, { useContext, useEffect, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { INotificationDirectory, NotificationId } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { convertQueryFilterToMongoFilter } from './helper_home';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { useInterval } from '../../hooks/hook_interval';


interface ScreenHomeNotificationsProps { }

export const ScreenHomeNotifications: React.FC<ScreenHomeNotificationsProps> = (props) => {
  const navigate = useNavigate()
  const contextOrganization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateNotifications, setNotifications] = useState([] as INotificationDirectory[]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState([] as QueryFilter[]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState({ id: 'uuid', direction: 'descending' } as Sorting);
  const [stateTablePagingSelected, setTablePagingSelected] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 } as Paging);

  const [ticker, setTicker] = useState(0);

  /* Getters */
  async function fetchNotificationsBackground() {
    if (!contextOrganization) { return }
    
    const response = await vigil.functions.notificationDirectory({
      uuidOrganization: contextOrganization.uuid,
      filter: convertQueryFilterToMongoFilter(stateTableFiltersSelected),
      ordering: { field: stateTableSortingSelected.id, direction: stateTableSortingSelected.direction },
      pagination: { page: stateTablePagingSelected.currentPage, count: stateTablePagingSelected.itemsPerPage }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateNotifications)) {
      setNotifications(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }

  async function fetchNotifications() {
    try {
      setLoading(true);
      await fetchNotificationsBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update immediately
  useEffect(() => {
    fetchNotifications();
  }, [contextOrganization, ticker])

  // Update every 5 seconds
  useInterval(async () => {
    await fetchNotificationsBackground();
  }, 5000, false);

  function navigateNotification(selectedNotification: INotificationDirectory) {
    navigate(ROUTES.ROUTE_HOME_NOTIFICATION.replace(':uuid', selectedNotification.uuid))
  }

  return (
    <div className='p-4 h-full w-full'>
      <Table<INotificationDirectory>
        className='h-full flex flex-col drop-shadow'
        name='Notifications'
        data={stateNotifications}
        selectable={false}
        loading={stateLoading}
        error={stateError}
        columns={[
          {
            id: 'id',
            header: 'Type',
            sortable: true,
            value(item) {
              let type = '';
              if (item.id == NotificationId.LateStart) { type = 'Late Start' }
              if (item.id == NotificationId.Panic) { type = 'Panic' }
              if (item.id == NotificationId.UnsuccessfulPatrol) { type = 'Unsuccessful Patrol' }
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateNotification(item)}>
                  {type}
                </div>
              );
            },
          },
          {
            id: 'userReceiveCount',
            header: 'Users Received',
            sortable: true,
            value(item) {
              return <div>{item.userReceiveCount}</div>;
            },
          },

          {
            id: 'userDismissedCount',
            header: 'Users Dismissed',
            sortable: true,
            value(item) {
              return <div>{item.userDismissedCount}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('id', 'Type', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
        ]}
        filtersSelected={stateTableFiltersSelected}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1) }}

        sortingOptions={null}
        sortingSelected={stateTableSortingSelected}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1) }}

        pagingOptions={null}
        pagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1) }}
      />
    </div >
  );
};
