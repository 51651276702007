import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IUserOrganizationInvite extends IBaseSyncable {
  uuidOrganization: string;
  uuidRole?: string;
  inviteUserUuid?: string;
  inviteUserEmail?: string;
  inviteUserMobile?: string;
}

export const schemaUserOrganizationInvite: TTSchema<IUserOrganizationInvite> = {
  ...schemaBaseSyncable,
  uuidOrganization: {
    type: String,
    indexed: true,
  },
  uuidRole: {
    type: String,
    indexed: true,
  },
  inviteUserUuid: {
    type: String,
    indexed: true,
  },
  inviteUserEmail: {
    type: String,
    indexed: true,
  },
  inviteUserMobile: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceUserOrganizationInvite = new TTSchemaSyncableInstance<IUserOrganizationInvite>('user_organization_invites', schemaUserOrganizationInvite, 0);
