import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export interface IDashStateLog extends IBaseSyncable {
  uuidSession: string;
  effectiveType?: string;
  downlink?: number;
  roundTripTime?: number;
  status: 'online' | 'offline';
}

export const schemaDashStateLog: TTSchema<IDashStateLog> = {
  ...schemaBaseSyncable,
  uuidSession: {
    type: String,
    indexed: true,
  },
  effectiveType: {
    type: String,
    indexed: true,
  },
  downlink: {
    type: Number,
    indexed: true,
  },
  roundTripTime: {
    type: Number,
    indexed: true,
  },
  status: {
    type: String,
    indexed: true,
  },
}

export const schemaInstanceDashStateLog = new TTSchemaSyncableInstance<IDashStateLog>('dash_state_logs', schemaDashStateLog, 0);
