import React, { useContext, useEffect, useState } from 'react';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/hook_modal';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ROUTES } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { convertQueryFilterToMongoFilter } from './helper_home';
import { IEventDirectory } from 'vigil-datamodel';
import { ModalEventDelete } from './func_event/modal_event_delete';
import { ModalEventCreate } from './func_event/modal_event_create';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../../components/table/table_types';
import { useInterval } from '../../hooks/hook_interval';
import { EventsTable } from '../shared/events_table';

interface ScreenHomeEventsProps { }

export const ScreenHomeEvents: React.FC<ScreenHomeEventsProps> = (props) => {
  const navigate = useNavigate()
  const contextOrganization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateEvents, setEvents] = useState<IEventDirectory[]>([]);
  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState<QueryFilter[]>([]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState<Sorting>({ id: 'uuid', direction: 'descending' });
  const [stateTablePagingSelected, setTablePagingSelected] = useState<Paging>({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 });

  const [ticker, setTicker] = useState(0);

  const { isOpen: isOpenModalCreate, toggle: toggleModalCreate } = useModal();
  const { isOpen: isOpenModalDelete, toggle: toggleModalDelete } = useModal();

  const [selectedEventsState, setSelectedEvents] = useState<IEventDirectory[]>([]);

  async function fetchEventsBackground() {
    if (!contextOrganization) { return }

    const response = await vigil.functions.eventDirectory({
      uuidOrganization: contextOrganization.uuid,
      filter: convertQueryFilterToMongoFilter(stateTableFiltersSelected),
      ordering: { field: stateTableSortingSelected.id, direction: stateTableSortingSelected.direction },
      pagination: { page: stateTablePagingSelected.currentPage, count: stateTablePagingSelected.itemsPerPage }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateEvents)) {
      setEvents(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }

  async function fetchEvents() {
    try {
      setLoading(true);
      await fetchEventsBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchEvents();
  }, [contextOrganization, ticker])

  useInterval(async () => {
    await fetchEventsBackground();
  }, 5000, false);

  function navigateEvent(selectedEvent: IEventDirectory) {
    navigate(ROUTES.ROUTE_HOME_EVENT.replace(':uuid', selectedEvent.uuid));
  }

  function deleteEvents(selectedEvents: IEventDirectory[]) {
    setSelectedEvents(selectedEvents);
    toggleModalDelete();
  }

  return (
    <div className='px-4 py-4 h-full w-full'>
      <ModalEventCreate isOpen={isOpenModalCreate} toggle={toggleModalCreate} onSubmit={async () => await fetchEvents()} />
      <ModalEventDelete isOpen={isOpenModalDelete} toggle={toggleModalDelete} events={selectedEventsState} onSubmit={async () => await fetchEvents()} />

      <EventsTable
        events={stateEvents}
        loading={stateLoading}
        error={stateError}
        onEventClick={navigateEvent}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1) }}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1) }}
        tablePagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1) }}
        selectable={true}
        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new event</div>,
            color: 'primary',
            onClick: () => toggleModalCreate(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            onClick: (selectedItems) => deleteEvents(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            onClick: (selectedItems) => deleteEvents(selectedItems)
          }
        ]}
      />
    </div>
  );
};