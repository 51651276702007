import React from 'react';
import { InputBase, InputBaseProps } from './input_base';

interface InputTextProps extends InputBaseProps {
  value: string | number;
  inputType?: "text" | "password" | "numberInt" | "numberFloat" | "email" | "tel" | "url";
  placeholder?: string;
  disabled?: boolean;
  size?: "input-sm" | "input-md" | "input-lg";
  label?: React.ReactNode;
  labelPosition?: 'front' | 'back';
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputText: React.FC<InputTextProps> = (props) => {
  function styleErrors() {
    return props.errors.length ? 'input-error' : '';
  }

  function getInputType() {
    if (props.inputType) {
      if (props.inputType === 'numberInt') return 'number';
      if (props.inputType === 'numberFloat') return 'number';
      return props.inputType;
    }
    return 'text';
  }

  function getStep() {
    if (props.inputType) {
      if (props.inputType === 'numberInt') return '1';
      if (props.inputType === 'numberFloat') return 'any';
    }
    return undefined;
  }

  function renderLabel() {
    if (!props.label) return null;
    return props.label;
  }

  return (
    <div>
      <InputBase {...props}>
        <div className={`input input-bordered flex items-center gap-2 ${styleErrors()} ${props.size || ''} ${props.disabled ? 'input-disabled' : ''}`}>
          {props.labelPosition !== 'back' && renderLabel()}
          <input 
            type={getInputType()}
            step={getStep()}
            placeholder={props.placeholder}
            className="grow bg-transparent"
            onChange={props.onChange}
            value={props.value}
            disabled={props.disabled}
          />
          {props.labelPosition === 'back' && renderLabel()}
        </div>
      </InputBase>
    </div>
  );
};