import React, { useContext, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { IconCalendar, IconQuestion, IconMapPin, IconChevronDownOutline, IconChevronRightOutline, IconUsers, IconDeviceMobile, IconBuildings, IconXMarkOutline, IconIdentificationOutline, IconBellOutline, IconChat, IconDocumentMagnifyingGlass, IconBolt, IconDocument } from '../../components/icons';
import { InputButtonIcon } from '../../components/input_button_icon';
import { ContextRoles } from '../../providers/provider_roles';
import { FullPageLoader } from '../full_page_loader';
import { TTActions } from 'tt-permissions';
import { Permissions } from 'vigil-datamodel';

interface SidebarItemInfo {
  text: string,
  icon: any,
  selected: (item: SidebarItemInfo) => boolean,
  onClick: (item: SidebarItemInfo) => void,
  minimized: boolean,
  children: SidebarItemInfo[],
  location?: string,
  disabled?: boolean,
}

export const SidebarItem: React.FC<SidebarItemInfo> = (sidebarItem) => {
  const [stateExpanded, setStateExpanded] = useState(false);
  const selected = sidebarItem.selected(sidebarItem) && stateExpanded == false;

  /* Actions */
  function onClick() {
    if (sidebarItem.disabled) return;

    if (sidebarItem.children.length > 0) {
      setStateExpanded(!stateExpanded);
    } else {
      sidebarItem.onClick(sidebarItem)
    }
  }

  return (
    <div className={`select-none ${sidebarItem.disabled ? 'opacity-50 pointer-events-none' : ''}`}>
      <div onClick={onClick} className={`flex w-full items-center p-2 cursor-pointer text-base rounded-full hover:bg-base-200 group ${selected && `bg-base-300`} ${sidebarItem.minimized && 'tooltip tooltip-right'}`} data-tip={sidebarItem.text} >
        {
          sidebarItem.children.length == 0 && <div className='' />
        }
        {
          sidebarItem.children.length > 0 &&
          <div>
            {!stateExpanded && <IconChevronRightOutline className='w-4 mx-1' />}
            {stateExpanded && <IconChevronDownOutline className='w-4 mx-1' />}
          </div>
        }

        <span className={`w-6 h-6 ${selected && 'text-primary'}`} >
          {sidebarItem.icon || <IconQuestion />}
        </span>

        {!sidebarItem.minimized && <span className={`ml-2 ${selected && 'text-primary font-bold'}`}>{sidebarItem.text}</span>}
      </div>
      {
        stateExpanded &&
        <div className='ml-6'>
          {
            sidebarItem.children.map(item => {
              return <SidebarItem key={item.text} {...item} />
            })
          }
        </div>
      }
    </div>
  )
}

interface SidebarProps {
  minimized?: boolean,
  mobile: boolean,
  mobileOpen: boolean,
  mobileOnMenuClick: () => void,
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const minimized = props.minimized || false;

  const contextRoles = useContext(ContextRoles);

  /* Helpers */
  function isSelected(item: SidebarItemInfo) {
    let childSelected = false;
    for (const itemChild of item.children) {
      if (isSelected(itemChild)) {
        childSelected = true;
      }
    }
    return childSelected || ROUTES.normalize(location.pathname).includes(item.location!);
  }

  /* Actions */
  function actionClick(item: SidebarItemInfo) {
    if (item.disabled) return;
    navigate(item.location!);
    props.mobile && props.mobileOnMenuClick()
  }

  /* Definition */
  const shared = {
    selected: isSelected,
    onClick: actionClick,
    minimized: minimized,
  }
  const items: SidebarItemInfo[] = [
    {
      ...shared,
      text: 'Overview',
      icon: <IconDocumentMagnifyingGlass />,
      location: ROUTES.ROUTE_HOME_OVERVIEW,
      children: [],
    },
    // {
    //   ...shared,
    //   text: 'Assistent',
    //   icon: <IconChat />,
    //   location: ROUTES.ROUTE_HOME_ASSISTENT,
    //   children: [],
    // },
    {
      text: 'Notifications',
      icon: <IconBellOutline />,
      location: ROUTES.ROUTE_HOME_NOTIFICATIONS,
      children: [],
      ...shared,
    },
    {
      text: 'Actions',
      icon: <IconBolt />,
      location: ROUTES.ROUTE_HOME_ACTIONS,
      children: [],
      disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RAction),
      ...shared,
    },
    {
      text: 'Reports',
      icon: <IconDocument />,
      location: ROUTES.ROUTE_HOME_REPORTS,
      disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RReport),
      children: [],
      ...shared,
    },
    {
      text: 'Events',
      icon: <IconCalendar />,
      location: ROUTES.ROUTE_HOME_EVENTS,
      children: [],
      disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.REvent),
      ...shared,
    },
    {
      text: 'Roles',
      icon: <IconIdentificationOutline />,
      location: ROUTES.ROUTE_HOME_ROLES,
      children: [],
      disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RRole),
      ...shared,
    },
    {
      text: 'Users',
      icon: <IconUsers />,
      location: ROUTES.ROUTE_HOME_USERS,
      children: [],
      disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RUser),
      ...shared,
    },
    {
      text: 'Sites',
      icon: <IconBuildings />,
      location: ROUTES.ROUTE_HOME_SITES,
      children: [],
      disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RSite),
      ...shared,
    },
    {
      text: 'Beacons',
      icon: <IconMapPin />,
      location: ROUTES.ROUTE_HOME_BEACONS,
      children: [],
      disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RBeacon),
      ...shared,
    },
    {
      text: 'Devices',
      icon: <IconDeviceMobile />,
      location: ROUTES.ROUTE_HOME_DEVICES,
      children: [],
      disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RDevice),
      ...shared,
    },
    // {
    //   ...shared,
    //   text: 'Directory',
    //   icon: <IconUserGroup />,
    //   location: ROUTES.ROUTE_HOME_BILLING,
    //   children: [
    //     {
    //       text: 'Patrols',
    //       icon: <IconQrCodeSolid />,
    //       location: ROUTES.ROUTE_HOME_EVENTS,
    //       children: [],
    // disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RPatrol),
    //       ...shared,
    //     },
    //     {
    //       text: 'Roles',
    //       icon: <IconIdentificationOutline />,
    //       location: ROUTES.ROUTE_HOME_ROLES,
    //       children: [],
    //       disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RRole),
    //       ...shared,
    //     },
    //     {
    //       text: 'Users',
    //       icon: <IconUsers />,
    //       location: ROUTES.ROUTE_HOME_USERS,
    //       children: [],
    //       disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RUser),
    //       ...shared,
    //     },
    //     {
    //       text: 'Sites',
    //       icon: <IconBuildings />,
    //       location: ROUTES.ROUTE_HOME_SITES,
    //       children: [],
    //       disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RSite),
    //       ...shared,
    //     },
    //     {
    //       text: 'Beacons',
    //       icon: <IconMapPin />,
    //       location: ROUTES.ROUTE_HOME_BEACONS,
    //       children: [],
    //       disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RBeacon),
    //       ...shared,
    //     },
    //     {
    //       text: 'Devices',
    //       icon: <IconDeviceMobile />,
    //       location: ROUTES.ROUTE_HOME_DEVICES,
    //       children: [],
    //       disabled: !contextRoles.hasUserPermission(TTActions.R, Permissions.RDevice),
    //       ...shared,
    //     },
    //   ],
    // },
    // {
    //   ...shared,
    //   text: 'Settings',
    //   icon: <IconSettings />,
    //   location: ROUTES.ROUTE_HOME_SETTINGS,
    //   children: [],
    // },

  ];

  if (!contextRoles) return <FullPageLoader />;

  function sidebarContents() {
    return (
      <div className={`h-full border-r border-r-base-300 ${!minimized && 'w-60'} px-1 py-4 `}>
        {
          items.map((item) => {
            return <div className={`pt-1 ${item.disabled ? 'opacity-50 pointer-events-none' : ''}`} key={item.text}> <SidebarItem {...item} /> </div>
          })
        }
      </div>
    )
  }

  if (props.mobile) {
    return (
      <div
        style={{ 'animationDuration': '0.25s' }}
        className={`h-full z-50 bg-base-100 absolute top-0 left-0 animate__animated ${props.mobileOpen ? 'animate__slideInLeft' : 'animate__slideOutLeft'}`}>
        <div className='flex flex-col h-full'>
          <div className='m-2'>
            <InputButtonIcon icon={IconXMarkOutline} shape='btn-circle' onClick={props.mobileOnMenuClick} />
          </div>
          {sidebarContents()}
        </div>

      </div>
    )
  } else {
    return sidebarContents()
  }

}

