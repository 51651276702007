import React, { useRef, useState } from 'react';

interface ScreenAdminDevicesProps { }

export const ScreenAdminDevices: React.FC<ScreenAdminDevicesProps> = (props) => {
  return (
    <div>
      <div>
        // TODO: here we can have a list of devices, queried from mongo, and combined somehow with the android management parts
      </div>
    </div >
  );
};
