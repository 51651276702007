import React, { useState, useEffect } from 'react';
import { InputBaseProps, InputBase } from './input_base';

export interface DurationState {
  // days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

interface InputDurationProps extends InputBaseProps {
  value: DurationState;
  onChange?: (newTime: DurationState) => void;
}

export const InputDuration: React.FC<InputDurationProps> = (props) => {
  const [duration, setDuration] = useState(props.value);

  const handleHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDuration({ ...duration, hours: parseInt(event.target.value) });
    if (props.onChange) props.onChange({ ...duration, hours: parseInt(event.target.value) })
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDuration({ ...duration, minutes: parseInt(event.target.value) });
    if (props.onChange) props.onChange({ ...duration, minutes: parseInt(event.target.value) })
  };

  const handleSecondChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDuration({ ...duration, seconds: parseInt(event.target.value) });
    if (props.onChange) props.onChange({ ...duration, seconds: parseInt(event.target.value) })
  };

  return (
    <InputBase {...props} >
      <div className={`p-2 inline-flex bg-base border-2 rounded-lg ${props.errors.length > 0 ? 'border-error' : 'border-base-300'}`}>
        {/* Hour select */}
        {props.value.hours !== undefined &&
          <select className="bg-transparent text-lg" value={props.value.hours} onChange={handleHourChange}>
            {[...Array(25)].map((_, i) => (
              <option key={i} value={i}>{`${i}`.padStart(2, '0')}</option>
            ))}
          </select>}
        {props.value.minutes !== undefined && <span className="text-lg mr-1">:</span>}
        {/* Minute select */}
        {props.value.hours !== undefined &&
          <select className="bg-transparent text-lg mr-1" value={props.value.minutes} onChange={handleMinuteChange}>
            {[...Array(12)].map((_, i) => (
              <option key={i} value={i * 5}>{`${i * 5}`.padStart(2, '0')}</option>
            ))}
          </select>}
        {props.value.seconds !== undefined && <span className="text-lg mr-1">:</span>}
        {/* Second select */}
        {props.value.seconds !== undefined &&
          <select className="bg-transparent text-lg" value={props.value.seconds} onChange={handleSecondChange}>
            {[...Array(12)].map((_, i) => (
              <option key={i} value={i * 5}>{`${i * 5}`.padStart(2, '0')}</option>
            ))}
          </select>}
      </div>
    </InputBase>
  );
};
