import { ReactNode, Suspense } from "react"
import { FullPageLoader } from "./full_page_loader"

interface FullPageSuspendProps {
  children: ReactNode
}
export const FullPageSuspend: React.FC<FullPageSuspendProps> = (props: FullPageSuspendProps) => {
  return (
    <Suspense fallback={<FullPageLoader/>}>
      {props.children}
    </Suspense>
  )
}
