import { IBaseSyncable, TTSchema, TTSchemaSyncableInstance, schemaBaseSyncable } from "tt-coms";

export interface IIdentity extends IBaseSyncable {
  uuid: string;
  targets: string[];
}

export const schemaIdentity: TTSchema<IIdentity> = {
  ...schemaBaseSyncable,
  uuid: {
    type: String,
    indexed: true,
  },
  targets: {
    type: Array<String>,
    indexed: true,
  },
}

export const schemaInstanceIdentity = new TTSchemaSyncableInstance<IIdentity>('identities', schemaIdentity, 0);
