import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { INotification, NotificationId, INotificationLog, NotificationLogStatus, IUser } from 'vigil-datamodel';
import { FullPageLoader } from '../../components/full_page_loader';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../routes';
import { TTuuid } from 'tt-uuid';
import { StatusAlert } from '../../components/status_alert';

interface ScreenHomeNotificationProps { }

export const ScreenHomeNotification: React.FC<ScreenHomeNotificationProps> = (props) => {
  /* State */
  const navigate = useNavigate()
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');

  const [stateNotificationInfo, setNotificationInfo] = useState<({ field: string, info: string } | null)[]>([]);

  const [stateNotification, setNotification] = useState<INotification>();
  const [stateNotificationLogs, setNotificationLogs] = useState<INotificationLog[]>();
  const [stateUsers, setUsers] = useState<IUser[]>();

  const params = useParams();
  const uuidNotification = params['uuid'] || '';

  useEffect(() => { fetchNotification(); }, [uuidNotification]);
  useEffect(() => { fetchNotificationLogs(); }, [stateNotification]);
  useEffect(() => { fetchUsers(); }, [stateNotificationLogs]);


  function getNotificationInfo(notification: INotification) {
    if (notification.id === NotificationId.Panic) {
      return [
        { field: 'Type', info: 'Panic' },
        { field: 'Creator', info: notification.creator.type === 'server' ? 'Server' : 'Device' },
        notification.creator.type === 'device' ? { field: 'Creator Uuid', info: notification.creator.uuidDevice } : null,
        { field: 'Date Created', info: TTuuid.decodeCuuid(notification.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(notification.uuid).time.toLocaleTimeString() },
        notification.creator.type === 'device' ? { field: 'Server Arrival', info: TTuuid.decodeCuuid(notification.serverStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(notification.serverStamp).time.toLocaleTimeString() } : null,
        { field: 'User Name', info: notification.data.userFirstName + ' ' + notification.data.userLastName },
        notification.data.siteName ? { field: 'Site Name', info: notification.data.siteName } : null,
        notification.data.deviceUuid ? { field: 'Device uuid', info: notification.data.deviceUuid } : null,
        { field: 'Location Accuracy', info: notification.data.acc.toString() },
        { field: 'Location Latitude', info: notification.data.lat.toString() },
        { field: 'Location Longitude', info: notification.data.lng.toString() },
        { field: 'Location Altitude', info: notification.data.alt.toString() },
      ];
    }
    if (notification.id === NotificationId.LateStart) {
      return [
        { field: 'Type', info: 'Late Start' },
        { field: 'Creator', info: notification.creator.type === 'server' ? 'Server' : 'Device' },
        notification.creator.type === 'device' ? { field: 'Creator Uuid', info: notification.creator.uuidDevice } : null,
        { field: 'Date Created', info: TTuuid.decodeCuuid(notification.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(notification.uuid).time.toLocaleTimeString() },
        notification.creator.type === 'device' ? { field: 'Server Arrival', info: TTuuid.decodeCuuid(notification.serverStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(notification.serverStamp).time.toLocaleTimeString() } : null,
        { field: 'Site Name', info: notification.data.siteName },
      ]
    }
    if (notification.id === NotificationId.UnsuccessfulPatrol) {
      return [
        { field: 'Type', info: 'Unsuccessful Patrol' },
        { field: 'Creator', info: notification.creator.type === 'server' ? 'Server' : 'Device' },
        notification.creator.type === 'device' ? { field: 'Creator Uuid', info: notification.creator.uuidDevice } : null,
        { field: 'Date Created', info: TTuuid.decodeCuuid(notification.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(notification.uuid).time.toLocaleTimeString() },
        notification.creator.type === 'device' ? { field: 'Server Arrival', info: TTuuid.decodeCuuid(notification.serverStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(notification.serverStamp).time.toLocaleTimeString() } : null,
        { field: 'Site Name', info: notification.data.siteName },
        { field: 'Scanned Percentage', info: notification.data.percentageCompleted + '%' },
        { field: 'Required Percentage', info: notification.data.percentageRequired + '%' },
      ]
    }
    return [];
  }

  async function fetchNotification() {
    try {
      const notification = await vigil.functions.notificationFindOne({ filter: { uuid: uuidNotification } });
      setNotificationInfo(getNotificationInfo(notification));
      setNotification(notification);
    } catch (error: any) {
      setError(error.message);
    }
  }

  async function fetchNotificationLogs() {
    try {
      if (!stateNotification) return;
      const notificationLogs = await vigil.functions.notificationLogFindMany({ filter: { uuidNotification: stateNotification.uuid } });
      setNotificationLogs(notificationLogs);
    } catch (error: any) {
      setError(error.message);
    }
  }

  async function fetchUsers() {
    try {
      if (!stateNotificationLogs) return;
      const users = await vigil.functions.userFindMany({ filter: { uuid: { $in: stateNotificationLogs.map((notificationLog) => notificationLog.uuidUser) } } });
      setUsers(users);
    } catch (error: any) {
      setError(error.message);
    }
  }

  if (!stateError && !stateNotification) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-8 pb-2 w-full flex flex-col'>
      <Breadcrumbs crumbs={[{ id: 'notifications', onClick: () => navigate(ROUTES.ROUTE_HOME_NOTIFICATIONS), text: 'Notifications' }, { id: uuidNotification, onClick: () => { }, text: stateNotification?.uuid || '' }]} />

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <div className='flex items-center'>
        <div className='flex flex-wrap flex-grow items-end'>
          {stateNotificationInfo && stateNotificationInfo.map((info) => {
            if (!info) return
            return (
              <div key={info.field} className="flex items-center mr-6">
                <span className='font-semibold'>{info.field}:</span>
                <span className="text-sm ml-2">{info.info}</span>
              </div>
            )
          })}
        </div>
      </div>

      <div className='card flex flex-grow mt-2 shadow-xl bg-base-200 overflow-auto'>
        {stateNotificationLogs && stateNotificationLogs.map((notificationLog) => {
          const user = stateUsers?.find((user) => user.uuid === notificationLog.uuidUser);
          return (
            <div className='flex'>
              {notificationLog.type === NotificationLogStatus.Acknowledged && <div className='bg-info w-5 h-full'></div>}
              {notificationLog.type === NotificationLogStatus.Expired && <div className='bg-error w-5 h-full'></div>}
              {notificationLog.type === NotificationLogStatus.Received && <div className='bg-primary w-5 h-full'></div>}
              {notificationLog.type === NotificationLogStatus.Dismissed && <div className='bg-secondary w-5 h-full'></div>}
              <div key={notificationLog.uuid} className='flex flex-col px-4 py-2 border-b border-base-300'>
                <div className='flex items-center'>
                  <span className='font-semibold'>User Name:</span>
                  <span className="text-sm ml-2">{user?.firstName + ' ' + user?.lastName}</span>
                </div>
                <div className='flex items-center'>
                  <span className='font-semibold'>Date:</span>
                  <span className="text-sm ml-2">{TTuuid.decodeCuuid(notificationLog.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(notificationLog.uuid).time.toLocaleTimeString()}</span>
                </div>
                <div className='flex items-center'>
                  <span className='font-semibold'>Status:</span>
                  <span className="text-sm ml-2">{notificationLog.type}</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};
