import { useContext, useState } from "react"
import { IconArrowRightRectangle, IconChevronDownOutline, IconHome, IconUser } from "../icons"
import { ContextUser } from "../../providers/provider_user"
import { ContextVigilClient } from "../../providers/provider_vigil_client"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../routes"
import useBreakpoints from "../../hooks/hook_breakpoints"
import { ContextSession } from "../../providers/provider_session"
import { IDashActivityLogUserLoginLogout } from "vigil-datamodel"

interface NavbarUserProps {
  onClickMenu?: () => void
}

export const NavbarUser: React.FC<NavbarUserProps> = (props) => {
  const breakpoints = useBreakpoints();
  const vigil = useContext(ContextVigilClient);
  const user = useContext(ContextUser);
  const session = useContext(ContextSession);
  const navigate = useNavigate();

  const [stateOpen, setOpen] = useState(false);

  async function actionSignOut() {
    // Log event
    session?.logEvent<IDashActivityLogUserLoginLogout>(
      'userLogout',
      {
        userUuid: user?.uuid || '',
      });
    await vigil.signOut();
  }

  return (
    <button className={`dropdown dropdown-end ${stateOpen ? 'dropdown-open' : ''}`}>
      <label className="btn btn-outline btn-neutral" onClick={() => setOpen(!stateOpen)}>
        <img src={`https://ui-avatars.com/api/?name=${user?.firstName[0]}&background=random`} className="w-6 h-6 rounded-full" alt="" />
        {breakpoints['MD'] && <span className="">{user?.firstName}</span>}
        <IconChevronDownOutline className='w-3 mr-1' />
      </label>
      <div className="mt-1 shadow dropdown-content z-[1] bg-base-100 rounded-box w-48 outline outline-1 outline-neutral-content">
        <ul className="menu">
          <li><a onClick={() => { navigate(ROUTES.ROUTE_HOME) }}> <IconHome className="w-6" /> Dashboard </a></li>
          <li><a onClick={() => { navigate(ROUTES.ROUTE_PROFILE) }}> <IconUser className="w-6" /> Profile </a></li>
          <hr className="my-1"></hr>
          <li><a className="text-error" onClick={actionSignOut}> <IconArrowRightRectangle className="w-6" /> Sign Out</a></li>
        </ul>
      </div>
    </button>
  )
}