import { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { IDevice, IDeviceActivityLog, IUser } from 'vigil-datamodel';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { TTuuid } from 'tt-uuid';
import { IDeviceActivityLogUserLoginLogout, IDeviceActivityLogVersionUpdate } from 'vigil-datamodel/src/bean_device_activity_log';

interface ScreenHomeDeviceLogsActivityProps { }

export const ScreenHomeDeviceLogsActivity: FC<ScreenHomeDeviceLogsActivityProps> = (props) => {
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateLoading, setLoading] = useState<boolean>(false);
  const [stateDevice, setDevice] = useState<IDevice>();
  const [stateDeviceActivityLogs, setDeviceActivityLogs] = useState<IDeviceActivityLog[]>();
  const [stateUsers, setUsers] = useState<IUser[]>([]);

  const params = useParams();
  const uuidDevice = params['uuid'] || '';

  useEffect(() => { fetchDevice(); fetchDeviceActivityLogs(); }, [uuidDevice]);

  async function fetchDevice() {
    try {
      const device = await vigil.functions.deviceFindOne({ filter: { uuid: uuidDevice } });
      setDevice(device);
    } catch (error: any) {
      setError(error.message);
    }
  }

  async function fetchUser(uuidUser: string) {
    try {
      if (stateUsers.find((user) => user.uuid === uuidUser)) return;
      const user = await vigil.functions.userFindOne({ filter: { uuid: uuidUser } });
      setUsers((prevUsers) => [...prevUsers, user]);
    } catch (error: any) {
      setError(error.message);
    }
  }

  async function fetchDeviceActivityLogs() {
    try {
      setLoading(true);
      const deviceActivityLogs = await vigil.functions.deviceActivityLogFindMany({ filter: { uuidDevice: uuidDevice } });
      for (const deviceActivityLog of deviceActivityLogs) {
        if (deviceActivityLog.type === 'userLogin' || deviceActivityLog.type === 'userLogout') {
          await fetchUser((deviceActivityLog.value as IDeviceActivityLogUserLoginLogout).userUuid);
        }
      }
      setDeviceActivityLogs(deviceActivityLogs);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  if (!stateDevice) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='pb-2 w-full flex flex-col'>
      <div className='card flex flex-grow mt-2 shadow-xl bg-base-200 overflow-auto'>
        {stateDeviceActivityLogs && stateDeviceActivityLogs.map((deviceActivityLog) => {
          return (
            <div className='flex'>
              {(deviceActivityLog.type === 'orgLogin' || deviceActivityLog.type === 'userLogin') && <div className='bg-primary w-5 h-full'></div>}
              {(deviceActivityLog.type === 'orgLogout' || deviceActivityLog.type === 'userLogout') && <div className='bg-error w-5 h-full'></div>}
              {deviceActivityLog.type === 'versionUpdate' && <div className='bg-info w-5 h-full'></div>}
              <div key={deviceActivityLog.uuid} className='flex flex-col px-4 py-2 border-b border-base-300'>
                <div className='flex items-center'>
                  <span className='font-semibold'>Activity Type:</span>
                  <span className="text-sm ml-2">{deviceActivityLog.type}</span>
                </div>
                <div className='flex items-center'>
                  <span className='font-semibold'>Date:</span>
                  <span className="text-sm ml-2">{TTuuid.decodeCuuid(deviceActivityLog.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(deviceActivityLog.uuid).time.toLocaleTimeString()}</span>
                </div>
                {(deviceActivityLog.type === 'userLogin' || deviceActivityLog.type === 'userLogout') &&
                  <>
                    <div className='flex items-center'>
                      <span className='font-semibold'>First Name:</span>
                      <span className="text-sm ml-2">{stateUsers.find((user) => user.uuid === (deviceActivityLog.value as IDeviceActivityLogUserLoginLogout).userUuid)?.firstName}</span>
                    </div>
                    <div className='flex items-center'>
                      <span className='font-semibold'>Last Name:</span>
                      <span className="text-sm ml-2">{stateUsers.find((user) => user.uuid === (deviceActivityLog.value as IDeviceActivityLogUserLoginLogout).userUuid)?.lastName}</span>
                    </div>
                  </>}
                {deviceActivityLog.type === 'versionUpdate' &&
                  <div className='flex items-center'>
                    <span className='font-semibold'>Version:</span>
                    <span className="text-sm ml-2">{(deviceActivityLog.value as IDeviceActivityLogVersionUpdate).version}</span>
                  </div>}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};
