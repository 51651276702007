import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { FullPageLoader } from "../components/full_page_loader";
import { ContextVigilClient } from "./provider_vigil_client";
import { IUser } from "vigil-datamodel";
import { ContextIdentity } from "./provider_identity";
import { Preferences } from "../preference_keys";

export const ContextUser = createContext(null as IUser | null);

interface ProviderUserProps {
  children: ReactNode
}
export const ProviderUser: React.FC<ProviderUserProps> = (props: ProviderUserProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);
  const identity = useContext(ContextIdentity);

  /* State */
  const [stateUserPref, setUserPref] = useState(null as string | null);
  const [stateUser, setUser] = useState(null as IUser | null);
  const [stateUserLoading, setUserLoading] = useState(true);

  /* Reactivity */
  const subscriptionUserPref = useRef(null as any);
  useEffect(() => {
    (async () => {
      subscriptionUserPref.current = await vigil.getPreferenceReactive(Preferences.SELECTED_USER, (value) => {
        console.log('Setting user pref: ' + value);
        setUserPref(value as string | null);
      });
    })();

    return () => { subscriptionUserPref.current?.unsubscribe() }
  }, []);

  useEffect(() => {
    (async () => {
      if (!identity || !identity.authenticated || !identity.tokenDecoded) {
        setUser(null);
        setUserLoading(false);
        return;
      }

      const users = await vigil.functions.userFindManyPublic({ filter: { uuidIdentity: identity.tokenDecoded.uuidIdentity } });
      console.log('users', users);
      setUser(users.length > 0 ? users[0] : null);
      setUserLoading(false);
    })();
  }, [identity, stateUserPref]);

  if (stateUserLoading) {
    return <FullPageLoader />
  }

  return (
    <ContextUser.Provider value={stateUser}>
      {props.children}
    </ContextUser.Provider>
  )
}
