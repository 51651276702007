import React, { useContext, useState, useEffect } from 'react';
import { Table } from '../../components/table/table';
import { TTuuid } from 'tt-uuid';
import { IEventInstance } from 'vigil-datamodel';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { useInterval } from '../../hooks/hook_interval';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { Paging, Sorting } from '../../components/table/table_types';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';

interface ScreenHomeOverviewEventsProps { }

export const ScreenHomeOverviewEvents: React.FC<ScreenHomeOverviewEventsProps> = (props) => {
  const navigate = useNavigate();

  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);

  const [stateEventInstances, setEventInstances] = useState([] as IEventInstance[]);
  const [stateLoading, setLoading] = useState(true);
  const [stateError, setError] = useState("");

  const [stateTableFiltersSelected, setTableFiltersSelected] = useState([] as QueryFilter[]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState({ id: '', direction: 'ascending' } as Sorting);
  const [stateTablePagingSelected, setTablePagingSelected] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 } as Paging);

  const [ticker, setTicker] = useState(0);

  async function fetchEventInstancesBackground() {
    if (!organization) { return }
    // TODO: add filtering and sorting
    const response = await vigil.functions.eventInstancesActive({
      filter: {
        uuidOrganization: organization.uuid,
      },
      pagination: {
        page: stateTablePagingSelected.currentPage,
        count: stateTablePagingSelected.itemsPerPage,
      },
      ordering: {
        field: stateTableSortingSelected.id,
        direction: stateTableSortingSelected.direction
      }
    });

    if (JSON.stringify(response.items) != JSON.stringify(stateEventInstances)) {
      setEventInstances(response.items);
      setTablePagingSelected({ ...stateTablePagingSelected, totalPages: response.pagination.pages, totalItems: response.pagination.count });
    }
  }

  async function fetchEventInstances() {
    try {
      setLoading(true);
      await fetchEventInstancesBackground();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update immediately
  useEffect(() => {
    fetchEventInstances();
  }, [organization, ticker]);

  // Update every 5 seconds
  const interval = useInterval(async () => {
    await fetchEventInstancesBackground();
  }, 5000, false);

  function navigateEvent(selectedEvent: IEventInstance) {
    navigate(ROUTES.ROUTE_HOME_EVENT_INSTANCE.replace(':uuid', selectedEvent.uuid))
  }

  return (
    <div className='h-full w-full'>
      <Table<IEventInstance>
        className='h-full flex flex-col drop-shadow'
        name='Active Events'
        data={stateEventInstances}
        selectable={false}
        loading={stateLoading}
        error={stateError}
        columns={[
          {
            id: 'event_name',
            header: 'Event Name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateEvent(item)}>
                  {item.event.name}
                </div>
              );
            },
          },
          {
            id: 'date_created',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'last_updated',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}

        filtersOptions={[
          new TextFilter('event_name', 'Event Name', 'contains'),
          new DateFilter('date_created', 'Date Created', 'between'),
          new DateFilter('last_updated', 'Last Updated', 'between'),
        ]}
        filtersSelected={stateTableFiltersSelected}
        onFiltersChange={(filters) => { setTableFiltersSelected(filters); setTicker(ticker + 1) }}

        sortingOptions={null}
        sortingSelected={stateTableSortingSelected}
        onSortingChange={(sorting) => { setTableSortingSelected(sorting); setTicker(ticker + 1) }}

        pagingOptions={null}
        pagingSelected={stateTablePagingSelected}
        onPagingChange={(paging) => { setTablePagingSelected(paging); setTicker(ticker + 1) }}

        unSelectedActions={[
        ]}
        selectedActions={[
        ]}
        hoverActions={[
        ]}
      />
    </div>
  );
};
