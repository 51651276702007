import { TTSchema, TTSchemaSyncableInstance } from "tt-coms";
import { IBaseSyncable, schemaBaseSyncable } from "tt-coms";

export enum DeviceBehaviourType {
  TimedSignIn  = 'TimedSignIn',
  TimedSignOut  = 'TimedSignOut',
}

export interface DeviceBehaviourBase {
  uuid: string,
  type: DeviceBehaviourType
}

export type DeviceBehaviourTimedSignIn = {
  behaviourCron: string,
  behaviourLifetimeMS: number,
}

export type DeviceBehaviourTimedSignOut = {
  start: Date
}

export type DeviceBehaviour = DeviceBehaviourBase & (
  { type: DeviceBehaviourType.TimedSignIn, data: DeviceBehaviourTimedSignIn } |
  { type: DeviceBehaviourType.TimedSignOut, data: DeviceBehaviourTimedSignOut }
)

export interface IDevice extends IBaseSyncable {
  serialNumber: string;
  behaviours: DeviceBehaviour[]
}

export const schemaDevice: TTSchema<IDevice> = {
  ...schemaBaseSyncable,
  serialNumber: {
    type: String,
    indexed: true,
  },
  behaviours: {
    type: Array,
    indexed: false,
  }
}

export const schemaInstanceDevice = new TTSchemaSyncableInstance<IDevice>('devices', schemaDevice, 0);
